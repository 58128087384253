import { List } from '@carespace-icons/layoult/list';
import { Image01 } from '@carespace-icons/files/image01';
import { Share07 } from '@carespace-icons/general/share07';
import { BarChart01 } from '@carespace-icons/charts/barChart01';
import { BarChartSquarePlus } from '@carespace-icons/charts/barChartSquarePlus';
import { MdOutlineAssignment } from 'react-icons/md';
import { PlusSquare } from '@carespace-icons/general/plusSquare';
import { MagicWand01 } from '@carespace-icons/editor/magicWand01';
import { PlaySquare } from '@carespace-icons/media/playSquare';
import { Plus } from '@carespace-icons/general/plus';

export const AI_MENU_DATA = {
	data: [
		{
			id: 0,
			title: 'Home',
			list: [
				{
					key: 'home',
					icon: (
						<Plus
							color="stroke-primary-400"
							height={16.67}
							width={13.33}
						/>
					),
					name: 'For You',
				},
				{
					key: 'programs',
					icon: <List color="stroke-primary-400" height={15} width={15} />,
					name: 'My List',
				}
			],
		},,
		{
			id: 1,
			title: 'Evaluation',
			list: [
				{
					key: 'virtualEvaluation',
					icon: (
						<MdOutlineAssignment
							className="text-primary-400"
							height={16.67}
							width={13.33}
						/>
					),
					name: 'Start Virtual Evaluation',
				},
				{
					key: 'listEvaluation',
					icon: <List color="stroke-primary-400" height={15} width={15} />,
					name: 'Evaluation Results',
				},
			],
		},
		{
			id: 2,
			title: 'OmniROM',
			list: [
				{
					key: 'startScan',
					icon: (
						<PlaySquare color="stroke-primary-400" height={16.67} width={15} />
					),
					name: 'Start Scan',
				},
				{
					key: 'romSummary',
					icon: <List color="stroke-primary-400" height={15} width={15} />,
					name: 'OmniROM Summary',
				},
				{
					key: 'captures',
					icon: <Image01 color="stroke-primary-400" height={15} width={15} />,
					name: 'OmniROM Captures',
				},
				{
					key: 'postureSummary',
					icon: <List color="stroke-primary-400" height={15} width={15} />,
					name: 'Posture Summary',
				},
				{
					key: 'postureCaptures',
					icon: <Image01 color="stroke-primary-400" height={15} width={15} />,
					name: 'Posture Captures',
				},
				// {
				//   "key": "customSummary",
				//   "icon": <List color='stroke-primary-400' height={15} width={15} />,
				//   "name": 'Custom Summary'
				// },
				// {
				//   "key": "customCaptures",
				//   "icon": <Image01 color='stroke-primary-400' height={15} width={15} />,
				//   "name": 'Custom Captures'
				// }
			],
		},
		{
			id: 3,
			title: "Let's Move",
			list: [
				{
					key: 'generateProgram',
					icon: (
						<MagicWand01 height={15} width={15} color="stroke-primary-400" />
					),
					name: 'Generate Program',
				},
				{
					key: 'programs',
					icon: (
						<PlusSquare color="stroke-primary-400" height={15} width={15} />
					),
					name: 'Program List',
				},
				{
					key: 'listSessions',
					icon: <List color="stroke-primary-400" height={15} width={15} />,
					name: 'Program Summary',
				},
			],
		},
		{
			id: 4,
			title: 'Reports',
			list: [
				{
					key: 'createReport',
					icon: (
						<BarChartSquarePlus
							color="stroke-primary-400"
							height={15.83}
							width={15.83}
						/>
					),
					name: 'Create Reports',
				},
				{
					key: 'myReport',
					icon: (
						<BarChart01 color="stroke-primary-400" height={15} width={15} />
					),
					name: 'Report Summary',
				},
			],
		},
		{
			id: 5,
			title: 'Survey',
			list: [
				{
					key: 'assignSurvey',
					icon: (
						<PlusSquare height={15} width={15} color="stroke-primary-400" />
					),
					name: 'Assign',
				},
				{
					key: 'createSurvey',
					icon: (
						<MagicWand01 height={15} width={15} color="stroke-primary-400" />
					),
					name: 'Create',
				},
				{
					key: 'startSurveyUser',
					icon: (
						<MdOutlineAssignment
							className="text-primary-400"
							height={16.67}
							width={13.33}
						/>
					),
					name: 'Start',
				},
				{
					key: 'surveySummary',
					icon: <List color="stroke-primary-400" height={15} width={15} />,
					name: 'Summary',
				},
			],
		},
	],
};
