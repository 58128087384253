import Calibration from '@atoms/APTransitionCalibration';
import Closing from '@atoms/APTransitionClosing';
import Openning from '@atoms/APTransitionOpenning';
import ReadySetGo from '@atoms/APTransitionReadySetGo';

import { useCallback, useEffect, useRef, useState } from 'react';

import SingleResult from '@molecules/MPTransitionSingleResultRom';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import {
	closeSession,
	ETransitions,
	getPrintScreen,
	nextExercise,
	nextTransition,
	setMetricsDataValue,
	setResultExerciseValidateValue,
} from '@stores/rom/main';
import { useNavigate } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import InstructionalVideo from './InstructionalVideo';
import { CompletionScreen } from './CompletionScreen/CompletionScreen';
import { ExerciseTransitionTime } from './TransitionTime';
import { ResultScreen } from './ResultScreen';
import { setShowAllResults } from '@stores/rom/results';
import { router } from '@routers/routers';
import { setOnBoardRomCompletion } from '@stores/onBoard/onBoard';

const legMovementsExerciseIds = [16, 17, 18, 19, 20, 21, 22, 23]

interface IMainProps {
	isDashboard: boolean;
	transitionTime: number;
	setTransitionTime: (value: number) => void;
	children: JSX.Element | JSX.Element[];
	isVideoPause: boolean;
	onTogglePauseVideo: () => void;
	isSwitchMode: boolean;
	isFullscreen: boolean;
	isCompleted: boolean;
	setIsManual: (val: boolean) => void;
	setCompleted: (val: boolean) => void;
	onSavePhysicalAssessmentsMetrics: () => void;
	isManual: boolean;
}

const Main = (props: IMainProps) => {
	const {
		children,
		isSwitchMode,
		isFullscreen,
		isVideoPause,
		onTogglePauseVideo,
		isCompleted,
		setIsManual,
		isManual,
		setCompleted,
		onSavePhysicalAssessmentsMetrics,
		transitionTime,
		setTransitionTime,
		isDashboard,
	} = props;
	const screenshotSound = '/assets/screenshotSound.mp3';
	const dispatch = useTypedDispatch();
	const navigate = useNavigate();
	const {
		user,
		isCustom,
		currentExercise,
		transition,
		finishedExercises,
		paused,
		uploadProgress,
		enableToSendResult,
		pose,
		isRepetingExercise,
		resultExerciseValidation,
		bodyPointsVisible,
		session,
		metricsData,
	} = useTypedSelector(state => state.rom.main);
	const [savedVoice, setSavedVoice] = useState('');
	const transitionRef = useRef(transition);

	useEffect(() => {
		transitionRef.current = transition;
	}, [transition]);

	const onNextTransition = useCallback(() => {
		dispatch(nextTransition(transition?.next));
	}, [dispatch, transition]);

	const onNextExercise = () => dispatch(nextExercise());

	const customRomSession = useTypedSelector(state => state.rom.main.session);

	const onSavePhysicalAssessmentsMetricsToState = useCallback(async () => {
		const data = {
			userId: user?.id,
			strapiOmniRomExerciseId: currentExercise?.id,
			romSessionId: session?.id,
			value: isCustom ? pose.angleResults : +pose.angleResult,
			coordinates: isCustom ? pose.multiCoordinates : pose.coordinates,
			screenshot: await getPrintScreen(),
		};
		dispatch(setMetricsDataValue(data));
		dispatch(setResultExerciseValidateValue(data));
		const sound = new Audio(screenshotSound);
    if (sound) {
        sound.play();
    }
	}, [user, pose, currentExercise, session, dispatch]);

	useEffect(() => {
		if (finishedExercises?.finished && session?.id) {
			setCompleted(true);
			dispatch(setOnBoardRomCompletion(true))
		}
	}, [finishedExercises?.finished, session?.id, navigate]);

	useEffect(() => {
		if (transition?.value === ETransitions.CLOSING) {
			onSavePhysicalAssessmentsMetricsToState();
		}
	}, [transition]);

	useEffect(() => {
		if (
			transition?.value === ETransitions.RESULT &&
			metricsData?.screenshot &&
			!isManual
		) {
			onSavePhysicalAssessmentsMetrics();
		}
	}, [enableToSendResult, transition, metricsData]);

	useEffect(() => {
		if (transition?.value === ETransitions.CALIBRATION) {
			setTimeout(() => {
				onSavePhysicalAssessmentsMetricsToState();
			}, 3000);
		}
	}, [bodyPointsVisible]);

	const completeSession = async () => {
		await dispatch(closeSession(customRomSession?.id!));
	};

	useEffect(() => {
		if (isCompleted) {
			completeSession();
			if(!isDashboard) 
				navigate(`/${user?.id}${router.AIASSISTANT_ROM_SUMMARY}`, {
					state: {
						tab: 'romSummary',
						sessionId: session?.romProgramId,
						programId: session?.id,
						subTab: isCustom ? 'customRom' : 'standardRom',
					},
				});
			dispatch(setShowAllResults(false));
		}
	}, [isCompleted]);

	console.count('Main render');

	return (
		<Content className="h-full overflow-hidden">
			{!transitionTime && !isManual && (
				<ExerciseTransitionTime
					setIsManual={setIsManual}
					setSavedVoice={setSavedVoice}
					savedVoice={savedVoice}
					setTransitionTime={setTransitionTime}
					transitionTime={transitionTime!}
				/>
			)}

			{!isCompleted &&
				transitionTime &&
				!finishedExercises.finished &&
				!resultExerciseValidation?.screenshot &&
				transition?.value != ETransitions.RESULT && (
					<InstructionalVideo
						isVideoPause={isVideoPause}
						onTogglePauseVideo={onTogglePauseVideo}
						onNextTransition={onNextTransition}
						transition={transition}
						currentExercise={currentExercise!}
						isSwitchMode={isSwitchMode}
						isFullscreen={isFullscreen}
						isRepeat={isRepetingExercise}
					/>
				)}

			{!isCompleted &&
				!bodyPointsVisible &&
				transition?.value === ETransitions.CALIBRATION && 
				<Calibration 
				label={
					legMovementsExerciseIds.includes(currentExercise?.id) 
					? 'STEP BACK, and Kindly use a chair for support and ensure your ankles are visible.'
					: 'STEP BACK'
				}
				/>
			}

			{/* {isCompleted && isDashboard && (
				<CompletionScreen savedVoice={savedVoice} />
			)} */}

			{transition?.value === ETransitions.READYSETGO && (
				<ReadySetGo
					isPaused={paused}
					onNextTransition={onNextTransition}
					transitionTime={transitionTime!}
				/>
			)}
			{transition?.value === ETransitions.CLOSING && (
				<Closing isPaused={paused} onNextTransition={onNextTransition} />
			)}

			{transition?.value === ETransitions.RESULT &&
				(isManual ? (
					<div
						style={{
							width: '100%',
							height: '100%',
							background: '#6941C6D9',
						}}>
						<span
							style={{
								width: '100%',
								position: 'absolute',
								display: 'flex',
								justifyContent: 'center',
								top: 10,
								left: 0,
								color: 'white',
								zIndex: 10,
								fontWeight: '700',
								fontSize: 30,
							}}>
							{(currentExercise?.name
								? currentExercise?.name
								: currentExercise?.title
							)?.toUpperCase()}
						</span>
						{uploadProgress > 0 ? (
							<SingleResult
								exerciseName={
									currentExercise?.name
										? currentExercise?.name
										: currentExercise?.title!
								}
								isPaused={paused}
								enableToSendResult={enableToSendResult}
								onNextTransition={onNextTransition}
								resultExerciseValidation={resultExerciseValidation}
							/>
						) : (
							<ResultScreen screenshot={metricsData?.screenshot} />
						)}
					</div>
				) : (
					<SingleResult
						exerciseName={
							currentExercise?.name
								? currentExercise?.name
								: currentExercise?.title!
						}
						isPaused={paused}
						enableToSendResult={enableToSendResult}
						onNextTransition={onNextTransition}
						resultExerciseValidation={resultExerciseValidation}
					/>
				))}
			{transition?.value === ETransitions.OPENNING && (
				<Openning isPaused={paused} onNextExercise={onNextExercise} />
			)}
			{children}
		</Content>
	);
};

export default Main;
