import { showCustomModal } from "@atoms/ACustomModalInfo"
import Button from "@atoms/Button"
import { SearchLg } from "@carespace-icons/general/searchLg"
import { Upload01 } from "@carespace-icons/general/upload01"
import { List } from "@carespace-icons/layoult/list"
import { PlayCircle } from "@carespace-icons/media/playCircle"
import { VideoRecorder } from "@carespace-icons/media/videoRecorder"
import { useTypedDispatch, useTypedSelector } from "@stores/index"
import { emptySelectedExercises } from "@stores/rehab/library"
import { Col, Empty, Input, Pagination, Row, Spin } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CiGrid41 } from "react-icons/ci"
import { useNavigate } from "react-router-dom"
import { resetAll, setOmniromRecord, setOmniromUpload } from "@stores/rehab/main"
import { getMyLibraryList } from "@stores/rom/romTemplates"
import { IRomExerciseLibrary, IRomProgramExercise } from "@stores/interfaces"
import { router } from '@routers/routers';

interface ExerciseProps {
  setSelectedExercises: (selectedExercises: IRomProgramExercise[]) => void
  selectedExercises: IRomProgramExercise[]
  isGrid: boolean
  setGrid: (isGrid: boolean) => void
}

export const MyExerciseData = (props: ExerciseProps) => {
  const { selectedExercises, setSelectedExercises, isGrid, setGrid } = props
  const { t } = useTranslation();
  const romExerciseLibrary = useTypedSelector(state => state.rom.romTemplates.romExerciseLibrary);
  const [searchValue, setSearchValue] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [libraryData, setLibraryData] = useState(romExerciseLibrary)
  const id = useTypedSelector((state) => state.user.id)
  const [perpage, setPerPage] = useState(10)
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const onPageChange = (pageNumber: number, pageSize: number) => {
    setLibraryData([])
    setPerPage(pageSize)
    fetchData(id, pageNumber);
  }

  useEffect(() => {
    setLibraryData(romExerciseLibrary)
  }, [romExerciseLibrary])

  useEffect(() => {
    fetchData(id, 1);
  }, [])

  const fetchData = async (userId: string, page: number) => {
    setIsLoading(true)
    const payload = {
      userId: userId,
      search: searchValue,
      page: page
    }
    dispatch(emptySelectedExercises())
    await dispatch(getMyLibraryList(payload))
    setIsLoading(false)
  }

  const handleDivClick = (item: IRomExerciseLibrary) => {
    if (selectedExercises?.find(exercise =>
      exercise?.exerciseLibrary ? exercise?.exerciseLibrary?.id.toString() === item?.id.toString() :
        exercise?.id.toString() === item?.id.toString())) {
      setSelectedExercises(selectedExercises?.filter(exercise =>
        exercise?.exerciseLibrary ? exercise?.exerciseLibrary?.id.toString() != item?.id.toString() :
          exercise?.id.toString() != item?.id.toString()))
    } else {
      const exercise = {
        id: item?.id,
        exerciseLibraryId: item?.id,
        name: item?.title,
        description: item?.description,
        transitionTime: item?.transitionTime || 3,
        video: item?.video,
        bodypoint: item?.exercises,
      }
      setSelectedExercises([...selectedExercises, exercise])
    }
  }

  const addExercise = () => {
    dispatch(resetAll())
    dispatch(setOmniromRecord(true));
    dispatch(setOmniromUpload(false))
    navigate(router.OMNIROMADDEXERCISES, {
      state: { omniRomRecord: true }
    })
  }

  const uploadExercise = () => {
    dispatch(resetAll())
    dispatch(setOmniromUpload(true));
    dispatch(setOmniromRecord(false))
    navigate(router.OMNIROMADDEXERCISES, {
      state: { omniRomUpload: true }
    })
  }

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === 'Enter') {
      fetchData(id, 1);
    }
  };

  return (
    <div>
      <div className="flex items-center gap-2 justify-between">
        <Input
          className='w-[82%] my-3 h-10'
          placeholder={t('Admin.data.menu.aiAssistant.aiAssistantPrograms.search')}
          value={searchValue}
          onKeyDown={handleKeyDown}
          onChange={(e) => setSearchValue(e.target.value)}
          suffix={<SearchLg height={15} width={15} color='stroke-black' />}
        />
        <Button onClick={addExercise} size="sm">
          <span className='whitespace-nowrap' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
            <VideoRecorder width={20} height={20} />
            <p>{t('Patient.data.omnirom.record')}</p>
          </span>
        </Button>
        <div className="w-[130px] cursor-pointer bg-success-500 rounded-lg flex justify-center items-center gap-2 text-white font-semibold p-2"
          onClick={uploadExercise}>
          <Upload01 width={20} height={20} />
          <p>{t('Patient.data.omnirom.upload')}</p>
        </div>

      </div>
      <div className="flex gap-2 items-center justify-end mb-3">
        {selectedExercises.length != 0 && <p className="rounded-full py-0.5 px-2 bg-success-500 text-white">{selectedExercises.length}</p>}
        <div className="flex justify-end">
          <span onClick={() => setGrid(false)} className="w-9 h-9 text-center p-1 cursor-pointer rounded-l-lg border border-gray-300"><List color={isGrid ? 'stroke-gray-300' : 'stroke-gray-500'} height={24} width={24} /></span>
          <span onClick={() => setGrid(true)} className="w-9 h-9 text-center p-1 cursor-pointer rounded-r-lg border border-l-0 border-gray-300"><CiGrid41 className={`w-6 h-6 ${isGrid ? 'text-gray-500' : 'text-gray-300'}`} /></span>
        </div>
      </div>
      <div style={{ maxHeight: "42vh", overflowX: "hidden", overflowY: "auto" }}>
        {
          isLoading
            ? <div className="flex justify-center items-center h-40">
              <Spin tip="Loading" size="large" />
            </div>
            : libraryData && libraryData.data && libraryData.data.length > 0 ?
              !isGrid
                ? libraryData?.data?.map((item: IRomExerciseLibrary) => {
                  const isSelected = selectedExercises?.find(exercise =>
                    exercise?.exerciseLibrary ? exercise?.exerciseLibrary?.id.toString() === item?.id.toString() :
                      exercise?.id.toString() === item?.id.toString())
                  return <div className="mb-4">
                    <div className={`flex gap-4 p-4 !rounded-lg border-4 w-full cursor-pointer ${isSelected ? "border-green-500" : "hover:border-primary-500"
                      }`}
                      onClick={() => handleDivClick(item)} key={item.id}>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          showCustomModal({
                            video: item?.video,
                            name: item?.title,
                            description: item?.description,
                          })
                        }}
                        className={`custom-image-container`}>
                        <div className="w-40 flex-shrink-0 image-wrapper">
                          <video src={item?.video} controls={false} className="rounded-lg w-full h-full" />
                          <div className="play-button">
                            <PlayCircle width={50} height={50} />
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 rounded-lg p-2">
                        <p className="text-gray-500 font-semibold text-lg">{item?.title}</p>
                        <p className="text-gray-400 font-semibold text-xs">{t('Patient.data.omnirom.transistionTime')} : {item?.transitionTime ? item?.transitionTime : "3"}</p>
                        <p className="text-gray-500 mt-2">{item?.description}</p>
                      </div>
                    </div>
                  </div>
                })
                : <Row gutter={[16, 16]}>
                  {libraryData?.data?.map((item: IRomExerciseLibrary) => {
                    const isSelected = selectedExercises?.find(exercise =>
                      exercise?.exerciseLibrary ? exercise?.exerciseLibrary?.id.toString() === item?.id.toString() :
                        exercise?.id.toString() === item?.id.toString())
                    return (
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} key={item.id}>
                        <div
                          className={`flex gap-4 p-4 !rounded-lg border-2 hover:border-4 w-full cursor-pointer ${isSelected ? 'border-4 border-green-500' : 'hover:border-primary-500'}`}
                          onClick={() => handleDivClick(item)}
                        >
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              showCustomModal({
                                video: item?.video,
                                name: item?.title,
                                description: item?.description,
                              })
                            }}
                            className={`!w-14 rounded-lg relative flex h-auto items-center justify-center`}>
                            <div className="w-14 flex-shrink-0 image-wrapper">
                              <video src={item?.video} controls={false} className="rounded-lg w-full h-full" />
                              <div className="play-button">
                                <PlayCircle width={30} height={30} />
                              </div>
                            </div>
                          </div>
                          <div className="flex-1 rounded-lg p-2">
                            <p className="text-gray-500 font-semibold text-lg">{item?.title}</p>
                            <span className="text-gray-400 font-semibold text-sm flex justify-start gap-3">
                              <p>{item?.description}</p>
                            </span>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              : <Empty
                description='No Exercises Found'
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
        }
      </div>
      {
        !isLoading && libraryData && libraryData.data && libraryData.data.length > 0 && <Pagination
          className='createProgramModalPagination flex justify-center'
          current={libraryData?.pagination?.currentPage}
          onChange={onPageChange}
          total={libraryData?.pagination?.totalCount}
          showSizeChanger={false}
          pageSize={perpage}
        />
      }
    </div>
  )
}