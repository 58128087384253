import { measurementTypes } from "@pages/PatientOnboard/Constants"
import { IBodyRegionNormalized, IStatusNormalized } from "@stores/interfaces"

export const BodyRegionNormalized: IBodyRegionNormalized = {
  full: 'Full Body',
  upperBody: 'Upper Body',
  upperRight: 'Upper Right',
  upperLeft: 'Upper Left',
  lowerBody: 'Lower Body',
  lowerRight: 'Lower Right',
  lowerLeft: 'Lower Left',
}

export const StatusNormalized: Omit<IStatusNormalized, 'newPatients'> = {
  pendingReview: 'Pending Review',
  outOfParams: 'Out Of Parameters',
  followUpRequired: 'Follow Up Required',
  escalationRequired: 'Escalation Required',
  reviewed: 'Reviewed'
}

export const limitPagination = 10

export const pagination = {
  isFirstPage: true,
  isLastPage: true,
  currentPage: 0,
  previousPage: 0,
  nextPage: 1,
  pageCount: 0,
  totalCount: 0
}

export const countdownTime = 3000

export const patientTimeLimit = 60000

export const adminTimeLimit = 10000

export const USER_ROLES = {
  ADMIN: 'admin',
  SUPER_ADMIN: 'superadmin',
  USER: 'user'
}

export const ACTIVETAB = {
  HOME: 'home',
  DOWNLOAD_APP: "downloadApp",
  START_SCAN:'startScan',
  ROM_SUMMARY:"romSummary"
}

export const MONTHS = [
  { value: 1, label: 'Jan' },
  { value: 2, label: 'Feb' },
  { value: 3, label: 'Mar' },
  { value: 4, label: 'Apr' },
  { value: 5, label: 'May' },
  { value: 6, label: 'Jun' },
  { value: 7, label: 'Jul' },
  { value: 8, label: 'Aug' },
  { value: 9, label: 'Sep' },
  { value: 10, label: 'Oct' },
  { value: 11, label: 'Nov' },
  { value: 12, label: 'Dec' },
]

export const DEFAULT_FORM_DATA = {
    firstName: '',
    lastName: '',
    email: '',
    imageUrl: '',
    birthDate: '',
    gender: '',
    imperialHeight: 0,
    metricHeight: 0,
    imperialWeight: 0,
    metricWeight: 0,
    isPregnant: false,
    consentPolicyRead: false,
    patientId: '',
    measurementSystem: measurementTypes.IMPERIAL,
    mobilePhone: '',
    weight:'',
    height: ''
  };
