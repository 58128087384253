import { useEffect, useState } from 'react';
import {
	Button,
	ConfigProvider,
	Empty,
	Input,
	Modal,
	Space,
	Spin,
	Table,
	Tooltip,
	message,
} from 'antd';
import { SearchOutlined, ArrowDownOutlined } from '@ant-design/icons';
import UploadExport from '@carespace-icons/general/uploadExport';
import { Copy07 } from '@carespace-icons/general/copy07';
import { Download01 } from '@carespace-icons/general/download01';
import { Eye } from '@carespace-icons/general/eye';
import { Trash01 } from '@carespace-icons/general/trash01';
import { ReportModal } from './ReportModal';
import html2pdf from 'html2pdf.js';
import CreateReportModal from './CreateReportModal';
import stores, { useTypedDispatch, useTypedSelector } from '@stores/index';
import { getReport, getUserReports } from '@stores/report/reports';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { AuthProvider } from '@contexts/AuthContext';
import { Provider } from 'react-redux';
import ReportEvaluationData from './ReportContent/ReportEvaluationData';
import ReportRomSummaryData from './ReportContent/ReportRomSummaryData';
import ReportRomCapturesData from './ReportContent/ReportRomCapturesData';
import ReportRehabCaptureData from './ReportContent/ReportRehabCaptureData';
import ReportSurveyData from './ReportContent/ReportSurveyData';
import ReactDOM from 'react-dom';
import { IAddButtonItemsProps } from '@stores/interfaces';
import ReportPostureData from './ReportContent/ReportPostureData';
import { Copy01 } from '@carespace-icons/general/copy01';
import { DownloadCloud02 } from '@carespace-icons/general/downloadCloud02';
import { Edit01 } from '@carespace-icons/general/edit01';

const CoachReport = ({
	isAssistant,
	perPage = 0,
}: {
	isAssistant?: boolean;
	perPage?: number;
}) => {
	const [searchText, setSearchText] = useState('');
	const [sortOrder, setSortOrder] = useState('createdAt');
	const [isReportModalVisible, setReportModalVisible] = useState(false);
	const [isCreateReportModalVisible, setCreateReportModalVisible] =
		useState(false);
	const reports = useTypedSelector(state => state.reports.reports);
	const pagination = useTypedSelector(
		state => state.reports.reports?.pagination,
	);
	const report = useTypedSelector(state => state.reports.report);
	const [page, setPage] = useState(1);
	const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
		useState(false);
	const [selectedReportForDeletion, setSelectedReportForDeletion] =
		useState('');
	const { selectedUser } = useTypedSelector(state => state.contacts.main);
	const user = useTypedSelector(state => state.user);
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();
	const currentDate = moment().format('DDMMYYYY');
	const [isLoading, setIsLoading] = useState(true);
	const [reportModalLoading, setReportModalLoading] = useState(false);

	const [isEditMode, setIsEditMode] = useState(false);
	const [activeComponent, setActiveComponent] = useState<
		IAddButtonItemsProps | undefined
	>();
	const [isVisible, setIsVisible] = useState(false);
	const {
		romSummaryData,
		evaluationData,
		romCapturesData,
		surveyData,
		rehabCapturesData,
		assessmentNotes,
		planNotes,
		diagnosisCode,
		postureData,
	} = useTypedSelector(state => ({
		romSummaryData: state.reports.report?.romSessionsIds,
		evaluationData: state.reports.report?.evaluationSessionsIds,
		romCapturesData: state.reports.report?.romResultsIds,
		surveyData: state.reports.report?.surveyResultIds,
		rehabCapturesData: state.reports.report?.programSessionsIds,
		assessmentNotes: state.reports.report?.assessmentNotes,
		planNotes: state.reports.report?.planNotes,
		postureData: state.reports.report?.postureSessionsIds,
		diagnosisCode: state.reports.report?.diagnosisCode,
	}));

	const onChange = (page: number, pageSize: number) => {
		page && setPage(page);
	};

	useEffect(() => {
		const payload = {
			userId: user.isPhysioterapist ? selectedUser.id : user.id,
			limit: perPage === 0 ? 10 : perPage,
			page: page,
			sort: sortOrder,
			search: searchText,
		};
		dispatch(getUserReports(payload)).then(() => setIsLoading(false));
	}, [
		user,
		selectedUser,
		searchText,
		deleteConfirmationVisible,
		sortOrder,
		page,
		perPage,
	]);

	const columns = [
		{
			title: (
				<span
					className="cursor-pointer"
					onClick={() => {
						setSortOrder('name');
					}}>
					{t('Admin.data.addToReports.reportName')} &nbsp;
					{sortOrder != 'name' && <ArrowDownOutlined />}
				</span>
			),
			dataIndex: 'reportName',
			key: 'reportName',
		},
		{
			title: (
				<span
					className="cursor-pointer"
					onClick={() => {
						setSortOrder('createdAt');
					}}>
					{t('Admin.data.addToReports.createdAt')} &nbsp;
					{sortOrder != 'createdAt' && <ArrowDownOutlined />}
				</span>
			),
			dataIndex: 'createdAt',
			key: 'createdAt',
		},
		{
			title: '',
			dataIndex: 'actions',
			key: 'actions',
			align: 'right' as const,
			width: 100,
		},
	];

	const handleDelete = (reportId: string) => {
		setSelectedReportForDeletion(reportId);
		setDeleteConfirmationVisible(true);
	};

	const handleConfirmDelete = async () => {
		await axios.delete(`/reports/${selectedReportForDeletion}`);
		message.success(t('Admin.data.addToReports.deleteText'));
		setDeleteConfirmationVisible(false);
	};

	const getUsersName = user => {
		if (user.profile.fullName) return user.profile.fullName;
		if (user.profile.firstName && user.profile.lastName)
			return `${user.profile.firstName} ${user.profile.lastName}`;
		if (user.profile.firstName) return user.profile.firstName;
		return user.profile.email;
	};

	function downloadPDF() {
		const container = document.createElement('div');
		container.id = 'pdf-container';
		document.body.appendChild(container);

		ReactDOM.render(
			<AuthProvider authType={'localstorage'} authName={'_auth_carespace'}>
				<ConfigProvider
					theme={{
						token: {
							colorPrimary: '#6739b7',
							borderRadius: 7,
							colorBgContainer: '#ffffff',
						},
					}}>
					<Provider store={stores}>
						<div id="pdf-content">
							<div className="p-5">
								<ul className="report-info-list pb-5">
									<li className="report-li-div">
										<p className="report-info-value">
											{t('Patient.data.myProgress.evaluation.patientID')}{' '}
											<span className="report-user-name-label">
												{user.isPhysioterapist ? selectedUser.id : user.id}
											</span>
										</p>
										<p className="report-info-value">
											{t('Patient.data.myProgress.evaluation.patientName')}{' '}
											<span className="report-user-name-label">
												{user.isPhysioterapist
													? getUsersName(selectedUser)
													: `${user?.profile?.firstName} ${user?.profile?.lastName}`}
											</span>
										</p>
									</li>
									<hr />
								</ul>

								{evaluationData?.length == 0 &&
									romSummaryData?.length == 0 &&
									romCapturesData?.length == 0 &&
									surveyData?.length == 0 &&
									rehabCapturesData?.length == 0 &&
									assessmentNotes?.length == 0 &&
									planNotes?.length == 0 &&
									postureData?.length == 0 ? (
									<>
										<Empty
											image={Empty.PRESENTED_IMAGE_SIMPLE}
											description={
												<span>{t('Admin.data.addToReports.noData')}</span>
											}
										/>
									</>
								) : (
									<>
										{(evaluationData?.length != 0 || diagnosisCode?.length! != 0) &&
											<ReportEvaluationData
												isDownload={true}
												isEditMode={isEditMode}
												setActiveComponent={setActiveComponent}
												setIsVisible={setIsVisible}
											/>
										}
										{romSummaryData?.length != 0 && (
											<ReportRomSummaryData
												isDownload={true}
												isEditMode={isEditMode}
											/>
										)}
										{romCapturesData?.length != 0 && (
											<ReportRomCapturesData
												isDownload={true}
												isEditMode={isEditMode}
											/>
										)}
										{rehabCapturesData?.length != 0 && (
											<ReportRehabCaptureData
												isDownload={true}
												isEditMode={isEditMode}
											/>
										)}
										{surveyData && surveyData?.length > 0 && (
											<ReportSurveyData
												isDownload={true}
												isEditMode={isEditMode}
											/>
										)}
										{postureData?.length! > 0 && (
											<ReportPostureData
												isDownload={true}
												isEditMode={isEditMode}
											/>
										)}
									</>
								)}
							</div>
						</div>
					</Provider>
				</ConfigProvider>
			</AuthProvider>,
			container,
		);

		setTimeout(() => {
			const element = document.getElementById('pdf-content');

			if (element) {
				html2pdf()
					.from(element)
					.set({
						margin: 10,
						filename: `${user.isPhysioterapist ? selectedUser?.profile?.firstName : user?.profile?.firstName}-${currentDate}.pdf`,
						image: { type: 'jpeg', quality: 0.98 },
						html2canvas: { scale: 2 },
						jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
					})
					.save()
					.then(() => {
						ReactDOM.unmountComponentAtNode(container);
						document.body.removeChild(container);
					})
					.catch(error => {
						console.error('Error generating PDF:', error);
						ReactDOM.unmountComponentAtNode(container);
						document.body.removeChild(container);
					});
			} else {
				console.error(
					'Error: The content to be converted to PDF is not found.',
				);
				ReactDOM.unmountComponentAtNode(container);
				document.body.removeChild(container);
			}
		}, 1000);
	}

	const copyToClipboard2 = () => {
		const container = document.createElement('div');
		container.style.position = 'absolute';
		container.style.left = '-9999px';
		document.body.appendChild(container);

		ReactDOM.render(
			<Provider store={stores}>
				<div className="p-5">
					<ul className="report-info-list pb-5">
						<li className="report-li-div">
							<p className="report-info-value">
								{t('Patient.data.myProgress.evaluation.patientID')}{' '}
								<span className="report-user-name-label">
									{user.isPhysioterapist ? selectedUser.id : user.id}
								</span>
							</p>
							<p className="report-info-value">
								{t('Patient.data.myProgress.evaluation.patientName')}{' '}
								<span className="report-user-name-label">
									{user.isPhysioterapist
										? getUsersName(selectedUser)
										: `${user?.profile?.firstName} ${user?.profile?.lastName}`}
								</span>
							</p>
						</li>
						<hr />
					</ul>
					{evaluationData?.length == 0 &&
						romSummaryData?.length == 0 &&
						romCapturesData?.length == 0 &&
						surveyData?.length == 0 &&
						rehabCapturesData?.length == 0 &&
						assessmentNotes?.length == 0 &&
						planNotes?.length == 0 &&
						postureData?.length == 0 ? (
						<>
							<Empty
								image={Empty.PRESENTED_IMAGE_SIMPLE}
								description={<span>{t('Admin.data.addToReports.noData')}</span>}
							/>
						</>
					) : (
						<>
							{evaluationData?.length != 0 ||
								(diagnosisCode?.length! != 0 && (
									<ReportEvaluationData
										isDownload={true}
										isEditMode={isEditMode}
										setActiveComponent={setActiveComponent}
										setIsVisible={setIsVisible}
									/>
								))}
							{romSummaryData?.length != 0 && (
								<ReportRomSummaryData
									isDownload={true}
									isEditMode={isEditMode}
								/>
							)}
							{romCapturesData?.length != 0 && (
								<ReportRomCapturesData
									isDownload={true}
									isEditMode={isEditMode}
								/>
							)}
							{rehabCapturesData?.length != 0 && (
								<ReportRehabCaptureData
									isDownload={true}
									isEditMode={isEditMode}
								/>
							)}
							{surveyData && surveyData?.length > 0 && (
								<ReportSurveyData isDownload={true} isEditMode={isEditMode} />
							)}
							{postureData?.length! > 0 && (
								<ReportPostureData isDownload={true} isEditMode={isEditMode} />
							)}
						</>
					)}
				</div>
			</Provider>,
			container,
		);

		setTimeout(() => {
			const htmlContent = container.innerHTML;
			const tempDiv = document.createElement('div');
			tempDiv.innerHTML = htmlContent;
			const getFormattedText = element => {
				let formattedText = '';
				element.childNodes.forEach(node => {
					if (node.nodeType === Node.TEXT_NODE) {
						formattedText += node.textContent;
					} else if (node.nodeType === Node.ELEMENT_NODE) {
						const tagName = node.tagName.toLowerCase();
						if (tagName === 'p' || tagName === 'li' || tagName === 'div') {
							formattedText += getFormattedText(node) + '\n\n';
						} else {
							formattedText += getFormattedText(node);
						}
					}
				});
				return formattedText;
			};

			const formattedText = getFormattedText(tempDiv);

			const tempTextArea = document.createElement('textarea');
			tempTextArea.value = formattedText.trim();
			document.body.appendChild(tempTextArea);
			tempTextArea.select();

			try {
				document.execCommand('copy');
				message.success(t('Patient.data.myProgress.evaluation.copyClipboard'));
			} catch (error) {
				console.error('Error copying to clipboard:', error);
			} finally {
				document.body.removeChild(tempTextArea);
				document.body.removeChild(container);
			}
		}, 1000);
	};

	const ActionIcons = (reportId: string, index: number) => (
		<div style={{ display: 'flex' }}>
			<Tooltip
				placement="topRight"
				title={t('Admin.data.addToReports.copy')}
				color="purple"
				showArrow={false}>
				<span
					className="cursor-pointer pl-4"
					onClick={() => {
						dispatch(getReport(reportId));
						setReportModalVisible(true);
						setTimeout(() => copyToClipboard2(), 1000);
					}}>
					<Copy01 color="stroke-gray-600" width={20} height={20} />
				</span>
			</Tooltip>
			<Tooltip
				placement="topRight"
				title={t('Admin.data.addToReports.download')}
				color="purple"
				showArrow={false}>
				<span
					className="cursor-pointer pl-4"
					onClick={() => {
						dispatch(getReport(reportId));
						setReportModalVisible(true);
						setTimeout(() => downloadPDF(), 1000);
					}}>
					<DownloadCloud02 color="stroke-gray-600" width={20} height={20} />
				</span>
			</Tooltip>
			<Tooltip
				placement="topRight"
				title={t('Admin.data.addToReports.delete')}
				color="purple"
				showArrow={false}>
				<span
					className="cursor-pointer pl-4"
					onClick={() => handleDelete(reportId)}>
					<Trash01 color="stroke-gray-600" width={20} height={20} />
				</span>
			</Tooltip>
			<Tooltip
				placement="topRight"
				title={t('Admin.data.addToReports.edit')}
				color="purple"
				showArrow={false}>
				<span
					className="cursor-pointer pl-4 pr-4"
					onClick={() => {
						setReportModalLoading(true);
						dispatch(getReport(reportId)).then(() =>
							setReportModalLoading(false),
						);
						setReportModalVisible(true);
					}}>
					<Edit01 color="stroke-gray-600" width={20} height={20} />
				</span>
			</Tooltip>
		</div>
	);

	return (
		<div>
			<div className="card-container collapse-panel-container">
				<div className="bg-white rounded-md border-inherit">
					<Space.Compact
						size="large"
						className="bg-white p-2"
						style={{ display: 'flex', alignItems: 'center' }}>
						<>
							<Input
								addonBefore={<SearchOutlined />}
								placeholder="Search"
								onChange={e => setSearchText(e.target.value)}
							/>
							&nbsp;&nbsp;
						</>
						{isAssistant ? (
							' '
						) : (
							<Button
								className="!border !border-gray-300 flex !rounded-lg p-2 gap-2 hover:!bg-white"
								onClick={() => setCreateReportModalVisible(true)}
								style={{ padding: '18px', borderRadius: '10px' }}>
								<span
									style={{
										display: 'flex',
										alignItems: 'center',
										marginTop: '-12px',
									}}
									className="text-black">
									<UploadExport />
									{t('Admin.data.addToReports.createReport')}
								</span>
							</Button>
						)}
					</Space.Compact>
					{isLoading ? (
						<Spin
							className="flex items-center justify-center"
							tip="Loading"
							size="large"
						/>
					) : (
						<>
							<Table
								dataSource={reports?.data?.map((report, index) => ({
									key: report.id,
									reportName: report.name,
									createdAt: moment(report.createdAt).local().format('LLL'),
									actions: ActionIcons(report.id, index),
								}))}
								columns={columns}
								pagination={
									!perPage && {
										current: pagination?.currentPage,
										defaultPageSize: 10,
										total: pagination?.totalCount,
										showSizeChanger: false,
										onChange,
									}
								}
							/>
						</>
					)}
				</div>
			</div>
			{isReportModalVisible && (
				<ReportModal
					isModalVisible={isReportModalVisible}
					onOk={() => setReportModalVisible(false)}
					onCancel={() => setReportModalVisible(false)}
					copyToClipboard={copyToClipboard2}
					downloadPdf2={downloadPDF}
					reportModalLoading={reportModalLoading}
					setReportModalLoading={setReportModalLoading}
				/>
			)}
			<Modal
				title={t('Admin.data.addToReports.deleteAlertText')}
				className="select-none"
				closable={false}
				centered
				open={deleteConfirmationVisible}
				onOk={handleConfirmDelete}
				onCancel={() => setDeleteConfirmationVisible(false)}
				okText={t('Admin.data.addToReports.yes')}
				cancelText={t('Admin.data.addToReports.no')}></Modal>
			{isCreateReportModalVisible && (
				<CreateReportModal
					isVisible={isCreateReportModalVisible}
					onCancel={() => setCreateReportModalVisible(false)}
				/>
			)}
		</div>
	);
};

export default CoachReport;
