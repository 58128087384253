import { Button, Popover } from 'antd'
import { List } from "@carespace-icons/layoult/list";
import { Share07 } from "@carespace-icons/general/share07";
import { Image01 } from "@carespace-icons/files/image01";
import { PlusSquare } from "@carespace-icons/general/plusSquare";
import { IAddButtonItemsProps } from '@stores/interfaces';
import { DotsHorizontal } from '@carespace-icons/general/dotsHorizontal';
import { useTypedDispatch } from '@stores/index';
import { clearReports } from '@stores/report/reports';
import { setIsReportModal } from '@stores/aiAssistant/aiAssistant';
import { useTranslation } from 'react-i18next';

interface IAddmoreResults {
  setActiveComponent: (value: IAddButtonItemsProps) => void
  setIsVisible: (val: boolean) => void
}

export default function AddMoreResults(props: IAddmoreResults) {
  const { setActiveComponent, setIsVisible } = props
  const dispatch = useTypedDispatch()
  const { t } = useTranslation()

  const handleClick = (item: IAddButtonItemsProps) => {
    setIsVisible(true);
    setActiveComponent(item)
    dispatch(setIsReportModal(true))
    dispatch(clearReports());
  }

  const addButtonItems: IAddButtonItemsProps[] = [
    {
      key: 'listEvaluation',
      label: t("Admin.data.addNotes.listEvaluation"),
      icon: <List width={13} height={16} color="stroke-gray-500" />
    },
    {
      key: 'romSummary',
      label: t("Admin.data.addNotes.romSummary"),
      icon: <Share07 width={15} height={16} color="stroke-gray-500" />
    },
    // {
    //   key: 'captures',
    //   label: t("Admin.data.addNotes.captures"),
    //   icon: <Image01 width={15} height={15} color="stroke-gray-500"/>
    // },
    {
      key: 'postureSummary',
      label: t("Patient.data.postures.postureSummary"),
      icon: <Image01 width={15} height={16} color="stroke-gray-500" />
    },
    {
      key: 'listSessions',
      label: t("Admin.data.addNotes.listSessions"),
      icon: <PlusSquare width={15} height={15} color="stroke-gray-500" />
    },
    {
      key: 'surveySummary',
      label: t("Admin.data.addNotes.surveySummary"),
      icon: <List width={13} height={16} color="stroke-gray-500" />
    }
  ]

  return (
    <div className="add-more-results-container">
      <Popover placement="bottomRight" content={<div>
        {addButtonItems.map((item: IAddButtonItemsProps) => {
          return (
            <div className="add-button-css" onClick={() => handleClick(item)}>
              <span>{item.icon}</span>
              <span className="add-button-label">{item.label}</span>
            </div>
          )
        })}
      </div>}>
        <Button className="btn-report-css">{t("Admin.data.addNotes.add")} <DotsHorizontal width={20} height={20} color="stroke-gray-700" /></Button>
      </Popover>
    </div>
  )
}
