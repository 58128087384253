import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { wellCheckNess } from '@stores/onBoard/onBoard';
import { getUser } from '@stores/user';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

export default function WellnessCheck() {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();
	const userId = useTypedSelector((state) => state.user.id)

	const handleNoClick = async () => {
		await dispatch(wellCheckNess({  userId, isWellCheckNess: true }));
		await dispatch(getUser(userId))
	};		

	return (
		<div
			style={{
				background: 'rgba(92, 62, 170, 1)',
				borderRadius: '0.5rem',
				padding: '8px',
			}}>
			<p className="font-bold text-sm">{t('Admin.data.managePatient.wellnesscheck.label')}</p>
			<div className="text-xs p-2 mt-2">
				<p>{t('Admin.data.managePatient.wellnesscheck.title')}</p>
				<p>{t('Admin.data.managePatient.wellnesscheck.description')}</p>
			</div>
			<div className="text-xs grid place-items-center mt-3 gap-2">
				<p>{t('Admin.data.managePatient.wellnesscheck.stillDiscomfort')}</p>
				<Button
					className="wellness-button-css"
					style={{ background: '#9E77ED' }} onClick={handleNoClick}>
					{t('Admin.data.managePatient.wellnesscheck.noBetter')}
				</Button>
			</div>
		</div>
	);
}
