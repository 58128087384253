import ImgFront from '@atoms/ABodyFront';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import {
	createSession,
	getOmniRomExerciseGroups,
	setStrapiOmniRomExerciseGroupId,
} from '@stores/rom/main';
import { Button, Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import './style.css';
import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import { templateGroup } from './ProgramGroup';
import { setVideoRecordState } from '@stores/onBoard/onBoard';

const bodyPoints = [
	{
		id: 0,
		name: 'Right Shoulder',
		position: 'front',
		styles: { top: '96px', left: '63px' },
		part: 'upperRight',
	},
	{
		id: 1,
		name: 'Left Shoulder',
		position: 'front',
		styles: { top: '96px', right: '63px' },
		part: 'upperLeft',
	},
	{
		id: 2,
		name: 'Right Elbow',
		position: 'front',
		styles: { top: '174px', left: '47px' },
		part: 'upperRight',
	},
	{
		id: 3,
		name: 'Left Elbow',
		position: 'front',
		styles: { top: '174px', right: '47px' },
		part: 'upperLeft',
	},
	{
		id: 6,
		name: 'Right Hip',
		position: 'front',
		styles: { top: '215px', left: '85px' },
		part: 'lowerRight',
	},
	{
		id: 7,
		name: 'Left Hip',
		position: 'front',
		styles: { top: '215px', right: '85px' },
		part: 'lowerLeft',
	},
	{
		id: 8,
		name: 'Right Knee',
		position: 'front',
		styles: { bottom: '138px', left: '97px' },
		part: 'lowerRight',
	},
	{
		id: 9,
		name: 'Left Knee',
		position: 'front',
		styles: { bottom: '138px', right: '97px' },
		part: 'lowerLeft',
	},
];

const btnClass = '!h-auto !text-white !rounded-full !py-[8px] !px-[24px]';

interface ISplashPageProps {
	onTogglesSplashPage: () => void;
}

const SplashPage = (props: ISplashPageProps) => {
	const { onTogglesSplashPage } = props;
	const dispatch = useTypedDispatch();
	const [activePart, setActivePart] = useState('');
	const { user, selectedUser } = useTypedSelector(state => ({
		user: state.user,
		selectedUser: state.contacts.main.selectedUser,
	}));

	const onToggleSplash = async (programId: number | string) => {
		dispatch(setVideoRecordState(true));
		dispatch(setStrapiOmniRomExerciseGroupId(programId));
		const session = {
			strapiOmniRomProgramId: programId,
			userId: user.isPhysioterapist ? selectedUser?.id : user.id,
		};
		await dispatch(createSession(session));
		onTogglesSplashPage();
	};

	const highlightArea = (bodyPart: string) => {
		const el = document.getElementById('highlght');
		if (el) {
			el.classList.remove('upper', 'right', 'left', 'full');
			switch (bodyPart) {
				case 'upperBody':
					el.classList.add('upper');
					break;
				case 'upperLeft':
					el.classList.add('upper', 'right');
					break;
				case 'upperRight':
					el.classList.add('upper', 'left');
					break;
				case 'fullScan':
					el.classList.add('full');
					break;
				default:
					break;
			}
		}
		setActivePart(bodyPart);
	};

	const setActiveHighlight = (part: string) => {
		if (activePart === 'full') {
			return 'hotspot-circle active';
		} else if (activePart === 'upperBody' && part.includes('upper')) {
			return 'hotspot-circle active';
		} else if (activePart === 'lowerBody' && part.includes('lower')) {
			return 'hotspot-circle active';
		} else if (activePart === part) {
			return 'hotspot-circle active';
		} else {
			return 'hotspot-circle';
		}
	};

	const activeStyle = { display: 'inline' };
	const notActiveStyle = { display: 'none' };
	const { t } = useTranslation();

	console.count('SplashPage render');

	return (
		<Content
			style={{
				aspectRatio: '16/9',
			}}>
			<div className={'splash orom-splash-wrapper'}>
				<div className="body-points select-none">
					<div className="title-top md:scale[50%] lg:scale-[60%]">
						<h3>{t('Patient.data.omnirom.chooseScan')}</h3>
					</div>
					<Row
						justify="center"
						className="md:scale-[40%] lg:scale-[55%] xl:scale-[70%] 2xl:scale-[90%]">
						<Col className="md:-top-56 lg:-top-20 xl:top-10 2xl:top-16">
							<div className="body-shape-front">
								<div
									className="area-highlight"
									id="highlght"
									style={activePart ? activeStyle : notActiveStyle}></div>
								<ImgFront color="#DCCDE9" width={250} />
								{bodyPoints.map((hotspot, index) => (
									<div
										key={index}
										style={hotspot.styles}
										className={setActiveHighlight(hotspot.part)}></div>
								))}
							</div>
						</Col>
						<Col className="md:-mt-72 md:mb-72 lg:-mt-40 lg:mb-40 xl:-mt-5 xl:mb-10 2xl:mt-1 2xl:mb-0">
							{templateGroup?.map(group => {
								return (
									<Button
										key={group.id}
										className={`btn-outline ${btnClass}`}
										onClick={() => onToggleSplash(group.id)}
										style={eval('(' + group?.frontend?.styles + ')')}
										// TODO: @binu: onMouseOver is not a good practice to use in this case, it will trigger the function and render multiple times and then cause performance issues
										onMouseOver={() => highlightArea(group.key)}>
										{group.name}
									</Button>
								);
							})}
						</Col>
					</Row>
				</div>
			</div>
		</Content>
	);
};

export default SplashPage;
