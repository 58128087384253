import { ThumbsDown } from '@carespace-icons/alerts/thumbsDown';
import { ThumbsUp } from '@carespace-icons/alerts/thumbsUp';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

const RecommendationDashboard = () => {
  const { t } = useTranslation()
  return (
    <div className='mb-5'>
      <div className="bg-white rounded-[16px] p-4">
        <p className="flex flex-col sm:flex-row sm:items-center sm:justify-between text-primary-800 font-inter text-[22px] font-bold">
          <span>{t('Patient.data.recommendations.recommendations')}</span>
          <span className="text-gray-800 text-[16px] font-normal">
            {t('Patient.data.recommendations.suggestions')}
          </span>
        </p>
        <Row
          gutter={[16, 16]}
          style={{ marginTop: '16px' }}
        >
          <Col xs={24} sm={24} md={12} lg={8}>
            <div className="flex items-center h-full p-4 gap-4 bg-white rounded-xl shadow-[0px_0px_12px_-4px_#10182817] border border-gray-200">
              <div className="bg-primary-50 p-2 rounded-full">
                <div className="bg-primary-100 p-2 rounded-full">
                  <ThumbsUp color="stroke-primary-800" />
                </div>
              </div>
              <p>
                <span className="font-bold">{t('Patient.data.recommendations.practiceDeep')}</span>
                <span> {t('Patient.data.recommendations.toManage')}</span>
              </p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <div className="flex items-center h-full p-4 gap-4 bg-white rounded-xl shadow-[0px_0px_12px_-4px_#10182817] border border-gray-200">
              <div className="bg-primary-50 p-2 rounded-full">
                <div className="bg-primary-100 p-2 rounded-full">
                  <ThumbsUp color="stroke-primary-800" />
                </div>
              </div>
              <p>
                <span className="font-bold">{t('Patient.data.recommendations.useProper')}</span>
                <span> {t('Patient.data.recommendations.duringActivities')}</span>
              </p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <div className="flex items-center h-full p-4 gap-4 bg-white rounded-xl shadow-[0px_0px_12px_-4px_#10182817] border border-gray-200">
              <div className="bg-primary-50 p-2 rounded-full">
                <div className="bg-primary-100 p-2 rounded-full">
                  <ThumbsUp color="stroke-primary-800" />
                </div>
              </div>
              <p>
                <span className="font-bold">{t('Patient.data.recommendations.engageGentle')}</span>
                <span> {t('Patient.data.recommendations.toHelp')}</span>
              </p>
            </div>
          </Col>
        </Row>

        <Row
          gutter={[16, 16]}
          style={{ marginTop: '16px' }}
        >
          <Col xs={24} sm={24} md={12} lg={8}>
            <div className="flex items-center h-full p-4 gap-4 bg-white rounded-xl shadow-[0px_0px_12px_-4px_#10182817] border border-gray-200">
              <div className="bg-error-50 p-2 rounded-full">
                <div className="bg-error-100 p-2 rounded-full">
                  <ThumbsDown color="stroke-error-400" />
                </div>
              </div>
              <p>
                <span className="font-bold">{t('Patient.data.recommendations.doNotPush')} </span>
                <span>{t('Patient.data.recommendations.stopActivities')}</span>
              </p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <div className="flex items-center h-full p-4 gap-4 bg-white rounded-xl shadow-[0px_0px_12px_-4px_#10182817] border border-gray-200">
              <div className="bg-error-50 p-2 rounded-full">
                <div className="bg-error-100 p-2 rounded-full">
                  <ThumbsDown color="stroke-error-400" />
                </div>
              </div>
              <p>
                <span className="font-bold">{t('Patient.data.recommendations.neglectEmotional')} </span>
                <span>{t('Patient.data.recommendations.stressAnxiety')}</span>
              </p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <div className="flex items-center h-full p-4 gap-4 bg-white rounded-xl shadow-[0px_0px_12px_-4px_#10182817] border border-gray-200">
              <div className="bg-error-50 p-2 rounded-full">
                <div className="bg-error-100 p-2 rounded-full">
                  <ThumbsDown color="stroke-error-400" />
                </div>
              </div>
              <p>
                <span className="font-bold">{t('Patient.data.recommendations.avoidMovement')} </span>
                <span>{t('Patient.data.recommendations.inactivityWorsen')}</span>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default RecommendationDashboard;
