import { Col, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Logo from './components/Logo';
import DrawerMenu from './components/DrawerMenu';
import Topbar from './components/Topbar';
import {
	FullScreenProvider,
	UseFullScreen,
} from './context/FullScreen.context';
import { MenuProvider } from './context/Menu.context';
import { TransitionProvider } from './context/Transition.context';
import { InstructionalVideoProvider } from './context/InstructionalVideo.context';
import { SwitchVideoProvider } from './context/SwitchVideo.context';
import { ControlsProvider } from './context/Controls.context';
import Transitions from './components/Transitions';
import Symetrograph from './components/Symetrograph';
import Capture from './components/Capture';
import Lines from './components/Lines';

function PostureScan(props: { isDashboard: boolean }) {
	const { isDashboard } = props;
	return (
		<FullScreenProvider>
			<SwitchVideoProvider>
				<ControlsProvider>
					<MenuProvider>
						<TransitionProvider>
							<InstructionalVideoProvider>
								<PostureScanContent isDashboard={isDashboard} />
							</InstructionalVideoProvider>
						</TransitionProvider>
					</MenuProvider>
				</ControlsProvider>
			</SwitchVideoProvider>
		</FullScreenProvider>
	);
}

function PostureScanContent(props: { isDashboard?: boolean }) {
	const { ref, sizes, isFullScreen } = UseFullScreen();
	const { isDashboard } = props;

	return (
		<Row align="middle" justify="center" className="md:scale-[90%] mb-10">
			<Col
				ref={ref}
				style={{
					maxWidth: isFullScreen ? '100%' : 1280,
					aspectRatio: '16/9',
					width: '100%',
				}}>
				<Topbar isDashboard={isDashboard} />
				<Content
					style={{
						zIndex: 1,
						background: '#6b2ba0',
						overflow: 'hidden',
						maxWidth: isFullScreen ? '100%' : 1280,
						width: '100%',
						height: isDashboard ? sizes.height : isFullScreen ? '97vh' : '100%',
						position: 'relative',
						aspectRatio: '16/9',
					}}>
					<DrawerMenu />
					<Transitions>
						<Capture />
						<Symetrograph />
						<Lines />
					</Transitions>
					<Logo />
				</Content>
			</Col>
		</Row>
	);
}

export default PostureScan;
