import { HomeLine } from '@carespace-icons/general/homeLine';
import { UserSquare } from '@carespace-icons/users/userSquare';
import { Logomark } from '@carespace-icons/Logo/logomark';
import { Mail01 } from '@carespace-icons/communication/mail01';
import { useTranslation } from 'react-i18next';
import Settings from '@pages/Settings';
import { Popover } from 'antd';
import {
	APIKeyData,
	IAdminSideBarProps,
	IMenuAdminProps,
} from '@stores/interfaces';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { Settings01 } from '@carespace-icons/general/settings01';
import { useEffect, useState } from 'react';
import { getSettingApiKey } from '@stores/settings/setings';
import { useBlockNavigation } from '@atoms/ABlockNavigation';
import { router } from '@routers/routers';
import { useNavigate } from 'react-router-dom';
import { USER_ROLES } from '@stores/constants';
import { Phone02 } from '@carespace-icons/media/phone02';
import { LogOut04 } from '@carespace-icons/general/logOut04';

export default function AdminMenuSideBar(props: IAdminSideBarProps) {
	const {
		onChangeHome,
		activeMenu,
		setActiveMenu,
		setSearchQuery,
		setSelectedItem,
	} = props;
	const { t } = useTranslation();
	const { handleNavigation } = useBlockNavigation(
		location.pathname.includes('/program/start'),
		'You have unsaved changes. Do you want to leave?',
	);
	const user = useTypedSelector(state => state.user);
	const { selectedUser } = useTypedSelector(state => state.contacts.main);
	const [isModalSettingsOpened, setIsModalSettingsOpened] = useState(false);
	const [settingData, setSettingData] = useState<APIKeyData | undefined>(
		undefined,
	);
	const [inviteCode, setInviteCode] = useState('');
	const [apiKey, setApiKey] = useState('');
	const dispatch = useTypedDispatch();
	const isSuperAdmin = user?.profile?.role === USER_ROLES.SUPER_ADMIN;
	const navigate = useNavigate()

	useEffect(() => {
		if (isSuperAdmin) {
			setActiveMenu('contacts');
		}
	}, [activeMenu]);

	const menuAdmin = [
		{
			label: t('Admin.data.menu.home.home'),
			key: '',
			icon: <HomeLine width={20} height={20} color={'stroke-white'} />,
		},
		{
			label: '',
			key: 'activity',
			icon: <Mail01 width={20} height={20} color={'stroke-white'} />,
		},
		{
			label: '',
			key: 'contacts',
			icon: <UserSquare width={20} height={20} color={'stroke-white'} />,
		},
		{
			label: 'Download App',
			key: 'downloadApp',
			icon: <Phone02 width={20} height={20} color={'stroke-white'} />,
		},
	];

	const menuSuperAdmin = [
		{
			label: '',
			key: 'contacts',
			icon: <UserSquare width={20} height={20} color={'stroke-white'} />,
		},
		{
			label: 'Download App',
			key: 'downloadApp',
			icon: <Phone02 width={20} height={20} color={'stroke-white'} />,
		},
	];

	const adminProfileData = [
		{
			label: t('Admin.data.menu.setting.setting'),
			key: 'settings',
			icon: <Settings01 width={24} height={24} color={'stroke-white'} />,
		},
		{
			label: "Logout",
			key: 'logout',
			icon: <LogOut04 width={24} height={24} color={'stroke-white'} />,
		},
	];

	const adminProfile = user.profile.role != USER_ROLES.USER && activeMenu === "downloadApp" ? adminProfileData : [adminProfileData[0]];

	const filteredMenuAdmin = isSuperAdmin ? menuSuperAdmin : menuAdmin;

	const onChangeAdminProfie = (value: string) => {
		if (value === 'settings') {
			fetchSettingApiKey();
			setIsModalSettingsOpened(true);
		} else {
			props.onClick(value);
		}
	};

	const fetchSettingApiKey = async () => {
		const data = await dispatch(getSettingApiKey());
		setApiKey(data.payload?.openaiApiKey);
		setSettingData(data.payload);
		setInviteCode(data.payload?.client?.inviteCode);
	};

	const popoverContent = (item: string) => {
		return <span className="popup-label-capitalize">{item}</span>;
	};

	return (
		<div>
			<ul className="admin-menu">
				<li className="logo" onClick={() => onChangeHome('')}>
					<Logomark />
				</li>
				{filteredMenuAdmin.map((item: IMenuAdminProps, index) => {
					if (item.key == 'contacts') {
						return (
							<Popover
								key={index}
								content={popoverContent(
									t('Admin.data.menu.contacts.patients.patients'),
								)}
								title=""
								placement="right">
								<li
									className={`menu-item ${activeMenu === item.key ? 'menu-item-active' : ''}`}
									onClick={() => {
										isSuperAdmin
											? onChangeHome('')
											: handleNavigation(router.SELECTUSER, {}, () => {
												setSelectedItem(null);
												setSearchQuery('');
												setActiveMenu(item.key);
											});
										setSelectedItem(null);
										setSearchQuery('');
										setActiveMenu(item.key);
									}}>
									{item.icon}
								</li>
							</Popover>
						);
					} else if (item.key == 'activity') {
						return (
							<Popover
								key={index}
								content={popoverContent(item.key)}
								title=""
								placement="right">
								<li
									className={`menu-item ${activeMenu === item.key ? 'menu-item-active' : ''}`}
									onClick={() => {
										if (location.pathname.includes('/program/start')) {
											handleNavigation(router.SELECTACTIVITYUSER, {}, () => {
												setActiveMenu(item.key);
											});
										} else {
											navigate(router.SELECTACTIVITYUSER)
											setActiveMenu(item.key);
										}
									}}>
									{item.icon}
								</li>
							</Popover>
						);
					} else if (item.key == 'downloadApp') {
						return (
							<Popover
								key={index}
								content={popoverContent(item.label)}
								title=""
								placement="right">
								<li
									className={`menu-item ${activeMenu === item.key ? 'menu-item-active' : ''}`}
									onClick={() => {
										navigate(`/${user?.id}${router.DOWNLOAD_APP}`);
										setActiveMenu(item.key);
									}}>
									{item.icon}
								</li>
							</Popover>
						);
					}
					else if (item.key == 'aiAssistant') {
						return (
							<Popover
								key={index}
								content={popoverContent(item.label)}
								title=""
								placement="right">
								<li
									className={`menu-item ${activeMenu === item.key ? 'menu-item-active' : ''}`}
									onClick={() => {
										location.pathname.includes('/program/start')
											? handleNavigation(
												`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.ACTIVITY}`,
												{},
												() => setActiveMenu(item.key),
											)
											: setActiveMenu(item.key);
									}}>
									{item.icon}
								</li>
							</Popover>
						);
					} else {
						return (
							<Popover
								key={index}
								content={popoverContent(item.label)}
								title=""
								placement="right">
								<li
									className={`menu-item ${activeMenu === item.key ? 'menu-item-active' : ''}`}
									onClick={() =>
										location.pathname.includes('/program/start')
											? handleNavigation(router.ROOT, {}, () => onChangeHome(item.key))
											: onChangeHome(item.key)
									}
									key={index}
									title={item.key}>
									{item.icon}
								</li>
							</Popover>
						);
					}
				})}
			</ul>
			<ul className="admin-profile">
				{adminProfile.map((item: IMenuAdminProps, index) => {
					return (
						<Popover
							key={index}
							content={popoverContent(item.key)}
							title=""
							placement="right">
							<li
								className={`profile-item ${activeMenu === item.key ? 'menu-item-active' : ''}`}
								onClick={() => item.key === "settings" ? onChangeAdminProfie(item.key) : props.onClick('logout')}
								key={index}>
								{item.icon}
							</li>
						</Popover>
					);
				})}
				<Settings
					isModalOpen={isModalSettingsOpened}
					setIsModalSettingsOpened={setIsModalSettingsOpened}
					settingData={settingData}
					apiKey={apiKey}
					setApiKey={setApiKey}
					inviteCode={inviteCode}
				/>
			</ul>
		</div>
	);
}
