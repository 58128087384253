import { useEffect, useState } from 'react'
import { MagicWand01 } from '@carespace-icons/editor/magicWand01'
import { useTranslation } from 'react-i18next'
import { ISurvey } from '@stores/interfaces'

const SelectedSurvey = (selectedSurvey: {selectedSurvey: ISurvey}) => {
  const { t } = useTranslation();


  return (<>
    {
      <div className={`mt-2 flex justify-start items-start gap-[9px]`}>
        <span className="rounded-full bg-primary-100 py-1 px-1.5">
          <MagicWand01 width={20} height={20} color="stroke-primary-600" />
        </span>
        <div className="header-container">
        <p className="header" style={{fontWeight:700}}>{t('Admin.data.menu.aiAssistant.aiVirtualAssessment.aiAssistant')}</p>
          <p className="header-title typing">{selectedSurvey?.selectedSurvey?.title}</p>
          <p className="header-description">{selectedSurvey?.selectedSurvey?.description}</p>
        </div>
      </div>
    }
  </>)
}

export default SelectedSurvey