import { IOnchangeStatus, ISurveyResult, ISurveyResultQuestionList, IstatusIcon, Status, SummaryContentProps } from '@stores/interfaces';
import { Checkbox, Collapse, Dropdown, Menu, Pagination, Space, Spin, message } from 'antd';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { StatusNormalized } from '@stores/constants';
import { updateSurveySessionStatus as updateSurveySessionStatusOutOfParams } from '@stores/outOfParams';
import { updateSurveySessionStatus as updateSurveySessionStatusPendingReview } from '@stores/pendingReview';
import { updateSurveySessionStatus as updateSurveySessionStatusReviewed } from '@stores/reviewed';
import { updateSurveySessionStatus as updateSurveySessionStatusEscalationRequired } from '@stores/escalationRequired';
import { updateSurveySessionStatus as updateSurveySessionStatusFollowUpRequired } from '@stores/followUpRequired';
import Badge from '@atoms/Badge';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { addReportId, deleteReportId } from '@stores/report/reports';

const { Panel } = Collapse

export default function SurveySummaryContent(props: SummaryContentProps) {
  const { handlePanelChange, handleClick, item, activePanelKey, index, selectedCollapse, setSelectedCollapse, isLoading, surveySessionData, setSurveyResultIds, getPainLevel, totalCount, pageHandle, currentCount, fetchSurveyByIdData, surveyIdData, setSurveyIdData } = props
  const { state } = useLocation()
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();
  const user = useTypedSelector((state) => state.user);
  const [statusSave, setStatusSave] = useState('')
  const reportIds = useTypedSelector(state => state.reports.reportIds)
	const stateId = useTypedSelector(state => state.aiAssistant.aiAssistant.stateId)
	const surveyStateId = useTypedSelector(state => state.aiAssistant.aiAssistant.surveyStateId)

  useEffect(() => {
    if (statusSave) {
      fetchSurveyByIdData(surveyIdData, currentCount);
    }
  }, [statusSave])

  useEffect(() => {
    if (state?.session?.surveyId) {
      fetchSurveyByIdData(state?.session?.surveyId, 1)
      setSurveyIdData(state?.session?.surveyId)
    }
  }, [])

  const statusIcons: IstatusIcon = {
    outOfParams: <Badge color="black" />,
    pendingReview: <Badge color="yellow" />,
    reviewed: <Badge color="moss" />,
    escalationRequired: <Badge color="rose" />,
    followUpRequired: <Badge color="orange" />,
  };

  type IUpdateRomSessionStatus = Omit<Record<Status, any>, 'newPatients'>;

  const updateSurveySession: IUpdateRomSessionStatus = {
    outOfParams: updateSurveySessionStatusOutOfParams,
    pendingReview: updateSurveySessionStatusPendingReview,
    reviewed: updateSurveySessionStatusReviewed,
    escalationRequired: updateSurveySessionStatusEscalationRequired,
    followUpRequired: updateSurveySessionStatusFollowUpRequired,
  };

  const onChangeStatus = async (params: IOnchangeStatus, item: any) => {
    const { sessionId, status } = params;
    message.loading({
      content: t('Admin.data.menu.aiAssistant.aiAssistantRomSummary.saving'),
      key: sessionId,
    });
    await dispatch(
      updateSurveySession[item.status as keyof IUpdateRomSessionStatus]({
        sessionId,
        body: { status },
      }),
    );
    message.success({
      content: t(
        'Admin.data.menu.aiAssistant.aiAssistantRomSummary.statusSuccess',
      ),
      key: sessionId,
      duration: 3,
    });
    fetchSurveyByIdData(surveyIdData, currentCount);
  };

  const healthStatus = (item: any) => {
    const menu = (
      <Menu>
        {Object.entries(StatusNormalized)
          .filter(([key]) => key !== item.status)
          .map(([status, label]) => {
            return (
              <Menu.Item
                key={status}
                onClick={() => {
                  onChangeStatus(
                    { sessionId: item.id, status: status as Status },
                    item,
                  )
                  setStatusSave(status)
                }}
              >
                <Space>
                  <span className="dropdown-badge">
                    {statusIcons[status as keyof IstatusIcon]}
                  </span>
                  <span>{label}</span>
                </Space>
              </Menu.Item>
            );
          })}
      </Menu>
    );
    return (
      <div className="flex justify-between items-center">
        <div className="align-middle mt-[0.20rem] pr-3">
          {user.isPhysioterapist ? (
            <Dropdown overlay={menu}>
              <a onClick={(e) => e.stopPropagation()}>
                {statusIcons[item.status as keyof IstatusIcon]}
              </a>
            </Dropdown>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  };

  return (
    <Collapse defaultActiveKey={surveyStateId} className="collapse-panel-container" activeKey={activePanelKey === item.id ? [item.id] : undefined} bordered={false} key={item.id} onChange={() => {
      handlePanelChange(item.id);
      handleClick(item.id);
    }}>
      <Panel
        className='header-panel !w-full bg-white !border !border-gray-200 !rounded-xl !mt-1'
        key={item.id}
        header={<div className="flex justify-between">
          <div className="flex gap-2">
            <p className="text-sm font-semibold text-gray-600">
              {item.title ? item.title : `Survey Result - ${index + 1}`}
            </p>
          </div>
        </div>}
      >
        <>
          {isLoading ? (
            <Spin tip="Loading" className="flex items-center justify-center p-2" size="large" />
          ) : (
            <>
              <Collapse
                bordered={false}
                defaultActiveKey={selectedCollapse ? selectedCollapse : stateId}
                onChange={(key) => setSelectedCollapse(key)}
                className={`select-none bg-white`}
              >
                {
                  surveySessionData?.map((item: ISurveyResult) => {
                    let totalScore = 0;
                    item.questionList.forEach((question: ISurveyResultQuestionList) => {
                      totalScore += question.score || 0;
                    });
                    return (
                      <Panel
                        className="header-panel bg-gray-50 !border !border-gray-200 !rounded-lg mt-3"
                        header={<div className="flex justify-between">
                          <div className="flex gap-2">
                            <p className="text-sm font-semibold text-gray-600">
                              {moment(item?.createdAt).local().format('LLL')}
                            </p>
                          </div>
                          <div className="flex items-center justify-center gap-2">
                            {healthStatus(item)}
                            {totalScore != 0 &&
                              <p className="flex h-fit items-center gap-1 px-2.5 py-0.5 pb-1 rounded-full bg-success-500 text-white">Score : {totalScore}</p>
                            }
                            <Checkbox
                              checked={reportIds?.surveyResultIds?.includes(item.id)}
                              onClick={(e) => {
                                e.stopPropagation()
                                if (e.target.checked) {
                                  dispatch(addReportId({
                                    type: "surveyResultIds",
                                    id: item.id
                                  }))
                                } else {
                                  dispatch(deleteReportId({
                                    type: "surveyResultIds",
                                    id: item.id
                                  }))
                                }
                              }}
                            />
                          </div>
                        </div>}
                        key={item?.id}
                      >
                        {
                          item.questionList.map((question: ISurveyResultQuestionList, index: number) => (
                            <div key={index} className="bg-gray-50 mb-2 p-[15px] rounded-md">
                              <div className="flex justify-between">
                                <p className="font-bold leading-5 h-8">{index + 1} - {question.question}</p>
                                {question?.score != null &&
                                  <p className="flex h-fit items-center gap-1 px-2.5 py-0.5 pb-1 rounded-full bg-success-500 text-white">{question.score}</p>
                                }
                              </div>
                              <hr />
                              {question.answerList?.length === 0 && question.questionType === 'rate' ? (
                                <p className="mt-2">{getPainLevel(question.ratingLevel!, question)}</p>
                              ) : question.answerList?.length! > 0 && question.questionType !== 'rate' ? (
                                <ul>
                                  {question.answerList?.map((answer: string, index: number) => (
                                    <li key={index} className="mt-2 mb-1">- {answer}</li>
                                  ))}
                                </ul>
                              ) : <>
                                <ul>
                                  <li key={index} className="mb-1">- {question.selectedAnswer}</li>
                                </ul>
                              </>
                              }
                            </div>
                          ))
                        }
                      </Panel>
                    )
                  })
                }
              </Collapse>
              <div>
                {totalCount > 9 && (
                  <Pagination
                    current={currentCount}
                    onChange={pageHandle}
                    total={totalCount}
                  />
                )}
              </div>
            </>
          )}
        </>
      </Panel>
    </Collapse>
  )
}

