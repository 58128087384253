import { IProgramExercise } from "@stores/interfaces"
import { Input } from "antd"
import { useTranslation } from "react-i18next"
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Menu01 } from "@carespace-icons/general/menu01";
import { MdAdd } from "react-icons/md";
import { PlayCircle } from "@carespace-icons/media/playCircle";
import { showCustomModal } from "@atoms/ACustomModalInfo";

interface AddExerciseItemProps {
  length: number;
  updatePropertyAtIndex: (index: number, newReps: number, property: string) => void
  index: number
  exercise: IProgramExercise
  id: string
  handleRemoveExercise: (id: number) => void
}

export const AddExerciseItem = ({ length, exercise, index, id, updatePropertyAtIndex, handleRemoveExercise }: AddExerciseItemProps) => {

  const { t } = useTranslation()
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return <div
    className="flex justify-between items-center relative"
    key={exercise.id}
    style={style}
    {...attributes}
  >
    <div
      ref={setNodeRef}
      {...listeners}
      className="p-1 bg-gray-200 h-[95px] flex items-center justify-center"
    >
      <Menu01 color="stroke-gray-500" />
    </div>
    <div className="bg-gray-50 my-2 p-2 grid grid-cols-6 gap-4 items-center">
      <div className="col-span-2 flex gap-2 items-center">
        <div
          className="custom-image-container"
          style={{ width: "5rem" }}
          onClick={(e) => {
            e.stopPropagation();
            showCustomModal({
              video: exercise?.video ? exercise?.video : exercise?.exerciseLibrary?.videoUrl,
              name: exercise?.name ? exercise?.name : exercise?.exerciseLibrary?.title,
              description: exercise?.description ? exercise?.description : exercise?.exerciseLibrary?.description || "N/A",
            })
          }}
        >
          {
            exercise?.image
              ? <div className="image-wrapper w-20 h-20 flex-shrink-0">
                <img src={exercise?.image} className="w-20 h-20 border border-primary-200" alt="" />
                <div className="play-button">
                  <PlayCircle width={50} height={50} />
                </div>
              </div>
              : <div className="image-wrapper w-20 h-20 flex-shrink-0">
                <video src={exercise?.video ? exercise?.video : exercise?.exerciseLibrary?.videoUrl} controls={false} className="rounded-lg w-full h-full" />
                <div className="play-button">
                  <PlayCircle width={50} height={50} />
                </div>
              </div>
          }
        </div>
        <p className="col-span-1 mx-3 font-semibold">{exercise?.name ? exercise?.name : exercise?.exerciseLibrary?.title}</p>
      </div>
      <div className="col-span-1">
        <label>*{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.repititions')}</label>
        <Input
          type="number"
          className="w-full"
          placeholder={t('Admin.data.menu.aiAssistant.aiAssistantPrograms.repititions')}
          defaultValue={exercise?.name ? exercise?.reps : exercise?.exerciseLibrary?.reps}
          onChange={(e) => updatePropertyAtIndex(index, parseInt(e.target.value), 'reps')}
        />
        {(exercise?.reps > 100 || exercise?.reps < 1) && <p className="text-xs h-12 text-red-400">{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.repititions')} should between 1 to 100</p>}
      </div>
      <div className="col-span-1">
        <label>*{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.setsPerSession')}</label>
        <Input
          type="number"
          className="w-full"
          placeholder={t('Admin.data.menu.aiAssistant.aiAssistantPrograms.setsPerSession')}
          defaultValue={exercise?.name ? exercise?.sets : exercise?.exerciseLibrary?.sets}
          onChange={(e) => updatePropertyAtIndex(index, parseInt(e.target.value), 'sets')}
        />
        {(exercise?.sets > 100 || exercise?.sets < 1) && <p className="text-xs h-12 text-red-400">{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.setsPerSession')} should between 1 to 100</p>}
      </div>
      <div className="col-span-1">
        <label>*{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.setsPerDay')}</label>
        <Input
          type="number"
          className="w-full"
          placeholder={t('Admin.data.menu.aiAssistant.aiAssistantPrograms.setsPerDay')}
          defaultValue={exercise?.name ? exercise?.dailyReps : exercise?.exerciseLibrary?.dailyReps}
          onChange={(e) => updatePropertyAtIndex(index, parseInt(e.target.value), 'dailyReps')}
        />
        {(exercise?.dailyReps > 100 || exercise?.dailyReps < 1) && <p className="text-xs h-12 text-red-400">{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.setsPerDay')} should between 1 to 100</p>}
      </div>
      <div className="col-span-1">
        <label>*{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.setsPerWeek')}</label>
        <Input
          type="number"
          className="w-full"
          placeholder={t('Admin.data.menu.aiAssistant.aiAssistantPrograms.setsPerWeek')}
          defaultValue={exercise?.name ? exercise?.weeklyReps : exercise?.exerciseLibrary?.weeklyReps}
          onChange={(e) => updatePropertyAtIndex(index, parseInt(e.target.value), 'weeklyReps')}
        />
        {(exercise?.weeklyReps > 100 || exercise?.weeklyReps < 1) && <p className="text-xs h-12 text-red-400">{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.setsPerWeek')} {t('Admin.data.menu.aiAssistant.aiAssistantPrograms.between')}</p>}
      </div>
    </div>
    {length > 1 && <div
      className="p-1 bg-gray-100 h-[95px] flex items-center justify-center"
    >
      <MdAdd onClick={() => handleRemoveExercise(exercise?.id)} className="w-6 h-6 text-white transform rotate-45 rounded-full bg-red-400 hover:bg-red-600" />
    </div>}
  </div>
}