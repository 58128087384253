import { Key02 } from '@carespace-icons/security/key02';
import { Button, Input, Modal, message } from 'antd';
import { useDispatch } from 'react-redux';
import {
	patchSettingApiKey,
	postSettingApiKey,
} from '@stores/settings/setings';
import { useTranslation } from 'react-i18next';
import { APIKeyData } from '@stores/interfaces';
import './style.css'

interface OpenAiSettingProps {
	isModalOpen: boolean;
	setIsModalSettingsOpened: (value: boolean) => void;
	settingData: APIKeyData | undefined;
	apiKey: string;
	setApiKey: (value: string) => void;
}

const OpenAiSettings = (props: OpenAiSettingProps) => {
	const {
		isModalOpen,
		setIsModalSettingsOpened,
		settingData,
		apiKey,
		setApiKey,
	} = props;

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const handleSave = async () => {
		if (!apiKey?.trim()) {
			message.error(t('Admin.data.menu.setting.openAi.apiRequired'));
		} else {
			try {
				if (!settingData?.openaiApiKey) {
					const payload = {
						openaiApiKey: apiKey,
					};
					dispatch(postSettingApiKey(payload));
					message.success(t('Admin.data.menu.setting.openAi.apiSuccess'));
				} else {
					const updatedpayload = {
						openaiApiKey: apiKey,
						openaiApiKeyActive: settingData?.openaiApiKeyActive,
					};
					dispatch(
						patchSettingApiKey({
							settingsId: settingData?.id,
							payload: updatedpayload,
						}),
					);
					message.success(t('Admin.data.menu.setting.openAi.apiUpdate'));
				}
				setIsModalSettingsOpened(false);
			} catch (error) {
				message.error(t('Admin.data.menu.setting.openAi.error'));
			}
		}
	};

	return (
		<div className="record-form-content" style={{ display: 'grid', gap: '1.25rem' }}>
			<li>
				<p className="main-container-setting-modal mt-4">
					{t('Admin.data.menu.setting.openAi.description')}{' '}
					<a href="https://platform.openai.com/signup" target="_blank">
						<span style={{ textDecorationLine: 'underline' }}>
							{t('Admin.data.menu.setting.openAi.description2')}
						</span>
					</a>
				</p>
			</li>
			<li>
				<Input
					prefix={<Key02 width={15} height={15} color={'stroke-gray-500'} />}
					placeholder={t('Admin.data.menu.setting.openAi.apikeyPlaceholder')}
					onChange={(e) => setApiKey(e.target.value)}
					value={apiKey}
				/>
			</li>

			<li>
				<Button
					htmlType="submit"
					block
					type="primary"
					onClick={handleSave}
				>
					{t('Admin.data.menu.setting.openAi.save')}
				</Button>
			</li>
		</div>
	);
};

export default OpenAiSettings;
