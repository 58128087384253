import { useEffect, useState, useRef } from "react";
import { ChevronRight } from "@carespace-icons/arrows/chevronRight";
import { ChevronLeft } from "@carespace-icons/arrows/chevronLeft";
import { useTranslation } from "react-i18next";
import { useTypedDispatch, useTypedSelector } from "@stores/index";
import { getProgramList, selectExercise, selectProgram } from "@stores/aiAssistant/program";
import { Button, Empty, Spin } from "antd";
import { PlayCircle } from "@carespace-icons/media/playCircle";
import { setActiveTab, setProgramStateId, setStateId } from "@stores/aiAssistant/aiAssistant";
import { useNavigate } from "react-router-dom";
import { useBlockNavigation } from "@atoms/ABlockNavigation";
import { router } from "@routers/routers";
import moment from "moment";
import { Link01 } from "@carespace-icons/general/link01";
import { getUserById, saveState, selectUser } from "@stores/contacts/contacts";
import { Calendar } from "@carespace-icons/time/calendar";

interface IProgramItem {
  thumbnail: string
  exercises: {
    image?: string;
    exerciseLibrary?: {
      videoUrl?: string;
    };
  }[];
  name: string;
  updatedAt?: string;
  duration?: number;
  durationType?: string
}

interface IProgramPatient {
  programData: []
  setProgramData: (val: []) => void
  fetchHomeData: (vlaue: number) => void
  pagination: {
    pageCount: number;
    totalCount: number;
  }
}

export default function MyProgramsPatientOnboard(props: IProgramPatient) {
  const { programData, setProgramData, fetchHomeData, pagination } = props
  const [activeImage, setActiveImage] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const user = useTypedSelector(state => state.user);
  const { selectedUser } = useTypedSelector(state => state.contacts.main);
  const navigate = useNavigate();
  const { clearNavigation } = useBlockNavigation();
  const dispatch = useTypedDispatch();
  const [arrowShow, setArrowShow] = useState(false);

  useEffect(() => {
    fetchHomeData(currentPage);
  }, [currentPage]);

  const handleNextPage = () => {
    pagination.pageCount != currentPage && setCurrentPage(prev => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
    }
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      const currentPosition = scrollRef.current?.scrollLeft;
      const targetPosition = currentPosition - 400;
      scrollToPosition(targetPosition, 300);
    }
    if (scrollRef.current?.scrollLeft === 0) {
      handlePreviousPage();
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      const currentPosition = scrollRef.current?.scrollLeft;
      const targetPosition = currentPosition + 400;
      scrollToPosition(targetPosition, 300);
    }
    if (
      scrollRef.current &&
      scrollRef.current.scrollLeft + scrollRef.current.offsetWidth >= scrollRef.current.scrollWidth
    ) {
      handleNextPage();
    }
  };

  const scrollToPosition = (targetPosition: number, duration: number) => {
    const startTime = performance.now();
    const start = scrollRef.current?.scrollLeft;
    const scroll = (timestamp: number) => {
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1);
      if (scrollRef.current) {
        scrollRef.current.scrollLeft = start! + (targetPosition - start!) * progress;
      }
      if (progress < 1) {
        requestAnimationFrame(scroll);
      }
    };
    requestAnimationFrame(scroll);
  };

  const handleImageClick = (index: number) => {
    setActiveImage(index);
  }

  const handleViewHistory = async (item: { id: string; }) => {
    const id = user?.isPhysioterapist ? selectedUser?.id : user?.id;
    clearNavigation();
    dispatch(
      saveState({
        activeTab: "Let's Move",
        userId: id,
      }),
    );
    navigate(`/${id}${router.AIASSISTANT_LIST_SESSIONS}`);
    dispatch(setActiveTab('listSessions'));
    await dispatch(getUserById(id));
    dispatch(setStateId(item?.id));
    dispatch(setProgramStateId(item?.id));
  };

  const startProgramSession = (item) => {
    dispatch(selectProgram(item));
    dispatch(selectExercise(item?.exercises));
    clearNavigation();
    navigate(
      `/${user.isPhysioterapist ? selectedUser.id : user.id}${router.AIASSISTANT_PROGRAM_START}`,
    );
    dispatch(setActiveTab(''));
  };

  return (
    <>
      {(!user?.profile?.isWellnessCheck || (programData.length > 0 && user?.profile?.isWellnessCheck)) &&
        <div className="program-main-container-css">
          <p className="flex flex-col pb-4 sm:flex-row sm:items-center sm:justify-between text-primary-800 font-inter text-[22px] font-bold">
            <span>{t('Patient.data.newDashboard.myProgram')}</span>
            <span className="text-gray-800 text-[16px] font-normal">
              {t('Patient.data.newDashboard.myProgramTips')}
            </span>
          </p>
          {programData?.length > 0 ?
            <div className="image-navigation-container">
              <div className="horizontal-scroll-container relative" ref={scrollRef}>
                {programData?.map((item: IProgramItem, index) => {
                  return (
                    <div
                      key={index}
                      className={`custom-image-container-form ${activeImage === index ? "w-[330px] h-[330px]" : "w-[300px] h-[300px]"}`}
                      onMouseOver={() => !arrowShow && handleImageClick(index)}
                      onMouseLeave={() => setActiveImage(null)}
                    >
                      <div className="image-wrapper-form">
                        {item?.exercises[0]?.image ? (
                          <img
                            src={item?.thumbnail ? item?.thumbnail : item?.exercises[0] && item?.exercises[0]?.image}
                            alt={item?.exercises[0]?.image}
                            className="w-full h-[200px] rounded-t-lg"
                          />
                        ) : (
                          <video
                            preload="metadata"
                            src={item?.exercises[0]?.exerciseLibrary?.videoUrl}
                            className="w-full h-[200px] rounded-t-lg"
                          />
                        )}
                        <div className="overlay-content-css">
                          <p className={`text-white font-medium text-lg ${activeImage === index ? "mb-2" : ""}`}>{item?.name}</p>
                          {activeImage != null && <div className="hover-details extra-details-div">
                            <Button
                              className={`!bg-transparent !border-0 !border-inherit text-white text-[11px] -mb-2 z-10 -mr-[15px]`}
                              icon={<Link01 height={10} width={10} />}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleViewHistory(item);
                              }}
                            >
                              {t('Patient.data.newDashboard.viewHistory')}
                            </Button>
                          </div>}
                          <Button
                            className='start-session-css font-semibold px-1 my-2 z-10 w-full'
                            onClick={(e) => {
                              e.stopPropagation();
                              startProgramSession(item);
                            }}
                          >
                            {t('Patient.data.myProgress.rehab.startSession')}
                          </Button>
                          <div className="hover-details">
                            {item?.exercises && (
                              <><div className="exercise-count">
                                {`${item?.exercises.length} ${t(
                                  `Patient.data.newDashboard.${item?.exercises.length === 1 ? 'exercise' : 'exercises'}`
                                )}`}
                              </div>
                                <div className="exercise-count-duration">
                                  <Calendar height={20} width={20} /> {item?.duration}{" "}
                                  {item?.durationType
                                    ? item?.duration! > 1
                                      ? item.durationType.charAt(0).toUpperCase() + item.durationType.slice(1)
                                      : item.durationType.charAt(0).toUpperCase() + item.durationType.slice(1).replace(/s$/, "")
                                    : ""}
                                </div></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="navigation-buttons">
                {pagination?.totalCount > 4 && <>
                  <span
                    className="p-2 absolute top-1/2 left-3 transform -translate-y-1/2 bg-black/35 rounded-full cursor-pointer"
                    onClick={scrollLeft} onMouseOver={() => setArrowShow(true)} onMouseLeave={() => setArrowShow(false)}>
                    <ChevronLeft />
                  </span>
                  <span
                    className="p-2 absolute top-1/2 right-3 transform -translate-y-1/2 bg-black/35 rounded-full cursor-pointer"
                    onClick={scrollRight} onMouseOver={() => setArrowShow(true)} onMouseLeave={() => setArrowShow(false)}>
                    <ChevronRight />
                  </span>
                </>}
              </div>
            </div> :
            <div className="flex gap-4 pb-2 justify-center">
              <img src="/images/dashboard/suggested-program-empty.svg" alt="program-empty" />
              <div className="text-xl font-semibold text-gray-blue-700 w-[369px] flex flex-col justify-center">
                <p>{t('Patient.data.newDashboard.programEmptyTitle')}</p>
                <p>{t('Patient.data.newDashboard.programEmptyDescription')}</p>
              </div>
            </div>
          }
        </div>
      }
    </>
  );
}
