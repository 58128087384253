import { VoiceControl } from '@atoms/ARehabPreAssesment/VoiceControl';
import Lottie from 'lottie-react';
import { useEffect } from 'react';
import checkLogo from './VoiceRecordingLogo.json';
import { PiMicrophoneFill } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';

interface ExerciseTransitionTimeProps {
	transitionTime: number;
	setTransitionTime: (value: number) => void;
	setIsManual: (value: boolean) => void;
	setSavedVoice: (value: string) => void;
	savedVoice: string;
}

export const ExerciseTransitionTime = ({
	transitionTime,
	setSavedVoice,
	savedVoice,
	setTransitionTime,
	setIsManual,
}: ExerciseTransitionTimeProps) => {
	const transitionOptions = [3, 5, 8, 10];
	const { t } = useTranslation();

	useEffect(() => {
		const voiceLowerCase = savedVoice.toLowerCase();
		if (savedVoice) {
			if (voiceLowerCase.includes('3') || voiceLowerCase.includes('three')) {
				setTransitionTime(3);
			} else if (
				voiceLowerCase.includes('5') ||
				voiceLowerCase.includes('five')
			) {
				setTransitionTime(5);
			} else if (
				voiceLowerCase.includes('8') ||
				voiceLowerCase.includes('eight')
			) {
				setTransitionTime(8);
			} else if (
				voiceLowerCase.includes('10') ||
				voiceLowerCase.includes('ten')
			) {
				setTransitionTime(10);
			} else if (voiceLowerCase.includes('manual')) {
				setIsManual(true);
				setTransitionTime(3);
			}
		}
	}, [savedVoice]);

	VoiceControl((updatedTranscript: string) => {
		setSavedVoice(updatedTranscript);
	});

	return (
		<div
			style={{
				width: '100%',
				position: 'absolute',
				background: '#6941C6D9',
				zIndex: 10,
				height: '100%',
				color: 'white',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<div
				className="md:scale-[60%] lg:scale-[70%] xl:scale-[90%] 2xl:scale-[100%]"
				style={{
					width: 551,
					zIndex: 10,
					fontSize: 18,
					padding: 20,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					gap: 10,
				}}>
				<p style={{ fontWeight: 600 }}>
					{t('Patient.data.omnirom.transitionTime')}
				</p>
				<p>
					{t('Patient.data.omnirom.transitionExercise')}{' '}
					<span style={{ fontWeight: 600 }}>
						{transitionTime} {t('Patient.data.omnirom.seconds')}
					</span>
					.
				</p>
				<p>{t('Patient.data.omnirom.keepTime')}:</p>

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
						marginBottom: 40,
						marginTop: 20,
					}}>
					{transitionOptions.map(time => {
						return (
							<span
								onClick={() => {
									setTransitionTime(time);
									setIsManual(false);
								}}
								style={{
									paddingTop: 5,
									paddingLeft: 10,
									paddingRight: 10,
									paddingBottom: 5,
									borderRadius: 8,
									cursor: 'pointer',
									border:
										transitionTime == time
											? '2px solid #85E13A'
											: '2px solid white',
									color: transitionTime == time ? '#85E13A' : 'white',
								}}>
								{time} {t('Patient.data.omnirom.sec')}
							</span>
						);
					})}
					<span
						onClick={() => {
							setIsManual(true);
							setTransitionTime(-1);
						}}
						style={{
							paddingTop: 5,
							paddingLeft: 10,
							paddingRight: 10,
							paddingBottom: 5,
							borderRadius: 8,
							cursor: 'pointer',
							border: '2px solid white',
							color: 'white',
						}}>
						{t('Patient.data.omnirom.manualOpt')}
					</span>
				</div>
				<div className="flex justify-end items-center mt-9">
					<div
						className="flex items-center justify-center align-middle"
						style={{ height: '50px', width: '150px' }}>
						<Lottie animationData={checkLogo} loop={true} autoplay={true} />
					</div>
					<PiMicrophoneFill className="text-primary-400 w-7 h-7" />
				</div>
				<p style={{ fontWeight: 600 }}>{t('Patient.data.omnirom.sayLoud')}</p>
			</div>
		</div>
	);
};
