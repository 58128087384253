import { useEffect, useState } from 'react';
import { Input, List, Avatar, message, Pagination, Empty, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './style.css';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { AdminDashboardPatient, Physiotherapist } from '@stores/interfaces';
import {
	getAllAdmin,
	getRegisteredPatientList,
	getUnAssignedPatientList,
	savePhysiotherapistToPatient,
	setRegisteredCount,
	setUnAssignedCount,
} from '@stores/adminPatient';
import { useTranslation } from 'react-i18next';
import { pagination, USER_ROLES } from '@stores/constants';

interface AddPatientToAdminProps {
	activeRowId?: string;
	setActiveRowId: (val: string) => void;
	addPhysiotherapistToUser?: (phiso: Physiotherapist[]) => void;
	currentPage: number;
	isRegistered: boolean;
	filterButton: string;
	searchName: string;
	role: string;
	handleTogglePhysiotherapist?: (physiotherapist: Physiotherapist) => void;
	setIsAddAdminModalOpen?: (val: boolean) => void;
	selectedPhysiotherapists?: Physiotherapist[];
	isBulkInvite?: boolean;
}

const AddPatientToAdmin = ({
	activeRowId,
	setActiveRowId,
	selectedPhysiotherapists,
	handleTogglePhysiotherapist,
	setIsAddAdminModalOpen,
	currentPage,
	isRegistered,
	filterButton,
	searchName,
	role,
	isBulkInvite = false,
	addPhysiotherapistToUser,
}: AddPatientToAdminProps) => {
	const allAdminList = useTypedSelector(
		state => state.adminDashboardPatient.allAdminList,
	);
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState('');
	const dispatch = useTypedDispatch();
	const [patientListData, setPatientListData] = useState<
		AdminDashboardPatient[]
	>([]);
	const [selectedAdmins, setSelectedAdmins] = useState<Physiotherapist[]>();
	const [selectedItem, setSelectedItem] = useState<AdminDashboardPatient>();
	const user = useTypedSelector(state => state.user);
	const [currentPageModal, setCurrentPageModal] = useState(
		allAdminList?.pagination?.currentPage || 1,
	);
	const unAssignedPatients = useTypedSelector(
		state => state.adminDashboardPatient.unAssignedPatients,
	);
	const isAdminPagination =
		isRegistered && role === USER_ROLES.ADMIN
			? unAssignedPatients?.pagination
			: allAdminList?.pagination;

	useEffect(() => {
		if (isRegistered && role === USER_ROLES.ADMIN && unAssignedPatients?.data) {
			setPatientListData(unAssignedPatients?.data);
		} else if (allAdminList?.data) {
			setPatientListData(allAdminList?.data);
		}
	}, [allAdminList, unAssignedPatients]);

	useEffect(() => {
		if (selectedPhysiotherapists?.length! > 0)
			setSelectedAdmins(
				selectedPhysiotherapists?.map(item => item?.physiotherapist) ?? [],
			);
	}, [selectedPhysiotherapists]);

	const onPageChange = async (pageNumber: number) => {
		setCurrentPageModal(pageNumber);
		if (isRegistered && role === USER_ROLES.ADMIN) {
			fetchUnAssigned(pageNumber);
		} else {
			fetchDataAllAdmin(pageNumber);
		}
	};

	const fetchDataAllAdmin = (page: number) => {
		const payload = {
			limit: 10,
			page: page,
			search: searchValue,
		};
		dispatch(getAllAdmin(payload));
	};

	const fetchUnAssigned = async (pageNumber: number) => {
		const payload = {
			...pagination,
			nextPage: pageNumber,
			name: searchValue,
		};
		dispatch(getUnAssignedPatientList(payload));
	};

	const handleSearchChange = e => {
		setSearchValue(e.target.value);
	};

	const handlePatientEnter = () => {
		if (!searchValue.trim()) {
			setPatientListData(allAdminList?.data);
			return;
		}
		const filteredUsers = patientListData?.filter(user =>
			user?.profile?.firstName
				?.toLowerCase()
				.includes(searchValue.toLowerCase()),
		);
		if (filteredUsers) {
			setPatientListData(filteredUsers);
		}
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter') {
			if (isRegistered && role === USER_ROLES.ADMIN) {
				fetchUnAssigned(1);
			} else {
				handlePatientEnter();
			}
		}
	};

	const getFetchData = async () => {
		if (isRegistered) {
			const payload = {
				...pagination,
				userId: user?.id,
				name: searchName || '',
				nextPage: searchName ? 1 : currentPage,
				role: filterButton,
			};
			const unAssignedPatient = await dispatch(
				getUnAssignedPatientList(pagination),
			);
			const registeredPatient = await dispatch(
				getRegisteredPatientList(payload),
			);
			!searchName &&
				(await dispatch(
					setUnAssignedCount(
						unAssignedPatient?.payload?.pagination?.totalCount,
					),
				));
			!searchName ||
				(role === USER_ROLES.ADMIN &&
					(await dispatch(
						setRegisteredCount(
							registeredPatient?.payload?.pagination?.totalCount,
						),
					)));
		} else {
			const updatedPagination = {
				...pagination,
				nextPage: currentPage,
			};
			const payload = {
				...pagination,
				userId: user?.id,
			};
			const unAssignedPatient = await dispatch(
				getUnAssignedPatientList(updatedPagination),
			);
			const registeredPatient = await dispatch(
				getRegisteredPatientList(payload),
			);
			await dispatch(
				setUnAssignedCount(unAssignedPatient?.payload?.pagination?.totalCount),
			);
			await dispatch(
				setRegisteredCount(registeredPatient?.payload?.pagination?.totalCount),
			);
		}
	};

	const handleUserClick = async (rowData: AdminDashboardPatient) => {
		setSelectedItem(rowData);
		if (isRegistered && role === USER_ROLES.ADMIN) {
			const data = await dispatch(
				savePhysiotherapistToPatient({
					physiotherapistId: activeRowId,
					patientId: rowData?.id,
				}),
			);
			if (data?.payload) {
				message.success(t('Admin.data.menu.userRoles.successPatientAssigned'));
			}
		} else {
			if (isBulkInvite) {
				if (activeRowId) {
					handleTogglePhysiotherapist!(rowData);
				} else {
					if (selectedAdmins?.find(item => item?.id == rowData?.id)) {
						setSelectedAdmins(
							selectedAdmins?.filter(item => item?.id != rowData?.id),
						);
					} else {
						setSelectedAdmins([...selectedAdmins || [], rowData]);
					}
				}
			} else {
				const data = await dispatch(
					savePhysiotherapistToPatient({
						physiotherapistId: rowData?.id,
						patientId: activeRowId,
					}),
				);
				if (data?.payload) {
					message.success(
						t('Admin.data.menu.userRoles.successPatientAssigned'),
					);
				}
			}
		}
		setActiveRowId('');
		!isBulkInvite && getFetchData();
	};

	return (
		<div
			className="add-to-patient-div"
			style={{ marginTop: isBulkInvite ? 25 : 0 }}>
			<Input
				placeholder={t('Admin.data.menu.userRoles.searchUser')}
				prefix={<SearchOutlined />}
				value={searchValue}
				onChange={handleSearchChange}
				onKeyDown={e => handleKeyDown(e)}
				className="search-input"
			/>
			{patientListData?.length > 0 ? (
				<List
					itemLayout="horizontal"
					style={{ height: '350px', overflow: 'auto' }}
					dataSource={patientListData}
					renderItem={rowData => (
						<List.Item
							className="user-list-item"
							style={{
								margin: '5px',
								borderRadius: '10px',
								padding: '10px',
								backgroundColor: selectedAdmins?.find(
									item => item?.id == rowData?.id,
								)
									? '#ECE9FC'
									: '',
							}}
							onClick={() => handleUserClick(rowData)}>
							<List.Item.Meta
								avatar={
									rowData?.profile?.imageUrl ? (
										<Avatar src={rowData?.profile?.imageUrl} alt="avatar" />
									) : (
										<Avatar
											style={{
												backgroundColor:
													rowData?.profile?.avatarColor || '#1890FF',
											}}
											alt="avatar"
											size={35}>
											{rowData?.profile?.firstName
												? rowData?.profile?.firstName?.charAt(0)?.toUpperCase()
												: 'U'}
										</Avatar>
									)
								}
								title={
									<div>
										<span className="block text-gray-900">
											<strong>
												{rowData?.profile?.firstName ? (
													<>
														{rowData?.profile?.firstName}{' '}
														{rowData?.profile?.lastName}{' '}
													</>
												) : (
													''
												)}
											</strong>
										</span>
										<span className="block text-gray-600">
											{rowData?.profile?.email}
										</span>
									</div>
								}
							/>
						</List.Item>
					)}
				/>
			) : (
				<Empty
					image={Empty.PRESENTED_IMAGE_SIMPLE}
					description={t('No Data')}
				/>
			)}
			{isAdminPagination?.totalCount! > 10 && (
				<div
					style={{
						textAlign: 'center',
						overflow: 'hidden',
						width: '100%',
						padding: '10px 0',
						whiteSpace: 'nowrap',
					}}>
					<Pagination
						defaultCurrent={currentPageModal}
						total={isAdminPagination?.totalCount}
						onChange={onPageChange}
						showSizeChanger={false}
						pageSize={10}
						showLessItems
						style={{ display: 'flex' }}
					/>
				</div>
			)}
			{isBulkInvite && !activeRowId && (
				<div className="bulk-invite-actions" style={{ marginTop: '15px' }}>
					<Button
						size="large"
						style={{ width: '50%' }}
						onClick={() => {
							setIsAddAdminModalOpen!(false);
						}}
						type="default">
						{t('Admin.data.rehab.rehabPreAssessment.closeText')}
					</Button>{' '}
					<Button
						size="large"
						style={{ width: '50%' }}
						htmlType="submit"
						onClick={() => {
							addPhysiotherapistToUser!(selectedAdmins!);
							setIsAddAdminModalOpen!(false);
						}}
						type="primary">
						{t('Admin.data.menu.userRoles.invitePatientModal.save')}
					</Button>
				</div>
			)}
		</div>
	);
};

export default AddPatientToAdmin;
