import { useTranslation } from 'react-i18next';
import './style.less';
import { useEffect, useState } from 'react';
import { CustomCaptureData } from './CustomCaptureData';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { getRomSessionList } from '@stores/rom/results';
import { Collapse, Empty, Pagination, Spin } from 'antd';
import { ICustomRom } from '@stores/interfaces';

export const CustomCaptures = () => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();
	const [selectedCollapse, setSelectedCollapse] = useState<
		string[] | string | undefined
	>();
	const user = useTypedSelector(state => state.user);
	const { selectedUser } = useTypedSelector(state => state.contacts.main);
	const { romSessionListData } = useTypedSelector(state => state.rom.results);
	const id = user.isPhysioterapist ? selectedUser?.id : user?.id;
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		fetchData(1);
	}, [user, selectedUser, dispatch]);

	const fetchData = async (page: number) => {
		await dispatch(getRomSessionList({ patientId: id, page: page, limit: 10 }));
	};

	const onPageChange = async (pageNumber: number) => {
		setLoading(true);
		fetchData(pageNumber);
		setLoading(false);
	};

	return (
		<div className="custom-captures">
			{loading ? (
				<Spin
					className="flex items-center justify-center p-2"
					tip="Loading"
					size="large"
				/>
			) : (
				<>
					{romSessionListData?.data?.length! > 0 ? (
						<>
							<Collapse
								bordered={false}
								defaultActiveKey={selectedCollapse}
								onChange={key => setSelectedCollapse(key)}
								className={`select-none posture-collapse`}>
								{romSessionListData?.data?.map((data: ICustomRom) => {
									return (
										<Collapse.Panel
											className="header-panel bg-gray-50 !border !border-gray-200 !rounded-lg mt-3 mb-3"
											header={data?.title}
											key={data?.id}>
											<Collapse
												bordered={false}
												className={`select-none posture-collapse`}>
												{data?.exercises?.map(exercise => {
													return (
														<Collapse.Panel
															className="header-panel bg-gray-50 !border !border-gray-200 !rounded-lg mt-3 mb-3"
															header={exercise?.name ?? exercise?.title}
															key={exercise?.id}>
															<CustomCaptureData
																exercise={exercise.exercises}
															/>
														</Collapse.Panel>
													);
												})}
											</Collapse>
										</Collapse.Panel>
									);
								})}
							</Collapse>
							{romSessionListData?.pagination?.totalCount! > 5 && (
								<Pagination
									className="flex justify-center"
									current={romSessionListData?.pagination?.currentPage}
									showSizeChanger={false}
									onChange={onPageChange}
									total={romSessionListData?.pagination?.totalCount}
								/>
							)}
						</>
					) : (
						<Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={<span>{t('Admin.data.addToReports.noData')}</span>}
						/>
					)}
				</>
			)}
		</div>
	);
};
