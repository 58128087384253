import { Check } from "@carespace-icons/general/check";
import { Minus } from "@carespace-icons/general/minus";
import React, { useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";

type CheckBoxProps = {
  id: string
  label?: string
  subLabel?: string
  onClick?: (name: string, value: boolean) => void
  isDisabled?: boolean
  checked?: boolean
  isPartial?: boolean
  color?: string
};


const CheckBox = (props: CheckBoxProps) => {

  const { label, subLabel, id = 'checkboxId', isDisabled, onClick, checked = false, isPartial = false, color='text-gray-700' } = props;
  const [isChecked, setIschecked] = useState(checked);
  let activeColor = 'hover:bg-primary-100 hover:border-primary-600 active:shadow-button'
  let checkboxStyle = 'border-2 rounded w-4 h-4 align-top inline-block';
  let disabledStyle = 'bg-gray-100 border-gray-300';
  disabledStyle = twJoin(checkboxStyle, disabledStyle);
  checkboxStyle = twJoin(checkboxStyle, activeColor,
    isChecked ? 'bg-white border-primary-600' : 'bg-white border-gray-300 active:bg-white active:border-primary-300'
  );

  const handleClick = (id: string, checked: boolean) => {
    setIschecked(checked);
    onClick && onClick(id, checked);
  }

  useEffect(()=> {
    setIschecked(checked)
  },[checked])

  return (
    <label htmlFor={id}>
      <input onChange={(e) => handleClick(id, e.target.checked)} type="checkbox" className="collapse absolute" id={id} checked={isChecked} disabled={isDisabled} />
      <div className={isDisabled ? disabledStyle : checkboxStyle}>
        {
          isChecked && <React.Fragment>
            {
              isPartial ? <Minus color={isDisabled ? "stroke-gray-300" : 'stroke-primary-600'} width={12} height={12} /> :
                <Check color={isDisabled ? "stroke-gray-300" : 'stroke-primary-600'} width={12} height={12} />
            }
          </React.Fragment>
        }
      </div>
      <div className="inline-block ml-1 align-top">
        <div className={twJoin("text-sm font-medium", color)}>{label}</div>
        <div className="text-sm font-regular text-gray-600">{subLabel}</div>
      </div>
    </label>
  );
}

export default CheckBox;
