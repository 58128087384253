import { useTypedSelector } from '@stores/index';
import { Avatar } from 'antd';
import { useTranslation } from 'react-i18next';

export default function ManagedBy() {
  const { t } = useTranslation();
  const user = useTypedSelector(state => state.user);
  const selectedUser = useTypedSelector(state => state.contacts.main.selectedUser);

  const physiotherapistData = user?.isPhysioterapist ? selectedUser?.physiotherapistPatientAssociationPatientIdRelation : user?.physiotherapistPatientAssociationPatientIdRelation;

  return (
    <>
      {physiotherapistData?.length > 0 &&
        <ul style={{ background: 'rgba(92, 62, 170, 1)', borderRadius: '0.5rem', marginTop: '10px' }}>
          <div className="functional-button-div pl-4">
            <p className="text-sm font-bold">
              {t('Admin.data.menu.userRoles.physiotherapist')}
            </p>
          </div>
          <div style={{ marginTop: '8px', marginBottom: '10px', paddingLeft: '6px' }}>
            {physiotherapistData?.map((relation) => {
              const physiotherapist = relation?.physiotherapist;
              if (!physiotherapist) return null;
              const { profile } = physiotherapist;
              const fullName = `${profile?.firstName || ''} ${profile?.lastName || ''}`.trim();
              return (
                <div className="flex p-2">
                  {profile?.imageUrl ? (
                    <Avatar
                      style={{ width: '41px', height: '40px', border: '3px solid white' }}
                      src={profile?.imageUrl}
                      alt={`${profile?.firstName} ${profile?.lastName}`}
                    />
                  ) : (
                    <Avatar
                      style={{ backgroundColor: profile?.avatarColor || '#1890FF', width: '41px', height: '40px', border: '3px solid white' }}
                      alt={`${profile?.firstName} ${profile?.lastName}`}
                    >
                      {profile?.firstName ? profile?.firstName.charAt(0).toUpperCase() : 'U'}
                    </Avatar>
                  )}
                  <span className="ml-2 text-[#EAECF0]">
                    <span className="block text-sm font-semibold">
                      <strong>
                        {profile?.firstName ? fullName : ''}
                      </strong>
                    </span>
                    <span className="block text-sm font-regular">
                      {profile?.email}
                    </span>
                  </span>
                </div>
              );
            })}
          </div>
        </ul>
      }
    </>
  )
}
