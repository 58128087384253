import ProfileModal from '@pages/Profile/ProfileModal';
import { IProgramResponse, SelectedUser } from '@stores/interfaces';
import { Avatar, Button, Popover } from 'antd';
import UserDetails from './UserDetails';
import { Edit02 } from '@carespace-icons/general/edit02';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select, { components, StylesConfig } from 'react-select';
import { ArrowLeft } from '@carespace-icons/arrows/arrowLeft';
import { PlayCircle } from '@carespace-icons/media/playCircle';
import { setActiveTab, setStartSession } from '@stores/aiAssistant/aiAssistant';
import {
	getProgramListApproved,
	selectExercise,
	selectProgram,
} from '@stores/aiAssistant/program';
import AdminMenuContactsContent from './AdminMenuContactsContent';
import { useBlockNavigation } from '@atoms/ABlockNavigation';
import { router } from '@routers/routers';
import { USER_ROLES } from '@stores/constants';
import { status } from '@pages/PatientOnboard/Constants';
import HeightInfo from '@atoms/AdminMenu/HeightInfo';
import { getUsers } from '@stores/contacts/contacts';

interface IAdminMenuContactsDataProps {
	setActiveMenu: (value: string) => void;
	handleSelectChange: (Selected: SelectedUser) => void;
	isModalOpen: boolean;
	setIsModalOpen: (value: boolean) => void;
	profileData: {};
	functionalData: [] | undefined;
	activeSubMenu: string;
	selectedItem: SelectedUser | null;
	onClick: (value: string) => void;
	activeSubPatients: string;
	setActiveSubPatients: (value: string) => void;
}

export default function AdminMenuContactsData(
	props: IAdminMenuContactsDataProps,
) {
	const {
		setActiveMenu,
		handleSelectChange,
		setIsModalOpen,
		isModalOpen,
		profileData,
		functionalData,
		activeSubMenu,
		selectedItem,
		onClick,
		activeSubPatients,
		setActiveSubPatients,
	} = props;
	const { t } = useTranslation();
	const { handleNavigation } = useBlockNavigation(
		location.pathname.includes(router.AIASSISTANT_PROGRAM_START),
		t('Admin.data.menu.aiAssistant.aiAssistantListSessions.unsavedChanges'),
	);
	const currentLocation = window.location.pathname;
	const isCorrectLocation = currentLocation.includes(
		router.AIASSISTANT_PROGRAM_START,
	);
	const dispatch = useTypedDispatch();
	const navigate = useNavigate();
	const user = useTypedSelector(state => state.user);
	const program = useTypedSelector(
		state => state.aiAssistant.program.programApproved,
	);
	const programUpdate = useTypedSelector(
		state => state.aiAssistant.program.latestUpdatedProgram,
	);
	const { selectedUser, userDropDownList } = useTypedSelector(
		state => state.contacts.main,
	);
	const isSuperAdmin = user?.profile?.role === USER_ROLES.SUPER_ADMIN;
	const [isProgramAvailable, setProgramAvailable] = useState(false);
	const [policyModalOpen, setPolicyModalOpen] = useState(false);

	const handleArrowClick = () => {
		if (isSuperAdmin) {
			navigate('/');
			setActiveMenu('');
			setActiveSubPatients('');
		} else {
			if (/[a-f0-9]{24}/.test(activeSubMenu)) {
				navigate(-1);
			} else {
				setActiveMenu(activeSubMenu);
			}
		}
	};

	const handleInputChange = (value: string) => {
		if (value.length >= 3) {
			dispatch(getUsers(value));
		} else {
			dispatch(getUsers(''));
		}
	};

	const buttonStyle = {
		color: '#533F85',
		border: 'inherit',
		width: '100%',
		margin: '10px 0',
	};

	const customOption = (props: any) => (
		<components.Option {...props}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{props?.profile?.imageUrl ? (
					<Avatar src={props?.data?.profile?.imageUrl} alt="avatar" size={35} />
				) : (
					<Avatar
						style={{
							backgroundColor: props?.data?.profile?.avatarColor || '#1890FF',
							color: '#FFF',
							fontSize: '20px',
							display: 'inline-flex',
							alignItems: 'center',
						}}
						alt="avatar"
						size={35}>
						{props?.data?.profile?.firstName
							? props?.data?.profile?.firstName?.charAt(0)?.toUpperCase()
							: 'U'}
					</Avatar>
				)}
				<span style={{ marginLeft: 8 }}>
					{props?.data?.profile?.firstName +
						' ' +
						props?.data?.profile?.lastName}
				</span>
			</div>
		</components.Option>
	);

	const handleClick = async () => {
		if (program.data?.length == 1) {
			dispatch(selectProgram(program.data[0]));
			dispatch(selectExercise(program.data[0].exercises));
			navigate(
				`/${user.isPhysioterapist ? selectedUser.id : user.id}${router.AIASSISTANT_PROGRAM_START}`,
			);
			dispatch(setActiveTab(''));
			dispatch(setStartSession(false));
		} else if (program.data?.length! > 1) {
			handleNavigation(
				`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.AIASSISTANT_PROGRAMS}`,
			);
			dispatch(setActiveTab('programs'));
			dispatch(setStartSession(true));
		}
	};

	const customStyles: StylesConfig<SelectedUser, false> = {
		control: provided => ({
			...provided,
			backgroundColor: '#7f56d9',
			border: 'none',
		}),
		placeholder: provided => ({
			...provided,
			color: 'white',
		}),
		input: provided => ({
			...provided,
			color: 'white',
		}),
		singleValue: provided => ({
			...provided,
			color: 'white',
		}),
		dropdownIndicator: (provided, state) => ({
			...provided,
			color: state.isFocused ? 'white' : 'white',
			'&:hover': {
				color: 'white',
			},
		}),
		indicatorSeparator: () => ({
			display: 'none',
		}),
	};

	const fetchHomeData = async (pageNumber: number) => {
		const payload = {
			userId: selectedUser?.id,
			limit: 10,
			page: pageNumber,
			searchValue: '',
			status: status.APPROVED,
		};
		const response = (await dispatch(getProgramListApproved(payload))) as {
			payload: { data: IProgramResponse[] };
		};
		if (response?.payload?.data?.length > 0) {
			setProgramAvailable(true);
		} else {
			setProgramAvailable(false);
		}
	};

	useEffect(() => {
		selectedItem && fetchHomeData(1);
	}, [selectedItem, programUpdate]);

	return (
		<div>
			{!selectedItem && (
				<AdminMenuContactsContent
					onClick={onClick}
					activeSubPatients={activeSubPatients}
					setActiveSubPatients={setActiveSubPatients}
				/>
			)}
			{selectedItem && (
				<>
					{!location.pathname.includes('/program/start') && (
						<div className="admin-menu-contacts-data">
							<Popover
								title=""
								content="Back"
								placement="left"
								style={{ display: 'inline-block' }}>
								<Button
									className="logout-button"
									shape="circle"
									icon={<ArrowLeft />}
									type="text"
									onClick={handleArrowClick}
								/>
							</Popover>
						</div>
					)}
					{!location.pathname.includes('/program/start') && (
						<Select
							className="select-container"
							options={userDropDownList}
							value={selectedItem}
							onChange={handleSelectChange}
							getOptionLabel={(item: SelectedUser) => {
								return item?.profile?.firstName + ' ' + item?.profile?.lastName;
							}}
							getOptionValue={(item: SelectedUser) => item.id.toString()}
							placeholder="Select a Patient"
							onInputChange={value => handleInputChange(value)}
							noOptionsMessage={() => t('Admin.data.menu.noUser')}
							components={{ Option: customOption }}
							styles={customStyles}
						/>
					)}
					<div className="user-main-container">
						<div className="user-container">
							<>
								{selectedUser?.profile?.imageUrl ? (
									<Avatar
										src={selectedUser?.profile?.imageUrl}
										alt="avatar"
										size={100}
									/>
								) : (
									<Avatar
										style={{
											backgroundColor:
												selectedUser?.profile?.avatarColor || '#1890FF',
											color: '#FFF',
											fontSize: '50px',
											border: '4px solid white',
											display: 'inline-flex',
											alignItems: 'center',
										}}
										alt="avatar"
										size={100}>
										{selectedItem?.profile?.firstName
											? selectedItem?.profile?.firstName
													?.charAt(0)
													?.toUpperCase()
											: 'U'}
									</Avatar>
								)}
							</>
							{!location.pathname.includes('/program/start') && (
								<div
									className="edit-container"
									style={{
										backgroundColor: 'rgba(127, 86, 217, 1)',
										width: '30px',
										height: '30px',
										textAlign: 'center',
									}}
									onClick={() => {
										setIsModalOpen(true);
									}}>
									<Edit02 width={24} height={16} />
								</div>
							)}
							{isModalOpen && (
								<ProfileModal
									isModalOpen={isModalOpen}
									setIsModalOpen={setIsModalOpen}
									setPolicyModalOpen={setPolicyModalOpen}
									policyModalOpen={policyModalOpen}
									closable={true}
									onEdit={true}
								/>
							)}
							<p className="user-name-contact" style={{ lineHeight: '0.9' }}>
								{selectedUser?.profile?.firstName}{' '}
								{selectedUser?.profile?.lastName}
							</p>
							<p className="user-email-contact">
								{selectedUser?.profile?.email}
							</p>
							<HeightInfo userDetails={profileData} />
							{isProgramAvailable && !isCorrectLocation && (
								<Button
									className="start-session-css"
									style={buttonStyle}
									icon={
										<PlayCircle
											height={18}
											width={18}
											color="stroke-purple-700"
										/>
									}
									onClick={e => {
										e.stopPropagation();
										handleClick();
									}}>
									{t('Patient.data.myProgress.rehab.startSession')}
								</Button>
							)}
						</div>
						<UserDetails
							userDetails={profileData}
							functionalData={functionalData}
						/>
					</div>
				</>
			)}
		</div>
	);
}
