import { Button, Input, message, Modal, Pagination, Tag } from 'antd';
import { SearchLg } from '@carespace-icons/general/searchLg';
import { Lock01 } from '@carespace-icons/security/lock01';
import { UserPlus01 } from '@carespace-icons/users/userPlus01';
import { useTranslation } from 'react-i18next';
import TableList from '@pages/AdminUnassignedPatients/TableList';
import { Edit01 } from '@carespace-icons/general/edit01';
import { Trash01 } from '@carespace-icons/general/trash01';
import { useEffect, useState } from 'react';
import { Physiotherapist, RegisteredPatientsProps } from '@stores/interfaces';
import AddPatientToAdmin from '@pages/AddPatientToAdmin';
import { pagination, USER_ROLES } from '@stores/constants';
import { useTypedSelector } from '@stores/index';
import Default from './Default';
import { bulkInviteUsers, setUploadProgress } from '@stores/user';
import { useTypedDispatch } from '@stores/index';
import AlreadyRegisteredUser from './AlreadyRegisteredUser';
import {
	getRegisteredPatientList,
	getUnAssignedPatientList,
	setRegisteredCount,
	setUnAssignedCount,
} from '@stores/adminPatient';
import { userCredentialsData } from '@pages/Settings/defaultInviteTemplates';

interface IInviteUser {
	id: string;
	userFeatures: string[];
	physiotherapistPatientAssociationPatientIdRelation: {
		patientId: string;
		physiotherapistId: string;
		physiotherapist: Physiotherapist;
	}[];
	profile: {
		email: string;
		firstName: string;
		lastName: string;
		avatarColor?: string;
		phone: string;
	};
}

interface BulkInviteTableProps {
	setIsInvitePatientModalOpen: (val: boolean) => void;
	fileData: IInviteUser[];
	cancelUploaded: (value: boolean) => void;
	htmlContentTemplate: string;
	mustSendEmail: boolean;
}

export const BulkInviteTable = ({
	fileData,
	cancelUploaded,
	htmlContentTemplate,
	setIsInvitePatientModalOpen,
	mustSendEmail,
}: BulkInviteTableProps) => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();
	const [userData, setUserData] = useState<IInviteUser[]>(fileData);
	const [userSearchData, setUserSearchedData] = useState(userData);
	const [userPaginatedData, setUserPaginatedData] = useState<IInviteUser[]>(
		fileData.slice(0, 10),
	);
	const user = useTypedSelector(state => state.user);
	const [selectedUserData, setSelectedUserData] = useState<IInviteUser>();
	const [searchValue, setSearchValue] = useState<string>('');
	const [defaultPassword, setDefaultPassword] = useState('');
	const [activeRowId, setActiveRowId] = useState<string | null>(null);
	const [filterButton, setFilterButton] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [isEditPatientModalOpen, setIsEditPatientModalOpen] = useState(false);
	const [isAddAdminModalOpen, setIsAddAdminModalOpen] = useState(false);
	const userRole = useTypedSelector(state => state.user.profile.role);

	useEffect(() => {
		setUserPaginatedData(
			(searchValue?.length > 0 ? userSearchData : userData).slice(
				(currentPage - 1) * 10,
				currentPage * 10,
			),
		);
	}, [userData, searchValue]);

	useEffect(() => {
		setUserSearchedData(
			userData.filter(user =>
				(user?.profile?.firstName! + user?.profile?.lastName!)
					?.toLowerCase()
					?.replace(' ', '')
					?.includes(searchValue),
			),
		);
	}, [searchValue]);

	const TEAM_HEADER = [
		{ name: '#', sort: true, key: 'index' },
		{ name: t('Admin.data.menu.userRoles.name'), sort: true, key: 'name' },
		{ name: 'Phone', sort: true, key: 'phone' },
		{
			name: t('Admin.data.menu.userRoles.physiotherapist'),
			sort: true,
			key: 'physiotherapist',
		},
	];

	const actions = [
		{
			icon: <UserPlus01 color="stroke-gray-600" width={15} height={16} />,
			action: (rowData: RegisteredPatientsProps) => handleUserClick(rowData),
			label: t('Admin.data.menu.userRoles.addPhysiotherapist'),
			key: 'userPlus',
		},
		{
			icon: <Edit01 color="stroke-gray-600" width={15} height={16} />,
			action: (rowData: IInviteUser) => {
				setSelectedUserData(rowData);
				setIsEditPatientModalOpen(true);
			},
			label: t('Admin.data.menu.userRoles.edit'),
		},
		{
			icon: <Trash01 color="stroke-gray-600" width={15} height={16} />,
			action: (rowData: RegisteredPatientsProps) => {
				setActiveRowId(null);
				handleDeleteClick(rowData);
			},
			label: t('Admin.data.menu.userRoles.delete'),
		},
	];

	const validateSubmit = async () => {
		if (defaultPassword?.length <= 0) {
			message.error(
				t('Admin.data.menu.userRoles.invitePatientModal.defaultPasswordErr'),
			);
		} else if (defaultPassword?.length < 8) {
			message.error(
				t('Admin.data.menu.userRoles.invitePatientModal.passwordLengthErr'),
			);
		} else {
			const payload = {
				message: htmlContentTemplate + userCredentialsData.replace('{{invite_code}}', user.client.inviteCode),
				invitedRole: USER_ROLES.USER,
				features: [],
				users: userData?.map(user => ({
					firstName: user?.profile?.firstName,
					lastName: user?.profile?.lastName,
					email: user?.profile?.email,
					mobilePhone: user?.profile?.phone?.toString(),
					adminIds:
						user?.physiotherapistPatientAssociationPatientIdRelation?.map(
							item => item?.physiotherapistId,
						),
				})),
				password: defaultPassword,
				mustSendEmail: mustSendEmail,
			};

			const responseData = await dispatch(bulkInviteUsers(payload));
			if (responseData?.payload?.usersAlreadyCreated?.length === 0) {
				message.success(
					t('Admin.data.menu.userRoles.invitePatientModal.dataSuccess'),
				);
			} else {
				message.open({
					content: (
						<AlreadyRegisteredUser
							message={message}
							responseData={responseData}
						/>
					),
					duration: 0,
				});
			}
			setIsInvitePatientModalOpen(false);
			dispatch(setUploadProgress(null))
			const payloadData = {
				...pagination,
				userId: user?.id,
			};
			const unAssigned = await dispatch(getUnAssignedPatientList(pagination));
			const registeredPatient = await dispatch(
				getRegisteredPatientList(payloadData),
			);
			dispatch(setUnAssignedCount(unAssigned?.payload?.pagination?.totalCount));
			dispatch(
				setRegisteredCount(registeredPatient?.payload?.pagination?.totalCount),
			);
		}
	};

	const onPageChange = async (pageNumber: number) => {
		setCurrentPage(pageNumber);
		setUserPaginatedData(
			userData.slice((pageNumber - 1) * 10, pageNumber * 10),
		);
	};

	const selectedPhysiotherapists: Physiotherapist[] = userData.reduce(
		(commonPhysios, patient) => {
			const physioIds = new Set(
				patient.physiotherapistPatientAssociationPatientIdRelation?.map(
					item => item.physiotherapistId,
				) || [],
			);

			return commonPhysios.filter(physio =>
				physioIds.has(physio.physiotherapistId),
			);
		},
		userData[0].physiotherapistPatientAssociationPatientIdRelation || [],
	);

	const handleUserClick = (rowData: RegisteredPatientsProps) => {
		setActiveRowId(rowData.id);
	};

	const handleUserUpdate = () => {
		setUserData(
			userData.map(user =>
				user?.id == selectedUserData?.id
					? {
							...user,
							userFeatures: selectedUserData?.userFeatures,
							profile: {
								firstName: selectedUserData?.profile?.firstName!,
								lastName: selectedUserData?.profile?.lastName!,
								email: selectedUserData?.profile?.email!,
								phone: selectedUserData?.profile?.phone,
							},
						}
					: user,
			),
		);
	};

	const handleDeleteClick = async (obj: RegisteredPatientsProps) => {
		setUserData(userData.filter(user => user?.id != obj.id));
	};

	const addPhysiotherapistToUser = (physiotherapists: Physiotherapist[]) => {
		setUserData(
			userData.map(patient => ({
				...patient,
				physiotherapistPatientAssociationPatientIdRelation:
					physiotherapists?.map(physio => ({
						patientId: patient?.id,
						physiotherapistId: physio?.id,
						physiotherapist: physio,
					})),
			})),
		);
	};

	const handleTogglePhysiotherapist = (
		patientId: string,
		physiotherapist: Physiotherapist,
	) => {
		setUserData(
			userData.map(patient =>
				patient?.id === patientId
					? {
							...patient,
							physiotherapistPatientAssociationPatientIdRelation:
								patient?.physiotherapistPatientAssociationPatientIdRelation.some(
									item => item?.physiotherapistId === physiotherapist?.id,
								)
									? patient.physiotherapistPatientAssociationPatientIdRelation.filter(
											item => item?.physiotherapistId !== physiotherapist?.id,
										)
									: [
											...patient.physiotherapistPatientAssociationPatientIdRelation,
											{
												patientId: patient?.id,
												physiotherapistId: physiotherapist?.id,
												physiotherapist,
											},
										],
						}
					: patient,
			),
		);
	};

	return (
		<div className="bulk-invite-container bulk-invite-table">
			<div className="bulk-invite-user-info">
				<h3
					style={{
						fontSize: '1.125rem',
						lineHeight: '1.75rem',
						fontWeight: 700,
						display: 'flex',
						alignItems: 'center',
					}}>
					{t('Admin.data.newPatients.imported')}
					<span className="total-patient-length">
						<Tag color="purple">
							{userData.length} {t('Admin.data.newPatients.users')}
						</Tag>
					</span>
				</h3>
				<span>{t('Admin.data.menu.userRoles.pendingInvites.description')}</span>
			</div>
			<div
				className="bulk-invite-data-manipulator"
				style={{
					gridTemplateColumns:
						userRole === USER_ROLES.SUPER_ADMIN
							? 'repeat(4, minmax(0, 1fr))'
							: 'repeat(3, minmax(0, 1fr))',
				}}>
				<Input
					prefix={<SearchLg width={17} height={17} color="stroke-gray-500" />}
					onChange={e => setSearchValue(e.target.value)}
					placeholder={t(
						'Admin.data.menu.aiAssistant.aiAssistantPrograms.search',
					)}
					className="input-item"
					style={{ gridColumn: 'span 2 / span 2' }}
					autoComplete="false"
				/>
				<Input.Password
					prefix={<Lock01 width={17} height={17} color="stroke-gray-500" />}
					type="password"
					className="input-item"
					onChange={e => setDefaultPassword(e.target.value)}
					placeholder={t(
						'Admin.data.menu.userRoles.invitePatientModal.defaultPassword',
					)}
					style={{ gridColumn: 'span 1 / span 1' }}
					visibilityToggle={true}
					autoComplete="false"
				/>
				{userRole === USER_ROLES.SUPER_ADMIN && (
					<div
						onClick={() => setIsAddAdminModalOpen(true)}
						className="add-admin">
						<UserPlus01 width={20} height={20} color="stroke-gray-500" />
						<p style={{ fontWeight: 700, color: '#6B7280' }}>
							{t('Admin.data.menu.userRoles.invitePatientModal.addAdmin')}
						</p>
					</div>
				)}
			</div>
			<div>
				<TableList
					tableData={userPaginatedData}
					columns={TEAM_HEADER}
					actions={userRole === USER_ROLES.ADMIN ? actions.slice(1) : actions}
					activeRowId={activeRowId}
					setActiveRowId={setActiveRowId}
					isRegistered={true}
					currentPage={1}
					filterButton={filterButton}
					searchValue={searchValue}
					handleTogglePhysiotherapist={handleTogglePhysiotherapist}
					isBulkInvite
				/>
			</div>
			<Pagination
				current={currentPage}
				total={
					searchValue?.length > 0 ? userSearchData.length : userData.length
				}
				onChange={onPageChange}
				pageSize={10}
				style={{ textAlign: 'center' }}
				showSizeChanger={false}
			/>
			<div className="bulk-invite-actions">
				<Button
					size="large"
					style={{ width: '50%' }}
					onClick={() => {
						cancelUploaded(false);
					}}
					type="default">
					{t('Admin.data.menu.userRoles.invitePatientModal.cancel')}
				</Button>{' '}
				<Button
					size="large"
					style={{ width: '50%' }}
					htmlType="submit"
					onClick={() => validateSubmit()}
					type="primary">
					{t('Admin.data.menu.userRoles.invitePatientModal.save')}
				</Button>
			</div>
			{
				<Modal
					open={isEditPatientModalOpen}
					centered
					footer={null}
					destroyOnClose
					closable={true}
					maskClosable={false}
					width={800}
					style={{ marginTop: 10, marginBottom: 10 }}
					onCancel={() => {
						setIsEditPatientModalOpen(false);
					}}>
					<Default
						fullRowDetails={selectedUserData}
						rowData={selectedUserData?.profile}
						handleUserUpdate={handleUserUpdate}
						userFormData={selectedUserData?.profile}
						setUserFormData={userProfileInfo =>
							setSelectedUserData({
								...selectedUserData,
								profile: userProfileInfo,
							})
						}
						rowDataFeature={selectedUserData?.userFeatures}
						setIsEditPatientModalOpen={setIsEditPatientModalOpen}
						currentPage={currentPage}
						searchValue={searchValue}
						filterButton={filterButton}
						isBulkInvite
					/>
				</Modal>
			}
			{
				<Modal
					open={isAddAdminModalOpen}
					centered
					footer={null}
					destroyOnClose
					closable={true}
					maskClosable={false}
					width={600}
					style={{ marginTop: 10, marginBottom: 10 }}
					onCancel={() => {
						setIsAddAdminModalOpen(false);
					}}>
					<AddPatientToAdmin
						// activeRowId={activeRowId}
						selectedPhysiotherapists={selectedPhysiotherapists}
						addPhysiotherapistToUser={addPhysiotherapistToUser}
						setIsAddAdminModalOpen={setIsAddAdminModalOpen}
						setActiveRowId={setActiveRowId}
						currentPage={currentPage}
						isRegistered={true}
						filterButton={filterButton}
						searchName={searchValue}
						role={USER_ROLES.USER}
						isBulkInvite
					/>
				</Modal>
			}
		</div>
	);
};
