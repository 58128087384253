import { Image03 } from '@carespace-icons/files/image03';
import { Plus } from '@carespace-icons/general/plus';
import { Trash01 } from '@carespace-icons/general/trash01';
import { XClose } from '@carespace-icons/general/xClose';
import { Microphone01 } from '@carespace-icons/media/microphone01';
import { StopCircle } from '@carespace-icons/media/stopCircle';
import { VideoRecorder } from '@carespace-icons/media/videoRecorder';
import { message, Popover, Tooltip } from 'antd';
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';

interface PopoverModalProps {
  isRecording: boolean;
  showVideoModal: () => void;
  showImageModal: () => void;
  setRecordingState: (value: boolean) => void;
  toggleRecording: () => void;
  resetTranscript: () => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  tempValue: string;
  recordedState: boolean;
  setRecordedState: (value: boolean) => void;
  setTempValue: (value: string) => void;
}


const PopoverModal = (props: PopoverModalProps) => {
  const {
    isRecording,
    showVideoModal,
    showImageModal,
    setRecordingState,
    toggleRecording,
    resetTranscript,
    recordedState,
    setRecordedState,
    onChange,
    setTempValue,
    tempValue } = props
  const { t } = useTranslation()
  const [isPopup, setPopup] = useState(false);
  const microphoneRef = useRef(null)

  const handlePopupItemClick = () => {
    setPopup(false);
  };

  return (
    <div>
      {!isRecording && !recordedState && (
        <Popover
          placement='top'
          open={isPopup}
          onOpenChange={(visible) => setPopup(visible)}
          content={
            <>
              <Tooltip
                placement='topRight'
                color='purple'
                showArrow={false}
              >
                <div
                  className='popup-item'
                  onClick={() => {
                    showVideoModal();
                    handlePopupItemClick();
                  }}
                >
                  <VideoRecorder color='stroke-primary-400' />{' '}
                  <span className='custom-icon-text'>{t("Admin.data.addNotes.video")}</span>
                </div>
              </Tooltip>
              <Tooltip
                placement='topRight'
                color='purple'
                showArrow={false}
              >
                <div
                  className='popup-item'
                  onClick={() => {
                    showImageModal();
                    handlePopupItemClick();
                  }}
                >
                  <Image03 color='stroke-primary-400' />{' '}
                  <span className='custom-icon-text'>{t("Admin.data.addNotes.image")}</span>
                </div>
              </Tooltip>
              <Tooltip
                placement='topRight'
                color='purple'
                showArrow={false}
              >
                <div
                  className={`popup-item`}
                  onClick={() => {
                    resetTranscript();
                    setRecordingState(true);
                    toggleRecording();
                    handlePopupItemClick();
                  }}
                  ref={microphoneRef}
                >
                  <Microphone01 color='stroke-primary-400' />{' '}
                  <span className='custom-icon-text'>
                    {isRecording ? t("Admin.data.addNotes.stop") : t("Admin.data.addNotes.record")}
                  </span>
                </div>
              </Tooltip>
            </>
          }
          trigger='click'
          overlayInnerStyle={{
            backgroundColor: 'rgba(66, 48, 125, 1)',
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
        >
          <Tooltip
            placement='topRight'
            color='purple'
            showArrow={false}
          >
            <div
              className='custom-icon-class'
              onClick={() => {
                setPopup(!isPopup);
              }}
            >
              {!isPopup ? (
                <Plus color='stroke-gray-500' />
              ) : (
                <div className='custom-x-icon-class'>
                  <XClose color='stroke-gray-500' />
                </div>
              )}
            </div>
          </Tooltip>
        </Popover>)}
      {isRecording && !recordedState && (
        <Tooltip
          placement='topRight'
          title={t('Patient.data.activityStream.stopTooltip')}
          color='purple'
          showArrow={false}
        >
          <div
            className='custom-stop-icon-class '
            onClick={() => {
              toggleRecording();
              setRecordingState(false);
              setRecordedState(true);
            }}
          >
            <StopCircle color='stroke-orange-dark-500' />
          </div>
        </Tooltip>
      )}
      {recordedState && (
        <>
          <Tooltip
            placement='topRight'
            title={t('Patient.data.activityStream.discardTooltip')}
            color='purple'
            showArrow={false}
          >
            <div
              className='custom-ml-2 custom-stop-icon-class '
              onClick={() => {
                resetTranscript();
                message.success(t('Patient.data.activityStream.recDiscard'));
                setRecordedState(false);
                onChange({ target: { value: tempValue } } as React.ChangeEvent<HTMLTextAreaElement>);
                setTempValue('')
              }}
            >
              <Trash01 color='stroke-orange-dark-500' />
            </div>
          </Tooltip>
        </>
      )}
    </div>
  )
}

export default PopoverModal