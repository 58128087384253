import { ChevronLeft } from '@carespace-icons/arrows/chevronLeft';
import { ChevronRight } from '@carespace-icons/arrows/chevronRight';
import {
	selectExercise,
	selectProgram,
	updateProgram,
} from '@stores/aiAssistant/program';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import {
	Empty,
	Modal,
	Spin,
	Typography,
	Collapse,
	message,
	Button,
	Tag,
} from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	ICustomModalProps,
	IProgramData,
	IProgramExercise,
} from '@stores/interfaces';
import { PlayCircle } from '@carespace-icons/media/playCircle';
import { AddProgramPopup } from '@organisms/OCreateProgramModal/AddProgramPopup';
import { useNavigate } from 'react-router-dom';
import './style.css';
import ExerciseHeader from './ExerciseHeader';
import { setActiveTab } from '@stores/aiAssistant/aiAssistant';
import { useBlockNavigation } from '@atoms/ABlockNavigation';
import { router } from '@routers/routers';

const { Panel } = Collapse;

const CustomModalInfo = (props: ICustomModalProps) => {
	const { name, description, video } = props;
	const modalStyle = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	};

	const modalContent = (
		<div
			className="select-none"
			style={{ textAlign: 'center', marginTop: '10%' }}>
			<video
				controls
				className="video"
				preload="metadata"
				src={video}
				width="100%"
				height="100%"
			/>
			<div className="select-none" style={{ marginTop: '24px' }}>
				<Typography.Title level={5}>{name}</Typography.Title>
				<Typography.Text>{description}</Typography.Text>
			</div>
		</div>
	);
	Modal.info({
		title: null,
		content: modalContent,
		maskClosable: true,
		icon: null,
		okButtonProps: { style: { display: 'none' } },
		style: modalStyle,
		closable: true,
	});
};

interface ExerciseDataItemProps {
	index: number;
	refresh: boolean;
	program: IProgramData;
	programExercises: IProgramExercise[];
	isLoading: boolean;
	setLoading: (isLoading: boolean) => void;
	setRefresh: (refresh: boolean) => void;
	session: boolean;
	setSession: (value: boolean) => void;
}

export const ExerciseDataItem = ({
	setSession,
	session,
	refresh,
	programExercises,
	program,
	isLoading,
	setRefresh,
}: ExerciseDataItemProps) => {
	const { t } = useTranslation();
	const programs = useTypedSelector(state => state.aiAssistant.program.program);
	const [bulletPosition, setBulletPosition] = useState<number>();
	const [exercises, setExercises] = useState<IProgramExercise[]>(() => {
		const sortedExercise = [...program?.exercises];
		return sortedExercise?.sort((a, b) => a.order - b.order);
	});
	const navigate = useNavigate();
	const { clearNavigation } = useBlockNavigation();
	const dispatch = useTypedDispatch();
	const user = useTypedSelector(state => state.user);
	const [showNextPrevious, setShowNextPrevious] = useState(false);
	const [isModalVisible, setModalVisible] = useState(false);
	const { selectedUser } = useTypedSelector(state => state.contacts.main);
	const scrollRef = useRef(null);
	const [approved, setApproved] = useState(
		program?.status?.toLowerCase() == 'approved',
	);
	const [isOpen, setIsOpen] = useState(false);
	const [hoveredSession, setHoveredSession] = useState<string | null>(null);

	useEffect(() => {
		if (
			programs?.data?.length === 1 &&
			session &&
			programs?.data[0]?.status === 'approved'
		) {
			startProgramSession();
			setSession(false);
		}
	}, []);

	useEffect(() => {
		setApproved(program?.status?.toLowerCase() == 'approved');
		setExercises(() => {
			const sortedExercise = [...program?.exercises];
			return sortedExercise?.sort((a, b) => a.order - b.order);
		});
	}, [program, isModalVisible]);

	const onScroll = () => {
		setBulletPosition(
			Math.floor(
				(scrollRef.current?.scrollLeft * position()) /
				scrollRef.current?.scrollWidth,
			),
		);
	};

	const position = () =>
		Math.floor(scrollRef.current?.scrollWidth / 1300) < 10
			? Math.floor(scrollRef.current?.scrollWidth / 1300)
			: 10;
	const scrollWidth = () => scrollRef.current?.scrollWidth;

	useEffect(() => {
		setBulletPosition(1);
	}, [user, selectedUser, refresh]);

	const scrollLeft = () => {
		const currentPosition = scrollRef.current?.scrollLeft;
		const targetPosition = currentPosition - 400;
		scrollToPosition(targetPosition, 300);
	};

	const scrollRight = () => {
		const currentPosition = scrollRef.current?.scrollLeft;
		const targetPosition = currentPosition + 400;
		scrollToPosition(targetPosition, 300);
	};

	const scrollToPosition = (targetPosition: number, duration: number) => {
		const startTime = performance.now();
		const start = scrollRef.current?.scrollLeft;

		const scroll = timestamp => {
			const elapsed = timestamp - startTime;
			const progress = Math.min(elapsed / duration, 1);
			scrollRef.current.scrollLeft =
				start + (targetPosition - start) * progress;

			if (progress < 1) {
				requestAnimationFrame(scroll);
			}
		};

		requestAnimationFrame(scroll);
	};

	const handleApproveProgram = async (
		program: IProgramData,
		value: boolean,
	) => {
		const programData = {
			physioterapistId: user?.id,
			active: value,
			status: value ? 'approved' : 'draft',
		};
		await dispatch(
			updateProgram({
				programId: program?.id,
				programData: programData,
			}),
		).then(() =>
			value
				? message.success(
					t('Admin.data.menu.aiAssistant.aiAssistantPrograms.approved'),
				)
				: message.success(
					t('Admin.data.menu.aiAssistant.aiAssistantPrograms.disapproved'),
				),
		);
		setRefresh(!refresh);
	};

	const deleteProgram = async (program: IProgramData) => {
		const programData = {
			name: program?.name,
			active: false,
			duration: program?.duration,
			durationType: program?.durationType,
		};
		await dispatch(
			updateProgram({
				programId: program?.id,
				programData: programData,
			}),
		);
		setRefresh(!refresh);
	};

	const startProgramSession = () => {
		dispatch(selectProgram(program));
		dispatch(selectExercise(exercises));
		clearNavigation();
		navigate(
			`/${user.isPhysioterapist ? selectedUser.id : user.id}${router.AIASSISTANT_PROGRAM_START}`,
		);
		dispatch(setActiveTab(''));
	};

	const startSessionButton = {
		color: '#533F85',
		border: 'inherit',
		marginTop: '-6px',
	};

	return (
		<Collapse
			className="collapse-panel-container"
			bordered={false}
			activeKey={isOpen ? program.id : undefined}>
			<Panel
				className="header-panel !w-full bg-white !border !border-gray-200 !rounded-xl !mt-2"
				key={program.id}
				onClick={() => setIsOpen(!isOpen)}
				header={
					<div
						className="flex justify-between exercise-start-session-button"
						onMouseEnter={() => setHoveredSession(program.id)}
						onMouseLeave={() => setHoveredSession(null)}>
						<div className="flex gap-1 items-center">
							<p className="text-sm font-semibold text-gray-600">
								{program?.name}
							</p>
							{program?.originType === 'manual' ? (
								<Tag color="purple">
									{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.byPT')}
								</Tag>
							) : (
								<Tag color="blue">
									{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.byAI')}
								</Tag>
							)}
						</div>
						{user.isPhysioterapist ? (
							<ExerciseHeader
								approved={approved}
								setApproved={setApproved}
								program={program}
								startProgramSession={startProgramSession}
								setModalVisible={setModalVisible}
								hoveredSession={hoveredSession}
							/>
						) : (
							<div className="!h-6">
								{' '}
								<Button
									style={startSessionButton}
									className="start-session-css !bg-[#BDFF00]"
									icon={<PlayCircle height={12} width={12} color="#533F85" />}
									onClick={e => {
										e.stopPropagation();
										startProgramSession();
									}}>
									{t('Patient.data.myProgress.rehab.startSession')}
								</Button>
							</div>
						)}
					</div>
				}>
				{!isLoading ? (
					program ? (
						<div
							className="w-full p-[15px] bg-gray-50 rounded-lg overflow-hidden"
							onClick={e => e.stopPropagation()}>
							<div className="flex items-center justify-center mb-2">
								<span className="font-semibold mr-1">
									{t(
										'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.startDate',
									)}
								</span>
								<span className="mx-1">
									{program?.startAt ? moment(program?.startAt).local().format('LL') : "N/A"}
								</span>
								|
								<span className="ml-1 font-semibold mr-1">
									{t(
										'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.duration',
									)}
								</span>
								<span>{`${program?.duration} ${program?.durationType}`}</span>
							</div>
							{exercises?.length === 0 ? (
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={
										<span className="text-gray-300">
											{t(
												'Admin.data.menu.aiAssistant.aiAssistantPrograms.noProgram',
											)}
										</span>
									}
								/>
							) : (
								<div
									onMouseOver={() => setShowNextPrevious(true)}
									onMouseOut={() => setShowNextPrevious(false)}
									className="w-full relative">
									<div
										ref={scrollRef}
										className="bg-gray-100 overflow-x-auto flex gap-4 p-2"
										onScroll={onScroll}>
										{exercises?.map(item => {
											return (
												<div
													className="border-2 border-gray-200 rounded-lg hover:shadow-2xl cursor-pointer"
													style={{ minWidth: '220px', maxWidth: '250px' }}>
													<div
														onClick={() =>
															CustomModalInfo({
																video:
																	item?.video ||
																	item?.exerciseLibrary?.videoUrl,
																name:
																	item?.name || item?.exerciseLibrary?.title,
																description:
																	item?.description ||
																	item?.exerciseLibrary?.description,
															})
														}
														className="w-full h-auto rounded-lg relative image-wrapper">
														{item?.image ? (
															<img
																src={item?.image}
																alt=""
																className="w-full h-[200px] rounded-t-lg"
															/>
														) : (
															<video
																className="video"
																preload="metadata"
																src={item?.exerciseLibrary?.videoUrl}
																width="100%"
																height="100%"
															/>
														)}
														<div className="play-button">
															<PlayCircle width={50} height={50} />
														</div>
													</div>
													<div className="p-3 w-full">
														<p className="text-gray-500 font-semibold text-lg mb-2">
															{item?.name || item?.exerciseLibrary?.title}
														</p>
														<p className="text-gray-400 font-semibold text-xs mb-2">
															{t('Patient.data.rehab.weekly').toLowerCase()}{' '}
															{item?.weeklyReps}X{' '}
															{t('Patient.data.rehab.daily').toLowerCase()}{' '}
															{item?.dailyReps}X{' '}
															{t('Patient.data.rehab.sets').toLowerCase()}{' '}
															{item?.sets}X{' '}
															{t('Patient.data.rehab.reps').toLowerCase()}{' '}
															{item?.reps}X
														</p>
														<p className="text-gray-500">
															{item?.description ||
																item?.exerciseLibrary?.description}
														</p>
													</div>
												</div>
											);
										})}
									</div>
									{exercises?.length > 4 && showNextPrevious && (
										<div>
											<span
												className="p-3 absolute top-1/2 left-0 transform -translate-y-1/2 bg-primary-300 hover:bg-primary-600 rounded-r-full cursor-pointer"
												onClick={scrollLeft}>
												<ChevronLeft />
											</span>
											<span
												className="p-3 absolute top-1/2 right-0 transform -translate-y-1/2 bg-primary-300 hover:bg-primary-600 rounded-l-full cursor-pointer"
												onClick={scrollRight}>
												<ChevronRight />
											</span>
										</div>
									)}
								</div>
							)}
						</div>
					) : (
						<Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={
								<span className="text-gray-300">
									{t(
										'Admin.data.menu.aiAssistant.aiAssistantPrograms.noProgram',
									)}
								</span>
							}
						/>
					)
				) : (
					<div className="flex justify-center items-center h-40">
						<Spin tip="Loading" size="large" />
					</div>
				)}
			</Panel>
			<AddProgramPopup
				isEdit
				onOk={() => {
					setModalVisible(false);
					setExercises([]);
					setRefresh(!refresh);
				}}
				deleteProgram={deleteProgram}
				refresh={refresh}
				setRefresh={setRefresh}
				isVisible={isModalVisible}
				onCancel={() => setModalVisible(false)}
				selectedExercises={exercises}
				setSelectedExercises={setExercises}
				program={program}
			/>
		</Collapse>
	);
};
