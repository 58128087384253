export const templateGroup = [
	{
		"id": 5,
		"name": "Upper Body",
		"key": "upperBody",
		"frontend": {
			"styles": "{ right: '60px', top: '10px' }"
		}
	},
	{
		"id": 7,
		"name": "Upper Right",
		"key": "upperRight",
		"frontend": {
			"styles": "{ left: '-400px', top: '140px' }"
		}
	},
	{
		"id": 6,
		"name": "Upper Left",
		"key": "upperLeft",
		"frontend": {
			"styles": "{ right: '-160px', top: '140px' }"
		}
	},
	{
		"id": 2,
		"name": "Lower Body",
		"key": "lowerBody",
		"frontend": {
			"styles": "{ right: '50px', bottom: '-110px' }"
		}
	},
	{
		"id": 4,
		"name": "Lower Right",
		"key": "lowerRight",
		"frontend": {
			"styles": "{ left: '-400px', bottom: '40px' }"
		}
	},
	{
		"id": 3,
		"name": "Lower Left",
		"key": "lowerLeft",
		"frontend": {
			"styles": "{ right: '-160px', bottom: '40px' }"
		}
	},
	{
		"id": 1,
		"name": "Full Scan",
		"key": "full",
		"frontend": {
			"styles": "{ right: '-270px', bottom: '200px' }"
		}
	},
	{
		"id": 9,
		"name": "Baseline Scan",
		"key": "full",
		"frontend": {
			"styles": "{ left: '-540px', bottom: '200px' }"
		}
	}
]