import { Check } from "@carespace-icons/general/check"
import { IRehabExerciseListSession, IReport } from "@stores/interfaces"
import { Col, Empty, Image, message, Row } from "antd"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { FaceHappy } from '@carespace-icons/users/faceHappy'
import { FaceWink } from '@carespace-icons/users/faceWink'
import { FaceSmile } from '@carespace-icons/users/faceSmile'
import { FaceNeutral } from '@carespace-icons/users/faceNeutral'
import { FaceSad } from '@carespace-icons/users/faceSad'
import { FaceFrown } from '@carespace-icons/users/faceFrown'
import AddNotes from "./AddNotes"
import { useTypedDispatch, useTypedSelector } from "@stores/index"
import { updateReportNotes } from "@stores/report/reports"
import { useState } from "react"
import { MdOutlineRemoveRedEye } from "react-icons/md"
import ReportNotesDownloadTemplate from "./ReportNotes/ReportNotesDownloadTemplate"
import ReportNotesTemplate from "./ReportNotes/ReportNotesTemplate"

interface ReportRehabCaptureDataProps {
  isEditMode: boolean
  isDownload?: boolean
}

export default function ReportRehabCaptureData(props: ReportRehabCaptureDataProps) {
  const { isEditMode, isDownload } = props
  const { t } = useTranslation();
  const report = useTypedSelector(state => state.reports.report)
  const [programSessionsNotes, setProgramSessionsNotes] = useState<string>()
  const dispatch = useTypedDispatch()

  const handleSubmit = async () => {
    const reportId = report?.id || '';
    const payload = {
      programSessionsNotes: {
        notes: programSessionsNotes
      }
    }
    await dispatch(updateReportNotes({ payload, reportId }))
    setProgramSessionsNotes('')
    message.success(t('Admin.data.addToReports.saveText'))
  }

  const sendMessage = async (reportId: string, messageDescription: string, imgFile: File[], videoBlob: Blob) => {
    if (
      (messageDescription.trim() !== '' && (videoBlob || imgFile))
    ) {
      const formData = new FormData();
      formData.append('programSessionsNotes[notes]', messageDescription);
      imgFile[0] && formData.append('images', imgFile![0]);
      videoBlob && formData.append(`videos`, videoBlob!)
      await dispatch(updateReportNotes({
        payload: formData,
        reportId: reportId || ''
      }))
    } else {
      message.error(t("Admin.data.addNotes.requiredErr"))
    }
  }

  const getStatusComponent = (dataItems: { overallCondition: string }) => {
    switch (dataItems?.overallCondition) {
      case "improving":
        return <span className='bg-green-500 text-white px-3 rounded-full'>{t('Admin.data.menu.aiAssistant.aiAssistantListSessions.improving')}</span>
      case "worsening":
        return <span className='bg-red-400 text-white px-3 rounded-full'>{t('Admin.data.menu.aiAssistant.aiAssistantListSessions.worsening')}</span>
      case "noChange":
        return <span className='bg-yellow-400 text-white px-3 rounded-full'>{t('Admin.data.menu.aiAssistant.aiAssistantListSessions.noChange')}</span>
      default:
        return null;
    }
  }

  const getExerciseDifficultyLevel = (dataItems: { exerciseDifficultyLevel: string }) => {
    switch (dataItems?.exerciseDifficultyLevel) {
      case "1":
        return <span className='bg-green-200 text-white px-3 rounded-full'>{t("Admin.data.addNotes.tooEasy")}</span>;
      case "2":
        return <span className='bg-green-500 text-white px-3 rounded-full'>{t("Admin.data.addNotes.easy")}</span>;
      case "3":
        return <span className='bg-yellow-500 text-white px-3 rounded-full'>{t("Admin.data.addNotes.normal")}</span>;
      case "4":
        return <span className='bg-yellow-700 text-white px-3 rounded-full'>{t("Admin.data.addNotes.hard")}</span>;
      case "5":
        return <span className='bg-red-500 text-white px-3 rounded-full'>{t("Admin.data.addNotes.tooHard")}</span>;
      default:
        return null;
    }
  }

  const painLevelData = {
    0: { color: 'text-cyan-500', icon: <FaceHappy color={'stroke-cyan-500'} width={20} height={20} /> },
    1: { color: 'text-green-700', icon: <FaceWink color={'stroke-green-700'} width={20} height={20} /> },
    2: { color: 'text-green-500', icon: <FaceSmile color={'stroke-green-500'} width={20} height={20} /> },
    3: { color: 'text-green-300', icon: <FaceSmile color={'stroke-green-300'} width={20} height={20} /> },
    4: { color: 'text-yellow-300', icon: <FaceNeutral color={'stroke-yellow-300'} width={20} height={20} /> },
    5: { color: 'text-yellow-400', icon: <FaceNeutral color={'stroke-yellow-400'} width={20} height={20} /> },
    6: { color: 'text-orange-300', icon: <FaceSad color={'stroke-orange-300'} width={20} height={20} /> },
    7: { color: 'text-orange-400', icon: <FaceSad color={'stroke-orange-400'} width={20} height={20} /> },
    8: { color: 'text-rose-500', icon: <FaceFrown color={'stroke-rose-500'} width={20} height={20} /> },
    9: { color: 'text-rose-600', icon: <FaceFrown color={'stroke-rose-600'} width={20} height={20} /> },
    10: { color: 'text-rose-700', icon: <FaceFrown color={'stroke-rose-700'} width={20} height={20} /> },
  };

  const getPainLevel = (dataItems: { painLevel: string }) => {
    const painLevelDataItem = painLevelData[dataItems?.painLevel];
    if (painLevelDataItem) {
      return (
        <div className={`!flex gap-2 ${painLevelDataItem.color}`}>
          <span className='text-sm font-semibold'>{dataItems?.painLevel}</span>
          {painLevelDataItem.icon}
        </div>
      );
    }
    return null;
  };

  return (
    <div className='pb-2'>
      {!isDownload ? <>
        <p className="mt-5 font-bold text-base text-gray-600 text-center">{t('Admin.data.addToReports.letsMoveHistoricalData')}</p>
      {report?.programSessionsIds?.map((item, index: number) => {
        return (
          <div key={index}>
            <p className='text-gray-600'><b className='font-bold'>{item?.program?.name}</b></p>
            <div className='flex items-center justify-between text-gray-600 mb-2'>
              <p className='text-gray-600'><b className='font-semibold'>{t('Admin.data.addToReports.sessionDate')}</b> {moment(item.createdAt).local().format('LLL')}</p>
              <div className='flex items-center' onClick={(e) => e.stopPropagation()}>
                <section className='flex'>
                  <p className='font-normal mr-2'>{getStatusComponent(item)}</p>
                  <p className='font-normal'>{getPainLevel(item)}</p>
                </section>
              </div>
            </div>

            <div className='p-[15px] my-2.5 bg-gray-100 mt-1 rounded-lg border-1 border-inherit flex flex-col gap-2 text-sm text-gray-500'>
              {item?.programSessionResult.length > 0 ? (
                <Row gutter={[16, 16]}>
                  {item?.programSessionResult?.map((exercItem, index: number) => (
                    <Col key={index} xs={24} sm={12} lg={6}>
                      <div className='relative rounded-md'>
                        <video
                          controls
                          className="video rounded-md"
                          preload="metadata"
                          src={exercItem?.video}
                          width="100%"
                          height="100%"
                        />
                        <div className="bg-gray-700 text-white p-2 -mt-1 relative z-10 rounded-b-md flex justify-between">
                          <span>{exercItem?.programExercise?.strapiExercise ? exercItem?.programExercise?.strapiExercise?.attributes?.name : exercItem?.programExercise?.exerciseLibrary?.title}</span>
                          <span></span>
                        </div>
                        <div className="absolute top-2 right-2 text-white p-1 rounded-md z-20">
                          <span>{getExerciseDifficultyLevel(exercItem)}</span>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>{t('Admin.data.addToReports.noData')}</span>}
                />
              )}
            </div>
          </div>
        );
      })}</>:<>
      <p className="mt-5 font-bold text-base text-gray-600 text-center">{t('Admin.data.addToReports.letsMoveHistoricalData')}</p>
      {report?.programSessionsIds?.map((item, index: number) => {
                return (
                  <div key={index}>
                    <p className='py-[15px] text-gray-600'><b className='font-bold'>{t('Admin.data.addToReports.sessionDate')}</b> {moment(item.createdAt).local().format('LLL')}</p>
                    <div className='p-[15px] my-2.5 bg-gray-100 mt-1 rounded-lg border-1 border-inherit flex flex-col gap-2 text-sm text-gray-500'>
                      <p className='font-normal'><b className='font-bold'>{t('Admin.data.addToReports.overallCondition')} </b>{item.overallCondition}</p>
                      <p className='font-normal'><b className='font-bold'>{t('Admin.data.addToReports.painLevel')} </b>{item.painLevel}</p>
                      {
                        item?.programSessionResult
                          ? item?.programSessionResult?.map(
                            (exercItem, index: number) => {
                              return (
                                <div key={index}>
                                  <p className='font-normal'><b className='font-bold'>{t('Admin.data.addToReports.video')} </b><a href={exercItem.video} target='_blank'>{exercItem.video}</a></p>
                                </div>
                              )
                            }
                          )
                          : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span >{t('Admin.data.addToReports.noData')}</span>} />
                      }
                    </div>
                  </div>
                )
              })}
      </>}
      {
        report?.programSessionsNotes?.some(
          (note) =>
            note.notes?.trim()
        ) && (<>
          {report?.programSessionsNotes?.length! > 0 ? (
                <>
                  <div className="text-left mt-3 mb-3">
                    <span className="font-semibold text-base text-gray-900">{t("Admin.data.addNotes.notes")}</span>
                  </div>
                  {!isDownload ? <>
                  {report?.programSessionsNotes?.map((note, index) => {
                    return <ReportNotesTemplate
                    index={index}
                    isEditMode={isEditMode}
                    note={note}
                  />
                  })}</>:<>
                  {report?.programSessionsNotes?.map((note, index) => {
                    return <ReportNotesDownloadTemplate
                    index={index}
                    isEditMode={isEditMode}
                    note={note}
                  />})}
                  </>}
                </>) : null}</>)}
      {report?.programSessionsIds?.length != 0 && !isDownload ? (
        <AddNotes sendMessage={sendMessage} onClick={() => handleSubmit()} onChange={(event) => setProgramSessionsNotes(event.target.value)} />
      ) : null}
    </div>
  )
}
