import { ChevronRight } from '@carespace-icons/arrows/chevronRight';
import './arrow.css';

type TRightArrow = {
  onNextWeek: () => void
}

const RightArrow = (props: TRightArrow) => {

  const {onNextWeek} = props

  return (
    <p className="custom-weekly-arrow" onClick={onNextWeek}>
      <ChevronRight color='stroke-primary-800'/>
    </p>
  );
};

export default RightArrow;
