import { showCustomModal } from '@atoms/ACustomModalInfo';
import Button from '@atoms/Button';
import { Plus } from '@carespace-icons/general/plus';
import { SearchLg } from '@carespace-icons/general/searchLg';
import { List } from '@carespace-icons/layoult/list';
import { PlayCircle } from '@carespace-icons/media/playCircle';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { ExerciseProps, IProgramFilterOptions } from '@stores/interfaces';
import { myLibraryInfoAction } from '@stores/library';
import { getMyLibraryData } from '@stores/myLibrary/myLibrary';
import { setOmniromRecord, setOmniromUpload } from '@stores/rehab/main';
import { Col, Empty, Input, Pagination, Row, Spin, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CiGrid41 } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import { ExerciseFilters } from './ExerciseFilters';
import { getMySystemLibraryData, setPopoverState } from '@stores/functionalGoals';
import { router } from '@routers/routers';
import { setProgramModalSubKey } from '@stores/aiAssistant/aiAssistant';

export const MyExrciseModalData = (props: ExerciseProps) => {
	const { selectedExercises, setSelectedExercises, open, setOpen } = props;
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);
	const [libraryData, setLibraryData] = useState([]);
	const id = useTypedSelector(state => state.user.id);
	const [currentPage, setCurrentPage] = useState(1);
	const [perpage, setPerPage] = useState(10);
	const [isGrid, setGrid] = useState(false);
	const [filterExercise, setFilterExercise] = useState("System Exercises")
	const [filterOptions, setFilterOptions] = useState<IProgramFilterOptions>({
		bodyRegionIds: [],
		exerciseCategoriesIds: [],
		jointsIds: [],
		functionalGoalsIds: [],
	});
	const dispatch = useTypedDispatch();
	const navigate = useNavigate();
	const selectedSideName = useTypedSelector((state) => state.functionalGoals.selectedSideName);
	const programModalSubKey = useTypedSelector((state) => state.aiAssistant.aiAssistant.programModalSubKey)

	const popoverState = useTypedSelector((state) => state.functionalGoals.popoverState)
	const onPageChange = (pageNumber: number, pageSize: number) => {
		setLibraryData([]);
		setCurrentPage(pageNumber);
		setPerPage(pageSize);
		handleApply(pageNumber);
	};

	const handleApply = async (pageNumber: number) => {
		setIsLoading(true);
		dispatch(setPopoverState(false))
		const payload = {
			id: id,
			limit: 10,
			page: pageNumber ? pageNumber : 1,
			title: searchValue ? searchValue : selectedSideName,
			filterOptions: filterOptions
		};
		if (programModalSubKey === "2") {
			const data = await dispatch(getMyLibraryData(payload));
			dispatch(myLibraryInfoAction.myLibraryInfo(data));
			setLibraryData(data.payload);
		} else {
			const data = await dispatch(getMySystemLibraryData(payload))
			setLibraryData(data.payload);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		setSearchValue("")
		handleApply(1)
	}, [programModalSubKey]);


	const handleDivClick = (item: {
		attributes: {
			name: string,
			description: string
		};
		id: string; title: string; videoUrl: string
	}) => {
		if (programModalSubKey === "2") {
			if (
				selectedExercises?.find(
					(exercise) =>
						exercise?.exerciseLibraryId === item?.id,
				)
			) {
				setSelectedExercises(
					selectedExercises?.filter(
						(exercise: { exerciseLibraryId: string | number }) =>
							exercise?.exerciseLibraryId !== item?.id,
					),
				);
			} else {
				const exercise = {
					id: item?.id,
					exerciseLibraryId: item?.id,
					name: item?.title,
					weeklyReps: 7,
					dailyReps: 1,
					sets: 1,
					reps: 10,
					video: item?.videoUrl,
				};
				setSelectedExercises([...selectedExercises, exercise]);
			}
		} else {
			{
				if (
					selectedExercises?.find(
						(exercise) => exercise?.strapiExerciseId === item?.id,
					)
				) {
					setSelectedExercises(
						selectedExercises?.filter(
							(exercise) => exercise?.strapiExerciseId !== item?.id,
						),
					);
				} else {
					const exercise = {
						id: item?.id,
						strapiExerciseId: item?.id,
						name: item?.attributes?.name,
						weeklyReps: 7,
						dailyReps: 1,
						sets: 1,
						reps: 10,
						description: item?.attributes?.description,
						image: item?.attributes?.exercise_image?.data[0]?.attributes?.url,
						video: item?.attributes?.exercise_video?.data[0]?.attributes?.url,
					};
					setSelectedExercises([...selectedExercises, exercise]);
				}
			};
		}
	};
	const addExercise = () => {
		dispatch(setOmniromRecord(false));
		dispatch(setOmniromUpload(false));
		navigate(router.PROGRAMADDEXERCISES);
	};

	const handleKeyDown = (e: { key: string }) => {
		if (e.key === 'Enter') {
			handleApply(1)
		}
	};

	function renderData() {
		return (
			<div className={`${popoverState ? "min-h-[650px]" : ""}`}>
				<div className="flex justify-between items-center gap-4 pl-4 pr-4">
					{programModalSubKey != "2" && <ExerciseFilters
						open={open}
						setOpen={setOpen}
						filterOptions={filterOptions}
						setFilterOptions={setFilterOptions}
						handleApply={handleApply}
						filterExercise={filterExercise}
						setFilterExercise={setFilterExercise}
						activeKey={"1"}
					/>}
					<Input
						className="w-[82%] my-3 h-10"
						placeholder={t(
							'Admin.data.menu.aiAssistant.aiAssistantPrograms.search',
						)}
						value={searchValue}
						onKeyDown={handleKeyDown}
						onChange={e => setSearchValue(e.target.value)}
						suffix={<SearchLg height={15} width={15} color="stroke-black" />}
					/>
					{programModalSubKey != "1" &&
						<Button onClick={addExercise} size="sm">
							<span
								className="whitespace-nowrap"
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}>
								<Plus />
								{t(
									'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.recordExercise',
								)}
							</span>
						</Button>}
					<div className="flex gap-2 items-center justify-end">
						{selectedExercises.length != 0 && (
							<p className="rounded-full py-0.5 px-2 bg-success-500 text-white">
								{selectedExercises.length}
							</p>
						)}
						<div className="flex justify-end">
							<span
								onClick={() => setGrid(false)}
								className="w-9 h-9 text-center p-1 cursor-pointer rounded-l-lg border border-gray-300">
								<List
									color={isGrid ? 'stroke-gray-300' : 'stroke-gray-500'}
									height={24}
									width={24}
								/>
							</span>
							<span
								onClick={() => setGrid(true)}
								className="w-9 h-9 text-center p-1 cursor-pointer rounded-r-lg border border-l-0 border-gray-300">
								<CiGrid41
									className={`w-6 h-6 ${isGrid ? 'text-gray-500' : 'text-gray-300'}`}
								/>
							</span>
						</div>
					</div>
				</div>
				<div style={{ maxHeight: "42vh", overflowX: "hidden", overflowY: "auto" }}>
					{isLoading ? (
						<div className="flex justify-center items-center h-40">
							<Spin tip="Loading" size="large" />
						</div>
					) : libraryData && libraryData.data && libraryData.data.length > 0 ? (
						!isGrid ? (
							libraryData?.data?.map((item) => {
								const isSelected = selectedExercises?.find(
									(exercise) =>
										exercise?.exerciseLibraryId === item?.id || exercise?.strapiExerciseId === item?.id,
								);
								return (
									<div className="mb-4">
										<div
											className={`flex gap-4 p-4 !rounded-lg border-4 w-full cursor-pointer ${isSelected ? 'border-green-500' : 'hover:border-primary-500'
												}`}
											onClick={() => handleDivClick(item)}
											key={item.id}>
											<div
												onClick={e => {
													e.stopPropagation();
													showCustomModal({
														video: item?.videoUrl || item?.attributes?.exercise_video?.data[0]?.attributes
															?.url,
														name: item?.title || item?.attributes?.name,
														description: item?.description || item?.attributes?.description,
													});
												}}
												className={`custom-image-container`}>
												<div className="w-40 flex-shrink-0 image-wrapper">
													{item?.videoUrl ? <video
														src={item?.videoUrl}
														controls={false}
														className="rounded-lg w-full h-full"
													/> : <img
														src={
															item?.attributes?.exercise_image?.data[0]?.attributes
																?.url
														}
														className="rounded-lg w-[150px] h-full"
													/>}
													<div className="play-button">
														<PlayCircle width={50} height={50} />
													</div>
												</div>
											</div>
											<div className="flex-1 rounded-lg p-2">
												<p className="text-gray-500 font-semibold text-lg">
													{item?.title || item?.attributes?.name}
												</p>
												<p className="text-gray-400 font-semibold text-xs">
													{t(
														'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.weekly',
													)}{' '}
													{item?.weeklyReps || 7}X{' '}
													{t(
														'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.daily',
													)}{' '}
													{item?.dailyReps || 1}X{' '}
													{t(
														'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.sets',
													)}{' '}
													{item?.sets || 1}X{' '}
													{t(
														'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.reps',
													)}{' '}
													{item?.reps || 10}X
												</p>
												<p className="text-gray-500 mt-2">{item?.description || item?.attributes?.description}</p>
											</div>
										</div>
									</div>
								);
							})
						) : (
							<Row gutter={[16, 16]}>
								{libraryData?.data?.map(item => {
									const isSelected = selectedExercises?.find(
										(exercise) =>
											exercise?.exerciseLibraryId === item?.id || exercise?.strapiExerciseId === item?.id,
									);
									return (
										<Col xs={12} sm={12} md={12} lg={12} xl={12} key={item.id}>
											<div
												className={`flex gap-4 p-4 !rounded-lg border-2 hover:border-4 w-full cursor-pointer ${isSelected ? 'border-4 border-green-500' : 'hover:border-primary-500'}`}
												onClick={() => handleDivClick(item)}>
												<div
													onClick={e => {
														e.stopPropagation();
														showCustomModal({
															video: item?.videoUrl || item?.attributes?.exercise_video?.data[0]?.attributes
																?.url,
															name: item?.title || item?.attributes?.name,
															description: item?.description || item?.attributes?.description,
														});
													}}
													className={`!w-14 rounded-lg relative flex h-auto items-center justify-center`}>
													<div className="w-14 flex-shrink-0 image-wrapper">
														{item?.videoUrl ? <video
															src={item?.videoUrl}
															controls={false}
															className="rounded-lg w-full h-full"
														/> : <img
															src={
																item?.attributes?.exercise_image?.data[0]?.attributes
																	?.url
															}
															className="rounded-lg w-[150px] h-full"
														/>}
														<div className="play-button">
															<PlayCircle width={30} height={30} />
														</div>
													</div>
												</div>
												<div className="flex-1 rounded-lg p-2">
													<p className="text-gray-500 font-semibold text-lg">
														{item?.title || item?.attributes?.name}
													</p>
													<span className="text-gray-400 font-semibold text-sm flex justify-start gap-3">
														<p>
															{t(
																'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.weekly',
															)}{' '}
															{item?.weeklyReps || 7}X
														</p>
														<p>
															{t(
																'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.daily',
															)}{' '}
															{item?.dailyReps || 1}X
														</p>
														<p>
															{t(
																'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.sets',
															)}{' '}
															{item?.sets || 1}X
														</p>
														<p>
															{t(
																'Admin.data.menu.aiAssistant.aiAssistantCreateProgramModal.reps',
															)}{' '}
															{item?.reps || 10}X
														</p>
													</span>
												</div>
											</div>
										</Col>
									);
								})}
							</Row>
						)
					) : (
						<Empty
							description="No Exercises Found"
							image={Empty.PRESENTED_IMAGE_SIMPLE}
						/>
					)}
				</div>
				{!isLoading &&
					libraryData &&
					libraryData.data &&
					libraryData.data.length > 0 && (
						<Pagination
							className="createProgramModalPagination flex justify-center"
							current={libraryData?.pagination?.currentPage || libraryData?.meta?.pagination?.page}
							onChange={onPageChange}
							total={libraryData?.pagination?.totalCount || libraryData?.meta?.pagination?.total}
							showSizeChanger={false}
							pageSize={perpage}
						/>
					)}
			</div>
		)
	}

	return (
		<Tabs
			type="card"
			activeKey={programModalSubKey}
			className="mainTabs select-none p-4 bg-gray-100"
			tabBarStyle={{ margin: 0 }}
			onChange={(activeKey: string) => {
				dispatch(setProgramModalSubKey(activeKey))
			}}
			items={[
				{
					label: t('Admin.data.menu.aiAssistant.aiAssistantPrograms.carespaceLibrary'),
					key: '1',
					children: (
						<>
							{renderData()}
						</>
					),
				},
				{
					label: t('Admin.data.menu.aiAssistant.aiAssistantPrograms.myLibrary'),
					key: '2',
					children: (
						<>
							{renderData()}
						</>
					),
				},
			]}
		/>
	);
};
