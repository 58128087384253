import { useEffect, useState } from "react"
import { useTypedDispatch, useTypedSelector } from "@stores/index"
import { Button, Input, message, Modal, Pagination, Spin, Tag } from "antd"
import { useTranslation } from "react-i18next"
import { AdminDashboardPatient, IInviteUserProps, IUserPaginationDefaultResponse } from "@stores/interfaces"
import { SearchLg } from "@carespace-icons/general/searchLg"
import { QrCode01 } from "@carespace-icons/development/qrCode01"
import { Link01 } from "@carespace-icons/general/link01"
import { Edit01 } from "@carespace-icons/general/edit01"
import { Trash01 } from "@carespace-icons/general/trash01"
import InvitePatientsModal from "@pages/InvitePatientsModal"
import { deletePendingInvitePatients, getPendingInvitesList, postInviteUserResend, setPendingCount } from "@stores/adminPatient"
import AdminQRCodeModal from "@pages/AdminQRCodeModal"
import { FilterLines } from "@carespace-icons/general/filterLines"
import Table from "./Table"
import { Send01 } from "@carespace-icons/communication/send01"
import { USER_ROLES } from "@stores/constants"

const PendingInvites = () => {
  const { t } = useTranslation();
  const [isInvitePatientModalOpen, setIsInvitePatientModalOpen] = useState(false);
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const user = useTypedSelector((state) => state.user)
  const isSuperAdmin = user?.profile?.role === USER_ROLES.SUPER_ADMIN;
  const pendingInvites = useTypedSelector((state) => state.adminDashboardPatient.pendingInvites)
  const [patientData, setPatientData] = useState<AdminDashboardPatient[]>([]);
  const [pagination, setPagination] = useState<IUserPaginationDefaultResponse>(pendingInvites?.pagination || {})
  const dispatch = useTypedDispatch()
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedButton, setSelectedButton] = useState('all')
  const [userData, setUserData] = useState<IInviteUserProps>()
  const [filterButton, setFilterButton] = useState('');
  const [currentPage, setCurrentPage] = useState(pagination?.currentPage || 1)

  const options = [
    { label: t('Admin.data.menu.userRoles.filter'), value: 'filter', icon: <FilterLines color="stroke-gray-800" width={20} height={18} /> },
    { label: t('Admin.data.menu.userRoles.all'), value: 'all' },
    { label: t('Admin.data.menu.userRoles.superAdmin'), value: USER_ROLES.SUPER_ADMIN },
    { label: t('Admin.data.menu.userRoles.admin'), value: USER_ROLES.ADMIN },
    { label: t('Admin.data.menu.userRoles.patient'), value: USER_ROLES.USER },
  ];

  const TEAM_MEMBER_HEADER = [
    { name: t('Admin.data.menu.userRoles.name'), helperText: 'I am helper', sort: true, key: "name" },
  ]

  const TEAM_MEMBER_SUPERaDMIN_HEADER = [
    { name: t('Admin.data.menu.userRoles.name'), sort: true, key: "name" },
    { name: t('Admin.data.menu.userRoles.role'), sort: true, key: "role" },
  ]

  const handleDeleteClick = (obj: IInviteUserProps) => {
    Modal.confirm({
      title: t('Admin.data.menu.userRoles.pendingInvites.deleteConfirm'),
      okText: t('Admin.data.menu.userRoles.pendingInvites.ok'),
      cancelText: t('Admin.data.menu.userRoles.pendingInvites.cancel'),
      className: "select-none",
      onOk: () => {
        dispatch(deletePendingInvitePatients(obj?.id)).then(async () => {
          message.success(t('Admin.data.menu.userRoles.pendingInvites.deleteMessage'));
          const data = await fetchData(currentPage, filterButton);
          dispatch(setPendingCount(data?.pagination?.totalCount));
        })
      },
      onCancel: () => {
        console.log("Cancel clicked");
      },
    });
  };

  const handleCopyLinkCode = (rowData: IInviteUserProps) => {
    if (rowData?.inviteUrl) {
      navigator.clipboard.writeText(rowData?.inviteUrl)
        .then(() => {
          message.success(t('Admin.data.menu.userRoles.pendingInvites.copyMessage'));
        })
        .catch(() => {
          message.error(t('Admin.data.menu.userRoles.pendingInvites.coypError'));
        });
    } else {
      message.error(t('Admin.data.menu.userRoles.pendingInvites.noCopyFound'));
    }
  }

  const handleReSendClick = (rowData: IInviteUserProps) => {
    console.log('Add clicked', rowData?.id)
    dispatch(postInviteUserResend(rowData?.id));
    message.success('Resend Successfully!!')
  }

  const actions = [
    {
      icon: <QrCode01 color="stroke-gray-600" width={15} height={16} />, action: (rowData: IInviteUserProps) => {
        setQrCodeUrl(rowData?.inviteUrl);
        setQrCodeModalOpen(true);
      }, label: t('Admin.data.menu.userRoles.qrCode')
    },
    { icon: <Link01 color="stroke-gray-600" width={15} height={16} />, action: (rowData: IInviteUserProps) => handleCopyLinkCode(rowData), label: t('Admin.data.menu.userRoles.copyUrl') },
    { icon: <Send01 color="stroke-gray-600" width={15} height={16} />, action: (rowData: IInviteUserProps) => handleReSendClick(rowData), label: t('Admin.data.menu.userRoles.resendEmail') },
    { icon: <Edit01 color="stroke-gray-600" width={15} height={16} />, action: (rowData: IInviteUserProps) => { setIsInvitePatientModalOpen(true); setUserData(rowData) }, label: t('Admin.data.menu.userRoles.edit') },
    { icon: <Trash01 color="stroke-gray-600" width={15} height={16} />, action: (rowData: IInviteUserProps) => handleDeleteClick(rowData), label: t('Admin.data.menu.userRoles.delete') },
  ];

  const onPageChange = async (pageNumber: number) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, filterButton)
  }

  const fetchData = async (pageNumber: number, role?: string) => {
    const payload = {
      ...pagination,
      nextPage: pageNumber,
      name: searchValue,
      role: role || '',
    }
    const response = await dispatch(getPendingInvitesList(payload));
    return response.payload;
  }

  useEffect(() => {
    if (pendingInvites?.data) {
      setPatientData(pendingInvites?.data);
      setPagination(pendingInvites?.pagination || {});
    }
  }, [pendingInvites])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      fetchData(1, filterButton)
      setCurrentPage(1)
    }
  };

  return (
    <div className="total-patient-main-div">
      <>
        <div className="border-b-2 border-inherit pb-3 mb-2">
          <h3>{t('Admin.data.menu.userRoles.pendingInvites.label')}<span className='total-patient-length'><Tag color="purple">{pagination.totalCount} {t('Admin.data.newPatients.users')}</Tag></span></h3>
          <span>{t('Admin.data.menu.userRoles.pendingInvites.description')}</span>
        </div>
        <div className="flex justify-between items-center my-3">
          <Input
            className='w-[400px] my-3 h-10'
            placeholder={t('Admin.data.menu.aiAssistant.aiAssistantPrograms.search')}
            value={searchValue}
            onKeyDown={(e) => handleKeyDown(e)}
            onChange={(e) => setSearchValue(e.target.value)}
            prefix={<SearchLg height={15} width={15} color='stroke-black' />}
          />
          {isSuperAdmin && (
            <Button.Group>
              {options.map((item) => {
                const isSelected = selectedButton === item.value;
                const isSuperAdminRoleButton = [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.USER].includes(item.value);
                return (
                  <Button
                    key={item.label}
                    icon={item.icon}
                    style={{
                      borderColor: 'inherit',
                      borderRadius: '0px',
                      backgroundColor: isSelected ? '#D0D5DD' : 'white',
                      color: '#1D2939',
                    }}
                    onClick={() => {
                      if (item.value !== 'filter') {
                        setSelectedButton(item.value);
                        if (isSuperAdminRoleButton) {
                          setCurrentPage(1)
                          setFilterButton(item.value);
                          fetchData(currentPage, item.value);
                        } else {
                          setCurrentPage(1)
                          setFilterButton('');
                          fetchData(currentPage);
                        }
                      }
                    }}
                  >
                    {item.label}
                  </Button>
                )
              })}
            </Button.Group>)}
        </div>
        <Table tableData={patientData} columns={isSuperAdmin ? TEAM_MEMBER_SUPERaDMIN_HEADER : TEAM_MEMBER_HEADER} actions={actions} currentPage={currentPage} filterButton={filterButton} searchValue={searchValue} />
        {isInvitePatientModalOpen && <InvitePatientsModal isInvitePatientModalOpen={isInvitePatientModalOpen} setIsInvitePatientModalOpen={setIsInvitePatientModalOpen} closable={true} rowData={userData} currentPage={currentPage} searchValue={searchValue} filterButton={filterButton} />}
        {qrCodeModalOpen && <AdminQRCodeModal qrCodeModalOpen={qrCodeModalOpen} setQrCodeModalOpen={setQrCodeModalOpen} qrCodeUrl={qrCodeUrl} closable={true} />}
        {
          pagination?.totalCount! > 10 && <Pagination current={currentPage}
            total={pagination?.totalCount} onChange={onPageChange}
            style={{ textAlign: 'center' }} showSizeChanger={false} />
        }
      </>
    </div>
  )
}

export default PendingInvites