import { ChevronLeft } from "@carespace-icons/arrows/chevronLeft";
import { ChevronRight } from "@carespace-icons/arrows/chevronRight";
import { Edit01 } from "@carespace-icons/general/edit01";
import { PlayCircle } from "@carespace-icons/media/playCircle";
import { useTypedDispatch, useTypedSelector } from "@stores/index";
import { ICustomModalProps, ICustomRom, ICustomRomExercise } from "@stores/interfaces";
import { Button, Collapse, Empty, message, Modal, Switch, Tag, Typography } from "antd"
import { MouseEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AddRomPopup from "./OCreateRomModal/AddRomPopup";
import { createCustomSession, createSession, resetAll, selectExercise, setSession } from "@stores/rom/main";
import { useNavigate } from "react-router-dom";
import { updateCustomRom } from "@stores/rom/customRom";
import { router } from '@routers/routers';

const { Panel } = Collapse

const CustomModalInfo = (props: ICustomModalProps) => {
  const { name, description, video } = props;
  const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const modalContent = (
    <div className="select-none" style={{ textAlign: 'center', marginTop: '10%' }}>
      <video
        controls
        className="video"
        preload="metadata"
        src={video!}
        width="100%"
        height="100%"
      />
      <div className="select-none" style={{ marginTop: '24px' }}>
        <Typography.Title level={5}>{name}</Typography.Title>
        <Typography.Text>{description}</Typography.Text>
      </div>
    </div>
  );
  Modal.info({
    title: null,
    content: modalContent,
    maskClosable: true,
    icon: null,
    okButtonProps: { style: { display: 'none' } },
    style: modalStyle,
    closable: true
  });
};

interface CustomRomDataItemProps {
  program: ICustomRom
  refresh: boolean
  setRefresh: (val: boolean) => void
  updateProgramExercises: (ex: ICustomRomExercise[]) => void
}

export const CustomRomDataItem = ({ program, refresh, setRefresh, updateProgramExercises }: CustomRomDataItemProps) => {

  const { t } = useTranslation()
  const [romProgramExercise, setRomProgramExercise] = useState(program)
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useTypedDispatch()
  const [isOpen, setIsOpen] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [showNextPrevious, setShowNextPrevious] = useState(false)
  const [hoveredSession, setHoveredSession] = useState<string | null>(null);
  const [approved, setApproved] = useState(program?.active == true)
  const user = useTypedSelector(state => state.user);
  const navigate = useNavigate()
  const { selectedUser } = useTypedSelector((state) => state.contacts.main)

  useEffect(() => {
    setApproved(program?.active == true)
  }, [program])

  const scrollLeft = () => {
    const currentPosition = scrollRef.current?.scrollLeft;
    const targetPosition = currentPosition! - 400;
    scrollToPosition(targetPosition, 300);
  };

  const handleStartSession = async (e: MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    dispatch(resetAll())
    if (pendingSession) {
      setIsModalVisible(true)
    } else {
      await dispatch(createCustomSession({
        userId: user.isPhysioterapist ? selectedUser?.id : user?.id,
        romProgramId: program?.id
      }))
      dispatch(selectExercise(program?.exercises))
      navigate(`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.AIASSISTANT_START_ROM_SCAN}`);
    }
  }

  const scrollRight = () => {
    const currentPosition = scrollRef.current?.scrollLeft;
    const targetPosition = currentPosition! + 400;
    scrollToPosition(targetPosition, 300);
  };

  const scrollToPosition = (targetPosition: number, duration: number) => {
    const startTime = performance.now();
    const start = scrollRef.current?.scrollLeft;

    const scroll = (timestamp: number) => {
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1);
      scrollRef.current!.scrollLeft = start! + (targetPosition - start!) * progress;

      if (progress < 1) {
        requestAnimationFrame(scroll);
      }
    };

    requestAnimationFrame(scroll);
  };

  const pendingSession = program?.customRomSession?.find(session => session?.completed == false && session?.customRomSessionExercise?.length < program?.exercises.length)

  const handleNo = async () => {
    setIsModalVisible(false)
    await dispatch(createSession({
      id: pendingSession?.id,
      userId: user.isPhysioterapist ? selectedUser.id : user.id,
      customRomId: program?.id
    }))
    dispatch(selectExercise(program?.exercises))
    navigate(`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.AIASSISTANT_START_ROM_SCAN}`);
  }

  const handleYes = async () => {
    setIsModalVisible(false)
    dispatch(setSession({
      session: pendingSession,
      exercises: program?.exercises
    }))
    navigate(`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.AIASSISTANT_START_ROM_SCAN}`);
  }

  const handleApproveProgram = async (value: boolean) => {
    await dispatch(updateCustomRom({
      programId: program?.id,
      programData: {
        active: value,
      }
    })).then(() => value ? message.success(t('Admin.data.menu.aiAssistant.aiAssistantPrograms.approved')) : message.success(t('Admin.data.menu.aiAssistant.aiAssistantPrograms.disapproved')))
  }

  useEffect(()=>{
      setRomProgramExercise(program)
  },[isEditModalVisible])

  return <div>
    <Collapse className="collapse-panel-container" bordered={false} activeKey={isOpen ? program.id : undefined}>
      <Panel
        key={program?.id}
        className='header-panel !w-full bg-white !border !border-gray-200 !rounded-xl !mt-2'
        onClick={() => setIsOpen(!isOpen)}
        header={
          <div className="flex justify-between exercise-start-session-button" onMouseEnter={() => setHoveredSession(program.id)} onMouseLeave={() => setHoveredSession(null)}>
            <div className="flex gap-1 items-center">
              <p className="text-sm font-semibold text-gray-600">{program?.title}</p>
              {/* {
                program?.originType === 'manual'
                  ? <Tag color="purple">{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.byPT')}</Tag>
                  : <Tag color="blue">{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.byAI')}</Tag>
              } */}
            </div>
            {
              user.isPhysioterapist
                ? <div className="flex gap-3 h-6">
                  {
                    hoveredSession === program.id && <>
                      <span className="flex h-fit items-center gap-1 px-1.5 py-0.5 pb-1 rounded-lg bg-gray-100"
                        onClick={(e) => {
                          e.stopPropagation()
                          setIsEditModalVisible(true)
                        }}>
                        <Edit01 width={12} height={12} color="stroke-gray-700" />
                        <p className="text-gray-700 text-xs">{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.edit')}</p>
                      </span>
                      <span className={`flex h-fit items-center gap-1 px-1.5 rounded-lg`}>
                        <Switch
                          checked={approved}
                          onChange={(value, event) => {
                            event.stopPropagation()
                            setApproved(value)
                            handleApproveProgram(value)
                          }}
                          style={{ backgroundColor: approved ? 'rgb(105 56 239)' : '#98a2b3' }} />
                      </span>
                      {
                        !approved && <Button disabled
                          style={{ marginTop: '-5px' }}
                          icon={<PlayCircle height={12} width={12} color="#cfcfcf" />}
                        >
                          {t('Patient.data.myProgress.rehab.startSession')}
                        </Button>
                      }
                    </>
                  }
                  {
                    approved && <Button
                      style={{ color: "#533F85", border: "inherit", marginTop: "-5px" }}
                      className='start-session-css !bg-[#BDFF00]'
                      icon={<PlayCircle height={12} width={12} color="#533F85" />}
                      onClick={async (e) => handleStartSession(e)}
                    >
                      {t('Patient.data.myProgress.rehab.startSession')}
                    </Button>
                  }
                </div>
                : <div className="!h-6">
                  <Button style={{ color: "#533F85", border: "inherit", marginTop: "-6px" }}
                    className="start-session-css !bg-[#BDFF00]"
                    icon={<PlayCircle height={12} width={12} color="#533F85" />}
                    onClick={async (e) => handleStartSession(e)}
                  >
                    {t('Patient.data.myProgress.rehab.startSession')}
                  </Button>
                </div>
            }
          </div>
        }
      >
        {
          romProgramExercise && <div className='w-full p-[15px] bg-gray-50 rounded-lg overflow-hidden' onClick={(e) => e.stopPropagation()}>
            {
              romProgramExercise?.exercises?.length === 0
                ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span className='text-gray-300'>{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.noProgram')}</span>} />
                : <div
                  onMouseOver={() => setShowNextPrevious(true)}
                  onMouseOut={() => setShowNextPrevious(false)}
                  className="w-full relative"
                >
                  <div ref={scrollRef} className="bg-gray-100 overflow-x-auto flex gap-4 p-2">
                    {
                      romProgramExercise?.exercises?.map(item => {

                        const image = item?.image?.url
                        const video = item?.video?.url ? item?.video?.url : item?.video
                        const name = item?.name ? item?.name : item?.title
                        const description = item?.description

                        return <div className="border-2 border-gray-200 rounded-lg hover:shadow-2xl cursor-pointer" style={{ minWidth: '220px', maxWidth: '250px' }}>
                          <div
                            onClick={() => CustomModalInfo({
                              video: video,
                              name: name,
                              description: description,
                            })}
                            className='w-full h-auto rounded-lg relative image-wrapper'>
                            {image ?
                              <img
                                className="video"
                                src={image}
                                width="100%"
                                height="100%"
                              /> :
                              <video
                                className="video"
                                preload="metadata"
                                src={video}
                                width="100%"
                                height="100%"
                              />}
                            <div className="play-button">
                              <PlayCircle width={50} height={50} />
                            </div>
                          </div>
                          <div className="p-3 w-full">
                            <p className="text-gray-500 font-semibold text-lg mb-2">{name}</p>
                            <p className="text-gray-500">{description}</p>
                          </div>
                        </div>
                      })
                    }
                  </div>
                  {
                    romProgramExercise?.exercises?.length > 4 && (showNextPrevious && <div>
                      <span className="p-3 absolute top-1/2 left-0 transform -translate-y-1/2 bg-primary-300 hover:bg-primary-600 rounded-r-full cursor-pointer" onClick={scrollLeft}>
                        <ChevronLeft />
                      </span>
                      <span className="p-3 absolute top-1/2 right-0 transform -translate-y-1/2 bg-primary-300 hover:bg-primary-600 rounded-l-full cursor-pointer" onClick={scrollRight}>
                        <ChevronRight />
                      </span>
                    </div>
                    )
                  }
                </div>
            }
          </div>
        }
      </Panel>
    </Collapse>
    {
      isModalVisible && <Modal
        title="Continue Session"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        centered
      >
        <p>{t('Patient.data.omnirom.continueSession')}</p>
        <div style={{ textAlign: 'right' }}>
          <Button type="default" onClick={handleNo} style={{ marginRight: '10px' }}>
            {t('Patient.data.omnirom.no')}
          </Button>
          <Button type="primary" onClick={handleYes}>
            {t('Patient.data.omnirom.yes')}
          </Button>
        </div>
      </Modal>
    }
    {
      isEditModalVisible && <AddRomPopup
        isVisible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        onOk={() => setIsEditModalVisible(false)}
        refresh={refresh}
        setRefresh={setRefresh}
        selectedExercises={romProgramExercise?.exercises}
        setSelectedExercises={(exe) => setRomProgramExercise({
          ...romProgramExercise,
          exercises: exe,
        })}
        selectedRom={romProgramExercise}
        isEdit
      />
    }
  </div>
}