import AiAssistantSearchBar from '@pages/AiAssistantSearchBar';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { useEffect, useState } from 'react';
import { AiAssistantKeys } from '@stores/interfaces';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import AiTab from '@pages/AiTab';
import './style.less';
import { useTranslation } from 'react-i18next';
import { clearReports } from '@stores/report/reports';
import { setActiveTab } from '@stores/aiAssistant/aiAssistant';
import { useBlockNavigation } from '@atoms/ABlockNavigation';
import { Modal, Button } from 'antd';
import { router } from '@routers/routers';
import { ACTIVETAB, USER_ROLES } from '@stores/constants';

const navigationConfig = {
	activity: { path: router.ACTIVITY },
	myReport: { path: router.AIASSISTANT_MY_REPORT },
	listEvaluation: { path: router.AIASSISTANT_LIST_EVALUATION },
	romSummary: { path: router.AIASSISTANT_ROM_SUMMARY },
	captures: { path: router.AIASSISTANT_CAPTURES },
	startScan: { path: router.AIASSISTANT_START_SCAN },
	startRomScan: { path: router.AIASSISTANT_START_ROM_SCAN },
	postureScan: { path: router.AIASSISTANT_POSTURE_SCAN },
	postureSummary: { path: router.AIASSISTANT_POSTURE_SUMMARY },
	postureCaptures: { path: router.AIASSISTANT_POSTURE_CAPTURES },
	customSummary: { path: router.AIASSISTANT_CUSTOM_SUMMARY },
	customCaptures: { path: router.AIASSISTANT_CUSTOM_CAPTURES },
	generateProgram: { path: router.AIASSISTANT_GENERATE_PROGRAM, state: { isGenerate: true } },
	programs: { path: router.AIASSISTANT_PROGRAMS, state: { isGenerate: false } },
	listSessions: { path: router.AIASSISTANT_LIST_SESSIONS },
	downloadApp: { path: router.DOWNLOAD_APP },
	surveySummary: { path: router.AIASSISTANT_SURVEY_SUMMARY },
	virtualEvaluation: { path: router.AIASSISTANT_VIRTUAL_EVALUATION },
	createReport: { path: router.AIASSISTANT_CREATE_REPORT },
	assignSurvey: {
		path: router.AIASSISTANT_ASSIGN_SURVEY,
		state: { openSurveyModal: true, isGenerate: true },
	},
	createSurvey: {
		path: router.AIASSISTANT_CREATE_SURVEY,
		state: { openSurveyModal: false, isGenerate: false },
	},
	startSurveyUser: { path: router.AIASSISTANT_START_SURVEY_USER },
	newPatients: { path: router.NEW_PATIENTS },
	outOfParams: { path: router.OUTOFPARAMETERS },
	pendingReview: { path: router.PENDINGREVIEW },
	reviewed: { path: router.REVIEWED },
	escalationRequired: { path: router.ESCALATIONREQUIRED },
	noComponentFound: { path: router.AIASSISTANT_PAGENOTFOUND },
	notUnderstandQuestion: { path: router.AIASSISTANT_SEARCHNOTFOUND },
	overview: { path: "overview" }
};

export const AiAssistant = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const [inputText, setInputText] = useState('');
	const [newRecentSearch, setNewRecentSearch] = useState('');
	const dispatch = useTypedDispatch();
	const activeTab = useTypedSelector(
		state => state.aiAssistant.aiAssistant.activeTab,
	);
	const { user, selectedUser } = useTypedSelector(state => ({
		user: state.user,
		selectedUser: state.contacts.main.selectedUser,
	}));
	// const navigate = useNavigate();
	const { isBlocked } = useTypedSelector(
		state => state.aiAssistant.program.blockNavigation,
	);
	const { isCompleted } = useTypedSelector(
		state => state.aiAssistant.program.main,
	);

	const { handleNavigation, confirmNavigation, cancelNavigation } =
		useBlockNavigation(
			!isCompleted && location.pathname.includes('/program/start'),
			t(
				'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.unsaveChanges',
			),
		);
	const [renderedComponent, setRenderedComponent] =
		useState<React.ReactNode | null>(null);

	useEffect(() => {
		const id = user?.isPhysioterapist ? selectedUser?.id : user?.id;
		const path = location.pathname.replace(`/${id}`, '');

		const newActiveTab = Object.keys(navigationConfig).find(
			key => navigationConfig[key].path === path,
		);
		if (newActiveTab) {
			dispatch(setActiveTab(newActiveTab));
		}
	}, [location.pathname, user, selectedUser]);

	useEffect(() => {
		dispatch(clearReports());
	}, [user, selectedUser]);

	useEffect(() => {
		const id = user?.isPhysioterapist ? selectedUser?.id : user?.id;
		const config = navigationConfig[activeTab];

		if (config) {
			const specialTabs = [
				'newPatients',
				'outOfParams',
				'pendingReview',
				'reviewed',
				'escalationRequired',
			];
			const fullPath = specialTabs.includes(activeTab)
				? `${config.path}`
				: `/${id}${config.path}`;

			if (location.pathname != fullPath)
				handleNavigation(fullPath, { state: config.state });
		}
	}, [activeTab, user, selectedUser]);

	const dataset = [
		{
			key: AiAssistantKeys.captures,
			patterns: [
				'captures',
				'omnirom scan captures',
				'chart',
				'screenshot',
				'screenshots',
				'rom captures',
			],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiCapturesResponseOne',
				),
			],
		},
		{
			key: AiAssistantKeys.activity,
			patterns: ['activity', 'activity stream'],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.activityInteractions',
				),
			],
		},
		{
			key: AiAssistantKeys.listEvaluation,
			patterns: [
				'evaluation results',
				'pain summary',
				'associated symptoms',
				'medical history',
			],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiListEvaluationResponseOne',
				),
			],
		},
		{
			key: AiAssistantKeys.virtualEvaluation,
			patterns: [
				'virtual',
				'virtual evaluation',
				'pain',
				'assessment',
				'located',
				'symptoms',
			],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiVirtualEvaluationResponseOne',
				),
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiVirtualEvaluationResponseTwo',
				),
			],
		},
		{
			key: AiAssistantKeys.romSummary,
			patterns: [
				'omnirom scan list',
				'omnirom results',
				'scan exercise',
				'scanned exercises',
				'normal',
				'wfl',
				'value',
			],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiRomSummaryResponseOne',
				),
			],
		},
		{
			key: AiAssistantKeys.romSummary,
			patterns: [
				'omnirom scan list',
				'omnirom results',
				'scan exercise',
				'scanned exercises',
				'normal',
				'wfl',
				'value',
			],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiRomSummaryResponseOne',
				),
			],
		},
		{
			key: AiAssistantKeys.startScan,
			patterns: [
				'choose scan',
				'upper',
				'lower',
				'left',
				'right',
				'body',
				'full scan',
				'full body scan',
				'body scan',
				'start scan',
				'record scan',
				'omnirom scan',
			],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiStartScanResponseOne',
				),
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiStartScanResponseTwo',
				),
			],
		},
		{
			key: AiAssistantKeys.listSessions,
			patterns: [
				'list sessions',
				'sessions',
				'captured sessions',
				'capture session',
			],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiListSessionsResponseOne',
				),
			],
		},
		{
			key: AiAssistantKeys.exercises,
			patterns: ['exercises', 'lets move', "let's move", 'exercise results'],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiExercisesResponseOne',
				),
			],
		},
		{
			key: AiAssistantKeys.programs,
			patterns: ['Program', 'Recovery', 'Hip', 'recoveries', 'recommendations'],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiProgramsResponseOne',
				),
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiProgramsResponseTwo',
				),
			],
		},
		{
			key: AiAssistantKeys.generateProgram,
			patterns: ['Program', 'Generate', 'Ai'],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiProgramsResponseOne',
				),
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiProgramsResponseTwo',
				),
			],
		},
		{
			key: AiAssistantKeys.startSession,
			patterns: ['start session', 'lets scan', "let's move", 'record session'],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiStartSessionResponseOne',
				),
			],
		},
		{
			key: AiAssistantKeys.createReport,
			patterns: [
				'create report',
				'create',
				'content of my reports',
				'create a new report',
			],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiCreateReportResponseOne',
				),
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiCreateReportResponseTwo',
				),
			],
		},
		{
			key: AiAssistantKeys.myReport,
			patterns: ['my report', 'reports' , 'summary'],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiMyReportResponseOne',
				),
			],
		},
		{
			key: AiAssistantKeys.newPatients,
			patterns: [
				'new patients',
				'new patiant',
				'new users',
				'users',
				'added patients',
				'new',
			],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiNewPatientsResponse',
				),
			],
		},
		{
			key: AiAssistantKeys.pendingReview,
			patterns: ['pending reviewed', 'pending', 'pending review'],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiPendingReviewResponse',
				),
			],
		},
		{
			key: AiAssistantKeys.outOfParams,
			patterns: ['out of parameters', 'parameters', 'out of', 'parameter'],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiOutOfParamsResponse',
				),
			],
		},
		{
			key: AiAssistantKeys.reviewed,
			patterns: ['review', 'reviewed'],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiReviewedResponse',
				),
			],
		},
		{
			key: AiAssistantKeys.escalationRequired,
			patterns: ['escalation', 'escalation required'],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiEscalationResponse',
				),
			],
		},
		{
			key: AiAssistantKeys.followUpRequired,
			patterns: ['follow up', 'follow up required', 'follow', 'required'],
			extraResponse: [
				t(
					'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.aiFollowUpResponse',
				),
			],
		},
		{
			key: AiAssistantKeys.startSurveyUser,
			patterns: ['start survey', 'survey', 'perform survey'],
		},
		{
			key: AiAssistantKeys.postureScan,
			patterns: [
				'posture scan',
				'posture analytics',
				'posture summary',
				'posture captures',
			],
		},
		{
			key: AiAssistantKeys.postureSummary,
			patterns: [
				'posture scan',
				'posture analytics',
				'posture summary',
				'posture captures',
			],
		},
		{
			key: AiAssistantKeys.postureCaptures,
			patterns: [
				'posture scan',
				'posture analytics',
				'posture summary',
				'posture captures',
			],
		},
	];

	return (
		<>
				<AiAssistantSearchBar 
				  setRenderedComponent={setRenderedComponent}
					dataset={dataset}
					inputText={inputText}
					setInputText={setInputText}
					setNewRecentSearch={setNewRecentSearch}
					newRecentSearch={newRecentSearch}/>
			<div
				className={`select-none ${activeTab != AiAssistantKeys.activity && activeTab != ACTIVETAB.DOWNLOAD_APP && activeTab != '' ? `${activeTab === ACTIVETAB.HOME ? "mt-[6.8rem]" : "mt-[8rem]"}` : 'mt-20'}`}>
				<Outlet />
			</div>
			{isBlocked && location.pathname.includes('/program/start') && (
				<Modal
					title={t(
						'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.continueSession',
					)}
					open={isBlocked}
					onCancel={cancelNavigation}
					footer={null}
					centered>
					<p>
						{t(
							'Admin.data.menu.aiAssistant.aiAssistantDatasetResponses.unsaveChanges',
						)}
					</p>
					<div style={{ textAlign: 'right' }}>
						<Button
							type="default"
							onClick={cancelNavigation}
							style={{ marginRight: '10px' }}>
							{t('Patient.data.omnirom.no')}
						</Button>
						<Button type="primary" onClick={confirmNavigation}>
							{t('Patient.data.omnirom.yes')}
						</Button>
					</div>
				</Modal>
			)}
		</>
	);
};
