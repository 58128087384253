import { useNavigate } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { Popover, type MenuProps, Avatar, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Edit02 } from '@carespace-icons/general/edit02';
import { useEffect, useState } from 'react';
import ProfileModal from '@pages/Profile/ProfileModal';
import UserDetails from '@atoms/AdminMenu/UserDetails';
import { getFunctionalGoals } from '@stores/functionalGoals';
import { getUser } from '@stores/user';
import './style.css';
import { LogOut04 } from '@carespace-icons/general/logOut04';
import { PlayCircle } from '@carespace-icons/media/playCircle';
import { setActiveTab, setStartSession } from '@stores/aiAssistant/aiAssistant';
import { useBlockNavigation } from '@atoms/ABlockNavigation';
import { router } from '@routers/routers';
import { UseAuth } from '@contexts/AuthContext';
import { ACTIVETAB, USER_ROLES } from '@stores/constants';
import {
	getProgramListApproved,
	selectExercise,
	selectProgram,
} from '@stores/aiAssistant/program';
import { status } from '@pages/PatientOnboard/Constants';
import HeightInfo from '@atoms/AdminMenu/HeightInfo';
import { NotificationText } from '@carespace-icons/alerts/notificationText';
import { Phone02 } from '@carespace-icons/media/phone02';
import { HomeLine } from '@carespace-icons/general/homeLine';
import { Logomark } from '@carespace-icons/Logo/logomark';
import { IMenuAdminProps } from '@stores/interfaces';
import { List } from '@carespace-icons/layoult/list';
import { PlaySquare } from '@carespace-icons/media/playSquare';

const UserMenu = () => {
	const { t } = useTranslation();
	const { handleNavigation } = useBlockNavigation(
		location.pathname.includes('/program/start'),
		'You have unsaved changes. Do you want to leave?',
	);
	const navigate = useNavigate();
	const dispatch = useTypedDispatch();
	const user = useTypedSelector(state => state.user);
	const program = useTypedSelector(
		state => state.aiAssistant.program.programApproved,
	);

	const isSuperAdmin = user?.profile?.role === USER_ROLES.SUPER_ADMIN;
	const currentLocation = window.location.pathname;
	const isCorrectLocation = currentLocation.includes('/program/start');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [policyModalOpen, setPolicyModalOpen] = useState(false);
	const [functionalData, setFunctionalData] = useState();
	const [profileData, setProfileData] = useState({});
	const [isProgramAvailable, setProgramAvailable] = useState(false);
	const [activeMenu, setActiveMenu] = useState(currentLocation.replace('/', ''));

	const buttonStyle = {
		color: '#533F85',
		border: 'inherit',
		width: '100%',
		margin: '10px 0',
	};
	const romData = useTypedSelector(
		state => state.rom.results.romSessionListData,
	);
	const [menuUser, setMenuUser] = useState([
		{
			label: t('Admin.data.menu.home.home'),
			key: '',
			icon: <HomeLine width={20} height={20} color={'stroke-white'} />,
		},
		{
			key: 'startScan',
			icon: <PlaySquare width={20} height={20} color={'stroke-white'} />,
			label: t('Admin.data.menu.home.startScanRom'),
		},
		{
			label: '',
			key: 'activity',
			icon: <NotificationText width={20} height={20} color={'stroke-white'} />,
		},
		{
			label: t('Admin.data.menu.home.downloadAppView'),
			key: 'downloadApp',
			icon: <Phone02 width={20} height={20} color={'stroke-white'} />,
		},
	]);

	useEffect(() => {
		if (romData?.data?.length > 0) {
			setMenuUser((prevMenu) => {
				const romSummaryExists = prevMenu.some((item) => item.key === 'romSummary');
	
				if (!romSummaryExists) {
					const newMenu = [...prevMenu];
					newMenu.splice(2, 0, {
						key: 'romSummary',
						icon: <List width={20} height={20} color={'stroke-white'} />,
						label: t('Admin.data.menu.home.romSummaryList'),
					});
					return newMenu;
				}
	
				return prevMenu;
			});
		}
	}, [romData]);

	const { credentials, onLogout } = UseAuth();

	const logout = () => {
		onLogout();
	};

	const handleClick = () => handleNavigation(router.ROOT);

	useEffect(() => {
		if (currentLocation === `/${user?.id}${router.ACTIVITY}`) {
			setActiveMenu('activity')
		} else if (currentLocation === `/${user?.id}${router.DOWNLOAD_APP}`) {
			setActiveMenu(ACTIVETAB.DOWNLOAD_APP)
		} else if (currentLocation === `/${user?.id}${router.AIASSISTANT_START_SCAN}`) {
			setActiveMenu(ACTIVETAB.START_SCAN)
		}else if (currentLocation === `/${user?.id}${router.AIASSISTANT_ROM_SUMMARY}`) {
			setActiveMenu(ACTIVETAB.ROM_SUMMARY)
		} else {
			setActiveMenu('')
		}
	}, [currentLocation])

	useEffect(() => {
		user?.id && fetchProgramData(1);
	}, [user]);

	const fetchProgramData = async (pageNumber: number) => {
		const payload = {
			userId: user?.id,
			limit: 10,
			page: pageNumber,
			searchValue: '',
			status: status.APPROVED,
		};
		const data = await dispatch(getProgramListApproved(payload));
		if (data?.payload?.data?.length! > 0) {
			setProgramAvailable(true);
		} else {
			setProgramAvailable(false);
		}
	};

	const popoverContent = (item: string) => {
		return <span className="user-popup-label-capitalize">{item}</span>;
	};

	const fetchingData = async () => {
		const data = await dispatch(getFunctionalGoals());
		setFunctionalData(data?.payload?.data);
	};

	useEffect(() => {
		if (!isSuperAdmin && !user?.isPhysioterapist) {
			fetchingData();
		}
	}, [dispatch]);

	const getUserData = async () => {
		if (credentials?.sub) {
			const user = await dispatch(getUser(credentials.sub));
			setProfileData(user.payload);
		}
	};

	useEffect(() => {
		if (!isSuperAdmin && !user?.isPhysioterapist) {
			getUserData();
		}
	}, []);

	const handleStartSession = () => {
		if (program.data?.length == 1) {
			dispatch(selectProgram(program.data[0]));
			dispatch(selectExercise(program.data[0].exercises));
			navigate(`/${user.id}${router.AIASSISTANT_PROGRAM_START}`);
			dispatch(setActiveTab(''));
			dispatch(setStartSession(false));
		} else if (program.data?.length! > 1) {
			dispatch(setActiveTab('programs'));
			dispatch(setStartSession(true));
			handleNavigation(`/${user?.id}${router.AIASSISTANT_PROGRAMS}`);
		}
	};

	return (
		<div style={{ position: 'relative' }}>
			<ul className="user-menu">
				<li className="li-logo" onClick={() => { handleClick(); setActiveMenu('') }} title="home">
					<Logomark />
				</li>
				{menuUser?.map((item: IMenuAdminProps, index) => {
					if (item.key == 'download-app') {
						return (
							<Popover
								key={index}
								content={popoverContent('Download App')}
								title=""
								placement="right">
								<li
									className={`menu-item ${activeMenu === item.key ? 'menu-item-active' : ''}`}
									onClick={() => {
										if (location.pathname.includes('/program/start')) {
											handleNavigation(router.SELECTACTIVITYUSER, {}, () => {
												setActiveMenu(item.key);
											});
										} else {
											navigate(`/${user?.id}${router.DOWNLOAD_APP}`);
											dispatch(setActiveTab(ACTIVETAB.DOWNLOAD_APP));
											setActiveMenu(item.key);
										}
									}}>
									{item.icon}
								</li>
							</Popover>
						);
					} else if (item.key == 'activity') {
						return (
							<Popover
								key={index}
								content={popoverContent(item.key)}
								title=""
								placement="right">
								<li
									className={`menu-item ${activeMenu === item.key ? 'menu-item-active' : ''}`}
									onClick={() => {
										if (location.pathname.includes('/program/start')) {
											handleNavigation(`${user.id}${router.ACTIVITY}`, {}, () => {
												setActiveMenu(item.key);
											});
										} else {
											navigate(`/${user?.id}${router.ACTIVITY}`);
											dispatch(setActiveTab('activity'));
											setActiveMenu(item.key);
										}
									}}>
									{item.icon}
								</li>
							</Popover>
						);
					} else if (item.key == 'downloadApp') {
						return (
							<Popover
								key={index}
								content={popoverContent(item.key)}
								title=""
								placement="right">
								<li
									className={`menu-item ${activeMenu === item.key ? 'menu-item-active' : ''}`}
									onClick={() => {
										navigate(`/${user?.id}${router.DOWNLOAD_APP}`);
										setActiveMenu(item.key);
									}}>
									{item.icon}
								</li>
							</Popover>
						);
					} else if (item.key == 'startScan') {
						return (
							<Popover
								key={index}
								content={popoverContent(item.label)}
								title=""
								placement="right">
								<li
									className={`menu-item ${activeMenu === item.key ? 'menu-item-active' : ''}`}
									onClick={() => {
										navigate(`/${user?.id}${router.AIASSISTANT_START_SCAN}`);
										setActiveMenu(item.key);
									}}>
									{item.icon}
								</li>
							</Popover>
						);
					} else if (item.key == 'romSummary') {
						return (
							<Popover
								key={index}
								content={popoverContent(item.label)}
								title=""
								placement="right">
								<li
									className={`menu-item ${activeMenu === item.key ? 'menu-item-active' : ''}`}
									onClick={() => {
										navigate(`/${user?.id}${router.AIASSISTANT_ROM_SUMMARY}`);
										setActiveMenu(item.key);
									}}>
									{item.icon}
								</li>
							</Popover>
						);
					} else {
						return (
							<Popover
								key={index}
								content={popoverContent(item.label)}
								title=""
								placement="right">
								<li
									className={`menu-item ${activeMenu === item.key ? 'menu-item-active' : ''}`}
									onClick={() => {
										handleClick();
										setActiveMenu('');
									}}
									key={index}
									title={item.key}>
									{item.icon}
								</li>
							</Popover>
						);
					}
				})}
			</ul>
			<ul className="user-menu-content">
				<li style={{ position: 'relative', padding: '16px' }}>
					<div className="avatar-container">
						<>
							{user?.profile?.imageUrl ? (
								<Avatar
									src={user?.profile?.imageUrl}
									alt="avatar"
									size={100}
								/>
							) : (
								<Avatar
									style={{
										backgroundColor: user?.profile?.avatarColor || '#1890FF',
										color: '#FFF',
										fontSize: '50px',
										border: '4px solid white',
										display: 'inline-flex',
										alignItems: 'center',
									}}
									alt="avatar"
									size={100}>
									{user.profile?.firstName.charAt(0).toUpperCase()}
								</Avatar>
							)}
						</>
						<div
							className="edit-image-icon"
							style={{ backgroundColor: 'rgba(127, 86, 217, 1)', height: '30px', width: '30px', textAlign: 'center' }}
							onClick={() => {
								setIsModalOpen(true);
							}}>
							<Edit02 width={20} height={20} />
						</div>
						{isModalOpen && (
							<ProfileModal
								isModalOpen={isModalOpen}
								setIsModalOpen={setIsModalOpen}
								setPolicyModalOpen={setPolicyModalOpen}
								policyModalOpen={policyModalOpen}
								closable={true}
								onEdit={true}
							/>
						)}
						<p
							className="first-name-label-text"
							style={{ lineHeight: '0.9' }}>
							{user?.profile?.firstName} {user?.profile?.lastName}
						</p>
						<p className="email-label-text">{user?.profile?.email}</p>
						<HeightInfo userDetails={profileData} />
						{!isCorrectLocation && isProgramAvailable && (
							<Button
								className="start-session-css"
								style={buttonStyle}
								icon={<PlayCircle height={18} width={18} color="#533F85" />}
								onClick={e => {
									e.stopPropagation();
									handleStartSession();
								}}>
								{t('Patient.data.myProgress.rehab.startSession')}
							</Button>
						)}
					</div>
					<UserDetails userDetails={user} functionalData={functionalData} />
				</li>
				<li className='logoutContainer'>
					<div className="logoutContainer-div">
						<p className="logoutText" onClick={logout}>
							Logout
						</p>
						<Popover
							content={popoverContent(t('Patient.data.menu.logout'))}
							title=""
							placement="right">
							<span className="logoutIcon-image" onClick={logout}>
								<LogOut04 width={20} height={20} color={'stroke-white'} />
							</span>
						</Popover>
					</div>
				</li>
			</ul>
		</div>
	);
};

export default UserMenu;
