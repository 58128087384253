import { Dispatch, ReactNode, SetStateAction } from 'react';
import { pagination } from './constants';
import { NormalizedLandmark } from '@mediapipe/tasks-vision';
import { linkedListTransitions } from './rom/customRom';
export enum BodyRegion {
	full = 'full',
	upperBody = 'upperBody',
	upperRight = 'upperRight',
	upperLeft = 'upperLeft',
	lowerBody = 'lowerBody',
	lowerRight = 'lowerRight',
	lowerLeft = 'lowerLeft',
}
export interface TFormDataType {
	userId: string;
	strapiBodyPointId: number;
	strapiAggravatingFactorsIds: [];
	strapiRelievingFactorsIds: [];
	strapiPainCausesIds: [];
	strapiPainDurationId: number;
	strapiPainFrequencyId: number;
	strapiPainStatusId: number;
}

export interface TProfileData {
	firstName: string;
	lastName: string;
	email: string;
	imageUrl: string;
	birthDate?: string;
	gender: string;
	imperialHeight: number;
	imperialWeight: number;
	metricWeight: number;
	metricHeight: number;
	isPregnant: boolean;
	consentPolicyRead: boolean;
	patientId: string;
	role?: string;
	features?: string[];
}

export interface FunctionalGoalsProps {
	functionalGoalsIds: number[];
}

export type IBodyRegionNormalized = Record<BodyRegion, string>;
export interface FeatureProps {
	title: string;
	description: string;
	buttonText: string;
	imageSrc: string;
	onClick: () => void;
	logoSrc: string;
	disabled: boolean;
}

export enum Status {
	newPatients = 'newPatients',
	outOfParams = 'outOfParams',
	pendingReview = 'pendingReview',
	reviewed = 'reviewed',
	escalationRequired = 'escalationRequired',
	followUpRequired = 'followUpRequired',
}

export enum StatusPatients {
	unAssignedPatients = 'unAssignedPatients',
	pendingInvites = 'pendingInvites',
	registeredPatients = 'registeredPatients',
}

export type IStatusNormalized = Record<Status, string>;

export type IStatusNormalizedPatients = Record<StatusPatients, string>;

export interface ISession {
	strapiOmniRomExerciseGroup: IStrapiOmniRomExerciseGroup;
	id: string;
	createdAt: Date;
	bodyRegion?: BodyRegion;
	status: Status;
	questionList?: ISurveyResultQuestionList[];
	surveyId?: string;
	programId?: string;
	customRomId: string;
	customRomExerciseId: string;
	customRomSessionExercise: ICustomRomSessionExercise[];
}

export interface IStrapiOmniRomExerciseGroup {
	id: number;
	name: string;
}

export interface IUserWithSession {
	profile: userData;
	id: string;
	romSessions?: ISession[];
	program?: ISession[];
	evaluationSession?: ISession[];
	survey?: ISession[];
}

export interface IUserPaginationDefaultResponse
	extends IPaginationPluginDefaultResponse {
	total?: number;
}

export interface IUserRegisterdPatientResponse
	extends IPaginationPluginDefaultResponse {
	total?: number;
	userId?: string;
	name?: string;
	role?: string;
}
interface IRomRehab {
	users: IUserWithSession[];
	pagination: IUserPaginationDefaultResponse;
	statusToRefresh: Status[];
}
export type IOminiRom = IRomRehab;

export type IRehab = IRomRehab;

export type IEvaluationData = IRomRehab;

export type ISurveyData = IRomRehab;

export type IPostureDataStatus = IRomRehab;

export interface IStatusStoreState {
	omnirom: IOminiRom;
	rehab: IRehab;
	evaluation: IEvaluationData;
	survey: ISurveyData;
	postures: IPostureDataStatus;
}

interface IAdminPatientData {
	data: any[];
	pagination: IUserPaginationDefaultResponse;
}

export interface INewAdminPatientsList {
	unAssignedPatients: IAdminPatientData;
	pendingInvites: IAdminPatientData;
	registeredPatients: IAdminPatientData;
	allAdminList: ResponseDataNewDashoard;
	allFeatureFlags: AllFeatureFlagsInterface[];
	loading: boolean;
	unAssignedCount: number;
	pendingCount: number;
	registeredCount: number;
	superAdminFeatureFlags: Feature[];
	statsCount: {
		newUsers: number;
		pendingReview: {
			evaluation: number;
			omniRom: number;
			letsMove: number;
			survey: number;
		};
		outOfParams: {
			evaluation: number;
			omniRom: number;
			letsMove: number;
			survey: number;
		};
		followUpRequired: {
			evaluation: number;
			omniRom: number;
			letsMove: number;
			survey: number;
		};
		escalationRequired: {
			evaluation: number;
			omniRom: number;
			letsMove: number;
			survey: number;
		};
		reviewed: {
			evaluation: number;
			omniRom: number;
			letsMove: number;
			survey: number;
		};
	};
}
export interface ISessionResponsePayload {
	data: IUserWithSession[];
	pagination: IUserPaginationDefaultResponse;
}

interface IGeneralDetails {
	id: string;
	userId: string;
	bodyRegion?: BodyRegion;
	status: Status;
	completed: boolean;
	createdAt: Date;
	updatedAt: Date;
}

export interface IUpdateSessionStatusResponsePayload extends IGeneralDetails {
	rehabPatientListExercises?: {
		userId: string;
	};
}

export interface IUpdateProgramStatusResponsePayload extends IGeneralDetails {
	program?: {
		userId: string;
	};
}

export interface IUpdateEvaluationStatusResponsePayload
	extends IGeneralDetails {
	evaluation?: {
		userId: string;
	};
}

export interface IUpdateStatusBody {
	sessionId: string;
	body: {
		status: Status;
	};
}

export enum FacingMode {
	USER = 'user',
	ENVIRONMENT = 'environment',
}

export enum RehabVideoState {
	START = 'START',
	READY = 'READY',
	SAVING = 'SAVING',
	RECORDING = 'RECORDING',
	STOPPED = 'STOPPED',
	REPLAYING = 'REPLAYING',
	RATING = 'RATING',
}

export interface IRebhabProgramExercise {
	id: string | number;
	name: string;
	video: Blob;
	programSessionId: string;
	programExerciseId: string;
	exerciseDifficultyLevel: string | number;
}

export interface FunctionalGoals {
	id: number;
	attributes: {
		name: string;
		createdAt: Date;
		updatedAt: Date;
		publishedAt: Date;
	};
}

export interface DiagnosisCode {
	id: number;
	code: string;
	description: string;
	createdAt: Date;
	updatedAt: Date;
	publishedAt: Date;
	locale: string;
}

export interface IClientCreate {
	name: string;
	domain: string;
	fusionAuthAppId: string;
	fusionAuthTenantId: string;
	inviteCode: string;
}

export interface IClient extends IClientCreate {
	id: string;
	active: boolean;
	createdAt: Date;
	updatedAt: Date;
}

export type IPaginationPluginDefaultResponse = {
	isFirstPage: boolean;
	isLastPage: boolean;
	currentPage: number;
	previousPage: number | null;
	nextPage: number | null;
	pageCount: number;
	totalCount: number;
};

export interface IDefaultPagination {
	limit?: number;
	page?: number;
}

interface IPaginationDefaultResponse {
	total: number;
	pageCount: number;
	currentPage: number;
	perPage: number;
	from: number;
	to: number;
}

export enum UserToolStatusEnum {
	assignRehab,
	assignedRehab,
}

export enum OverallConditionEnum {
	improving,
	noChange,
	worsening,
}

export interface IEvaluationBodyPoint {
	id: number;
	attributes: {
		name: string;
		position: string;
	};
}

export interface IEvaluation {
	id: string;
	user?: IUser;
	userId: string;
	active: boolean;
	painAssessments?: IEvaluationPainAssessment[];
	healthSigns?: IEvaluationHealthSigns;
	medicalHistories?: IEvaluationMedicalHistory;
	createdAt: Date;
	updatedAt: Date;
}

export interface IEvaluationPaginated {
	data: IEvaluation[];
	pagination: IPaginationDefaultResponse;
}
interface IStrapiGeneral {
	id: number;
	attributes: {
		name: string;
		locale?: string;
	};
}
export type IStrapiHealthSign = IStrapiGeneral;

export type IStrapiMedicalHistory = IStrapiGeneral;

interface Evaluation {
	id: string;
	user?: IUser;
	userId: string;
	notes?: string;
	createdAt?: Date;
	updatedAt?: Date;
}

export interface IEvaluationHealthSigns extends Evaluation {
	strapiHealthSignsIds: number[];
	strapiHealthSigns?: IStrapiHealthSign[];
}

export interface IEvaluationMedicalHistory extends Evaluation {
	strapiMedicalHistoriesIds: number[];
	strapiMedicalHistories?: IStrapiMedicalHistory[];
}
interface IIds {
	strapiBodyPointId: number;
	strapiAggravatingFactorsIds: number[];
	strapiRelievingFactorsIds: number[];
	strapiPainCausesIds: number[];
	strapiPainDurationId: number;
	strapiPainFrequencyId: number;
	strapiPainStatusId: number;
}
export interface IEvaluationPainAssessment extends Evaluation, IIds {
	strapiBodyPoint?: IEvaluationBodyPoint;
}

interface IOptions {
	id: number;
	attributes: {
		name: string;
		bodyPoint: IEvaluationBodyPoint;
	};
}

export interface IEvaluationPainAssessmentOptions {
	aggravatingFactors: IOptions[];
	relievingFactors: IOptions[];
	painCauses: IOptions[];
	painDurations: IOptions[];
	painFrequencies: IOptions[];
	painStatuses: IOptions[];
}
interface IExerciseDetails {
	id: string;
	key: string;
	label: string;
	sample: string;
	neutralAngle: number;
	acceptableAngle: number;
	minAcceptableAngle: number;
	wfl: number;
	order: number;
	createdAt: Date;
	updatedAt: Date;
	active: boolean;
}
export interface IMovrExercise extends IExerciseDetails {
	movrId: number;
	movrExerciseGroupId: string;
}

export interface ILetsMoveExercise {
	id: string;
	name: string;
	side: Side;
	createdAt: Date;
	updatedAt: Date;
	movrExercise: IMovrRomExercise[];
	styles: IBodyPointsStyle;
}

type Days = {
	[key: string]: boolean;
};
interface IGeneralProgram {
	id: string;
	userId: string;
	name: string;
	active: boolean;
	score: number;
	movrRomCompleted: boolean;
	movrRomPatientResults?: ILetsMovePatientResult[];
	movrProgramHistory?: ILetsMoveProgramHistory[];
	startedAt?: Date;
	expirationAt?: Date;
	createdAt: Date;
	updatedAt: Date;
	closedAt?: Date;
	days?: IDays;
}
export interface ILetsMoveCustomProgram extends IGeneralProgram {
	movrPrescribedExercises?: ILetsMovePrescribedExercisesApi[];
	reasons?: string[];
}
export interface IMovrProgram extends IGeneralProgram {
	movrPrescribedExercises?: ILetsMovePrescribedExercisesApi[];
	bodyRegion?: string;
}

export interface ILetsMovePerformanceExercises {
	id: string;
	name: string;
	videoUrl: string;
	imageUrl: string;
	movrExerciseId: number;
	createdAt: Date;
	updatedAt: Date;
}
interface IPrescribeStrpiExe {
	id: number;
	description: string;
	name: string;
	is_bilateral: boolean;
	recommended_duration: number;
}
export type PrescribedExercise = {
	id: number;
	description: string;
	name: string;
	is_bilateral: boolean;
	recommended_duration: number;
	exercise_video?: string;
	exercise_image?: string;
};

export interface ILetsMovePrescribedExercisesApi {
	id: string;
	prescribedExercise: PrescribedExercise;
	movrProgramId: string;
	createdAt: Date;
	updatedAt: Date;
}

export interface ILetsMoveProgramHistory {
	id: string;
	movrProgramId: string;
	movrPerformancePatientResultId: string;
	createdAt: Date;
}
export interface IDays {
	[key: string]: boolean;
}

export interface ILetsMovePatientResult {
	id: string;
	userId: string;
	movrExercise?: ILetsMoveExercise;
	movrExerciseId: string;
	value: number;
	screenshot: string;
	movrProgramId: string;
	createdAt: Date;
	updatedAt: Date;
}

export interface ILetsMovePerformancePatientResults {
	id: string;
	userId: string;
	movrPerformanceExerciseId: string;
	createdAt: Date;
	updatedAt: Date;
}

export interface ILetsMovePrescribedExercises {
	success: string;
	result: [];
}

export interface IReportsFindAllSearch extends IDefaultPagination {
	readonly search?: string;
}

export enum ReportsResources {
	romSessions = 'romSessions',
	romResults = 'romResults',
	rehabSessions = 'rehabSessions',
	evaluationSessions = 'evaluationSessions',
}

export enum Sort {
	name = 'name',
	createdAt = 'createdAt',
}
export interface IPaginationAndSort extends IDefaultPagination {
	sort?: Sort;
}

export interface ReportNotes {
	createdAt: Date;
	notes: string;
	image: string;
	video: string;
	jobId: string;
}

export interface IReport {
	id: string;
	user?: IUser;
	userId: string;
	name: string;
	romSessionsIds?: IRomSession[];
	romSessionsNotes?: ReportNotes[];
	surveyResultNotes?: ReportNotes[];
	romResultsIds?: IRomPatientResult[];
	romResultsNotes?: ReportNotes[];
	programSessionsIds?: IRehabExerciseListSession[];
	programSessionsNotes?: ReportNotes[];
	evaluationSessionsIds?: IEvaluation[];
	surveyResultIds?: ISurveyResult[];
	evaluationSessionsNotes?: ReportNotes[];
	assessmentNotes?: ReportNotes[];
	diagnosisCode?: number[];
	planNotes?: ReportNotes[];
	startDate?: Date;
	endDate?: Date;
	createdAt: Date;
	updatedAt: Date;
	postureSessionsIds?: IPosture[];
	postureSessionsNotes?: ReportNotes[];
}

export interface IReportPaginatedResponse {
	data: IReport[] | null;
	pagination: IPaginationPluginDefaultResponse | null;
}

export interface IReportData {
	reports: IReportPaginatedResponse;
	report: IReport | null;
	loading: boolean;
	reportIds: ReportIdsInterface;
	searchTextForReports: string
}

export interface IProgramExercise {
	exerciseLibrary: ExerciseLibrary | null;
	exerciseLibraryId: string;
	active: boolean;
	createdAt: Date;
	dailyReps: number;
	reps: number;
	sets: number;
	weeklyReps: number;
	strapiExerciseId: number | StrapiExercises;
	strapiOmniRomExerciseId?: number | StrapiExercises;
	id: number;
	programId: string;
	updatedAt: Date;
	name: string;
	description: string;
	image: string;
	video: string;
	order: number;
	uploadProgress?: number;
}

export interface StrapiExercises {
	id: number;
	name: string;
	description: string;
	is_bilateral: boolean;
	recommended_duration?: any;
	createdAt: string;
	updatedAt: string;
	publishedAt: string;
	locale: string;
	instructions: string;
	contraindications: string;
	therapist_notes: string;
	breathing: string;
	supportive_surfaces: string;
	alignment_tips: string;
	frequency: string;
	equipment_alternatives: string;
	precautions: string;
	functional_applications: string;
	exercise_video: StrapiExercisevideo[];
	exercise_image: StrapiExerciseImage[];
	video?: StrapiExercisesAssets;
	image?: StrapiExercisesAssets;
}

interface StrapiExercisesAssets {
	id: number;
	url: string;
}
type StrapiExerciseImage = StrapiExercisesAssets;
type StrapiExercisevideo = StrapiExercisesAssets;

export interface IOpenAiProgramExercises {
	id: number;
	name: string;
	justify: string;
	provider: string;
	weeklyReps: number;
	dailyReps: number;
	sets: number;
	reps: number;
}

export interface IOpenAiProgram {
	duration: number;
	durationType: string;
	exercises: IOpenAiProgramExercises[];
	programName: string;
}

export interface IProgramData {
	active: boolean;
	createdAt: Date;
	duration: number;
	durationType: string;
	status: string;
	exercises: IProgramExercise[];
	programTemplateId: string;
	startAt: Date;
	originType: string;
	deletedAt: Date;
	finishAt: Date;
	id: string;
	name: string;
	userId: string;
	updatedAt: Date;
}

export interface IExerciseDataItemProps {
	index: number;
	refresh: boolean;
	isEdit: boolean;
	program: IProgramData;
	programExercises: IProgramExercise[];
	isLoading: boolean;
	setLoading: (isLoading: boolean) => void;
	updateIsEditAtIndex: (index: number, value: boolean) => void;
	updateIsApprovedAtIndex: (index: number, value: boolean) => void;
	setRefresh: (refresh: boolean) => void;
	session: boolean;
	setSession: (value: boolean) => void;
}

export interface IProgram {
	id: string;
	userId: string;
	name: string;
	active: boolean;
	duration: number;
	durationType: string;
	startAt: string;
	finishAt: string;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	status: string;
	originType: string;
	programTemplateId: string | null;
}
export interface IProgramResponse {
	data: IProgramData[] | null;
	pagination: IPaginationPluginDefaultResponse | null;
}

export interface IProgramTemplateResponse {
	data: IProgramTemplate[] | null;
	pagination: IPaginationPluginDefaultResponse | null;
}

export interface Exercise {
	id: string;
	programId: string;
	strapiExerciseId: number;
	name: string;
	weeklyReps: number;
	dailyReps: number;
	sets: number;
	reps: number;
	active: boolean;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	order: number;
	description: string;
	video: string;
	image: string;
}

export interface IProgram {
	id: string;
	userId: string;
	name: string;
	active: boolean;
	duration: number;
	durationType: string;
	startAt: string;
	finishAt: string;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	status: string;
	originType: string;
	programTemplateId: string | null;
	exercises: Exercise[];
}
export interface ProgramByIdList {
	data: IProgram[] | null;
	pagination: IPaginationPluginDefaultResponse | null;
}

export interface IExerciseLibary {
	id: string;
	physioterapistId: string;
	videoUrl: string;
	title: string;
	description: string;
	reps: number;
	sets: number;
	dailyReps: number;
	weeklyReps: number;
	visibility: boolean;
	processing: boolean;
	jobId: string;
	createdAt: string;
	updatedAt: string;
	active: true;
	rehabExerciseLibraryId: string;
}

export interface IStrapiExerciseProps {
	id: string;
	programId: string;
	strapiExerciseId: number;
	name: string;
	weeklyReps: number;
	dailyReps: number;
	sets: number;
	reps: number;
	active: boolean;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	order: number;
	strapiExercise: {
		id: number;
		attributes: IAttributes;
	};
}

export interface IAttributes {
	name: string;
	description: string;
	is_bilateral: boolean;
	recommended_duration: number | null;
	createdAt: string;
	updatedAt: string;
	publishedAt: string;
	locale: string;
	instructions: string;
	contraindications: string;
	therapist_notes: string;
	breathing: string;
	supportive_surfaces: string;
	alignment_tips: string;
	frequency: string;
	equipment_alternatives: string;
	precautions: string;
	functional_applications: string;
	assets: {
		id: number;
		gender: string;
		sideBody: string;
		video: {
			data: {
				id: number;
				attributes: {
					url: string;
				};
			};
		};
		image: {
			data: {
				id: number;
				attributes: {
					url: string;
				};
			};
		};
	}[];
	reasons: {
		data: {
			id: number;
			attributes: {
				name: string;
			};
		}[];
	};
}

export interface ProgramSessionResult {
	id: string;
	programSessionId: string;
	programExerciseId: string;
	video: string;
	processing: boolean;
	jobId: string;
	exerciseDifficultyLevel: string;
	createdAt: string;
	programExercise: IStrapiExerciseProps | IExerciseLibary;
}

export interface ProgramSession {
	id: string;
	programId: string;
	createdAt: string;
	overallCondition: string;
	painLevel: number;
	program: {
		id: string;
		userId: string;
		name: string;
		active: boolean;
		duration: number;
		durationType: string;
		startAt: string;
		finishAt: string;
		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
		status: string;
		originType: string;
		programTemplateId: string | null;
	};
	programSessionResult: ProgramSessionResult[];
}

export interface ProgramSessionByProgramId {
	data: ProgramSession[] | null;
	pagination: IPaginationPluginDefaultResponse | null;
}

export interface ProgramSessionResult {
	id: string;
	programSessionId: string;
	programExerciseId: string;
	video: string;
	processing: boolean;
	jobId: string;
	exerciseDifficultyLevel: string;
	createdAt: string;
}
export interface ProgramSessionResultResponse {
	data: ProgramSessionResult[] | null;
	pagination: IPaginationPluginDefaultResponse | null;
}
export interface IProgramTemplate {
	id: string;
	clientId?: string;
	name: string;
	duration: number;
	durationType: string;
	createdAt?: Date;
	startAt?: Date;
	updatedAt?: Date;
	deletedAt?: Date;
	exercises: IProgramExercise[];
	description?: string;
	thumbnail?: string | IThumbnailProps;
}

export interface IThumbnailProps {
	url: string;
}

export interface IProgramExerciseUpload {
	sessionId: string;
	user: IUser | null;
	exercises: IProgramExercise[];
	program: IProgramData | null;
	progress: number[];
}
export interface IProgramState {
	latestUpdatedProgram: IProgramData | null;
	programApproved: IProgramResponse;
	program: IProgramResponse;
	openAiProgram: {
		data: IProgramData | null;
		errorMessage: string;
		statusCode: number;
		loading: boolean;
	};
	carespaceAiProgram: {
		data: IProgramData | null;
		errorMessage: string;
		statusCode: number;
		loading: boolean;
	};
	programTemplate: IProgramTemplateResponse;
	programSummary: ProgramByIdList;
	programByIdList: ProgramSessionByProgramId;
	exerciseByProgram: ProgramSessionResultResponse;
	main: {
		sessionId: string;
		program: IProgramData | null;
		exercises: IProgramExercise[];
		completedExercises: IProgramExercise[];
		currentExercise: IProgramExercise | null;
		transitionTime: number;
		isAuto: boolean;
		uploadProgress: IProgramExerciseUpload[];
		isCompleted: boolean;
	};
	blockNavigation: {
		isBlocked: boolean;
		config: any;
		nextLocation: string | null;
		callBack: any;
	};
}

export interface IMovrPerformancePatientResult {
	createdAt: Date;
	id: string;
	jobId: string;
	movrPerformanceExercise: ILetsMovePerformanceExercises;
	movrPerformanceExerciseId: string;
	updatedAt: Date;
	userId: string;
	videoUrl: string;
}
export interface IMovrStateScore {
	bodyPoints: IMovrRomPatientResult[];
}
export interface IPerformance extends IPerformanceSaveUserProgress {
	id: string;
	createdAt: Date;
	updatedAt: Date;
}

export interface IPerformanceExercises extends ISetRepexercise {
	id: string;
	label: string;
	videoUrl: string;
	createdAt: Date;
	updatedAt: Date;
}

export interface IRehabCreateOrUpdateExerciseListSession {
	userId: string;
	patientResultsExercisesId?: string;
	rehabPatientListExercisesId?: string;
	rehabExerciseToPatientId?: string;
}

export interface IRehabExerciseEffort {
	id: string;
	name: string;
	createdAt: Date;
	updatedAt: Date;
}

export interface IRehabExerciseLibrary extends ISetRepexercise, IProcessId {
	physioterapistId: string;
	videoUrl: string;
	title: string;
	description: string;
	visible: boolean;
	export: boolean;
	active: boolean;
}

export interface IRehabPagination extends IDefaultPagination {
	title?: string;
}

export interface IRehabExerciseLibraryPaginationReturn {
	videos: IRehabExerciseLibrary[];
	pagination: IPaginationDefaultResponse;
}
interface ISetRepexercise {
	frequencyPerWeek?: number;
	repetitions?: number;
	setsPerDay?: number;
	setsPerSession?: number;
}
export interface IRehabExerciseLibraryUpdate extends ISetRepexercise {
	ids: string[];
	title?: string;
	description?: string;
}

export interface IRehabExerciseListSession {
	id: string;
	rehabPatientListExercises?: IRehabPatientListExercise;
	rehabPatientListExercisesId: string;
	patientResultsExercises?: IRehabPatientResultExercise[];
	rehabEvaluation?: IRehabPatientEvaluation;
	rehabEvaluationId?: string;
	status: string;
	painLevel?: number;
	overallCondition?: string;
	newInjury?: boolean;
	createdAt: Date;
	updatedAt: Date;
}

export interface ISurveyQuestionOptions {
	id?: string;
	option: string;
	score: number;
	questionId?: string;
	order?: number;
}

export interface ISurveyQuestion {
	deletedOptionList: never[];
	id: string;
	title: string;
	description: string;
	ratingOptions: string[];
	questionType: string;
	surveyId: string | null;
	optionList?: ISurveyQuestionOptions[];
	surveyTemplateId: string | null;
	order: number;
}
export interface SurveyQuestionItemProps {
	survey: ISurvey;
	question: QuestionListPayload;
	index: number;
	handleQuestionDelete: (index: number) => void;
	updateOptionByIndex: (
		index: number,
		option: ISurveyQuestionOptions[],
	) => void;
	addOptionsByIndex: (index: number, option: ISurveyQuestionOptions) => void;
	deleteOptionByIndex: (index: number, optionIndex: number) => void;
	addRatingOptionByIndex: (index: number, ratingOptions: string[]) => void;
	handleQuestionTitle: (index: number, title: string) => void;
	updateOptionTitle: (
		index: number,
		optionIndex: number,
		title: string,
		score: number,
	) => void;
}

export interface ISurveyOpenItemProps {
	isQuestionEdit: boolean;
	questionTitle: string;
	setQuestionTitle: (value: string) => void;
	setQuestionEdit: (value: boolean) => void;
	question: QuestionListPayload;
	validateQuestion: () => void;
	handleQuestionDelete: (index: number) => void;
	index: number;
}

export interface ISurveyRateDataProps {
	survey: ISurvey;
	question: QuestionListPayload;
	isEdit: boolean;
	ratingOptions: ISurveyQuestionOptions[];
	handleDragEnd: (event: {
		active: { id: string };
		over: { id: string };
	}) => void;
	index: number;
	setIsEdit: (value: boolean) => void;
	handleInputChange: (index: number, value: string) => void;
	handleScoreChange: (index: number, score: number) => void;
	updateOptionTitle: (
		index: number,
		optionIndex: number,
		title: string,
		score: number,
	) => void;
	addOptionsByIndex: (index: number, option: ISurveyQuestionOptions) => void;
	deleteOptionByIndex: (index: number, optionIndex: number) => void;
	addRatingOptionByIndex: (index: number, ratingOptions: string[]) => void;
	updateOptionByIndex: (
		index: number,
		option: ISurveyQuestionOptions[],
	) => void;
	handleDeleteRate: (index: number) => void;
	handleReset: () => void;
}

export interface ISurvey {
	id: string;
	userId: string;
	clinicallyValidated: boolean;
	image: string;
	title: string;
	description: string;
	instructions: string;
	resultFeedback: string;
	active: boolean;
	startAt: Date | null;
	finishAt: Date | null;
	createdAt: Date;
	updatedAt: Date;
	deletedAt: Date | null;
	status: string;
	originType: string;
	isFinished: boolean;
	questionList?: ISurveyQuestion[];
	surveyTemplateId: string | null;
}

export interface ISurveyTemplate {
	id: string;
	userId: string;
	image: string;
	title: string;
	description: string;
	instructions: string;
	resultFeedback: string;
	createdAt: Date;
	updatedAt: Date;
	deletedAt: Date | null;
	originType: string;
	surveyTemplateQuestion?: ISurveyQuestion[];
	surveyTemplateId: string | null;
}

export interface ISurveyPaginated {
	data: ISurvey[] | null;
	pagination: IPaginationPluginDefaultResponse | null;
}

export interface ISurveyTemplatePaginated {
	data: ISurveyTemplate[] | null;
	pagination: IPaginationPluginDefaultResponse | null;
}

export interface ISurveyResultPaginated {
	data: ISurveyResult[] | null;
	pagination: IPaginationPluginDefaultResponse | null;
}

export interface ISurveyResponse {
	survey: ISurveyPaginated;
	surveyTemplate: {
		myTemplate: {
			data: ISurvey[] | null;
			pagination: IPaginationPluginDefaultResponse | null;
		};
		systemTemplate: {
			data: ISurvey[] | null;
			pagination: IPaginationPluginDefaultResponse | null;
		};
		clinicalTemplate: {
			data: ISurvey[] | null;
			pagination: IPaginationPluginDefaultResponse | null;
		};
	};
	surveyResult: ISurveyResultPaginated;
}

export interface ISurveyQuestion {
	id: string;
	surveyResultId: string;
	questionType: string;
	ratingLevel: number;
	selectedAnswer: string | null;
	score: number | null;
	question: string;
	questionDescription: string;
	answerList: string[];
}

export interface ISurveyResult {
	id: string;
	title: string;
	description: string;
	userId: string;
	reportsId: string;
	createdAt: string;
	updatedAt: string;
	questionList: ISurveyQuestion[];
}

export interface ISurveyResultQuestionList {
	id?: string;
	surveyResult?: ISurveyResult;
	surveyResultId?: string;
	questionType: string;
	ratingLevel?: number | null;
	selectedAnswer?: string | null;
	score?: number | null;
	question: string;
	questionDescription: string;
	answerList?: string[];
}

export interface IRehabExerciseRequestParam extends IDefaultPagination {
	patientId: string;
}

export interface IRehabExerciseListSessionPaginated {
	data: IRehabExerciseListSession[];
	pagination: IPaginationPluginDefaultResponse;
}

export interface IRehabListSessionParam extends IDefaultPagination {
	open?: string;
}

export interface IRehabExerciseToPatient extends ISetRepexercise {
	id: string;
	rehabExercisesLibrary?: IRehabExerciseLibrary;
	rehabExercisesLibraryId?: string;
	strapiExerciseId?: number;
	strapiExercise?: IStrapiExercise;
	bodySide?: string;
	active: boolean;
	createdAt: Date;
	updatedAt: Date;
}

export interface IRehabExerciseEffort {
	createdAt: Date;
	id: string;
	name: string;
	updatedAt: Date;
}

export interface IRehabPatientEvaluation {
	id: string;
	painLevel?: number;
	progress?: number;
	rehabExerciseEffortId: string;
	rehabPatientListExercisesId: string;
	rehabExerciseEffort: IRehabExerciseEffort;
	createdAt: Date;
	updatedAt: Date;
}

export interface IPatientListExerciseQueryParams {
	all?: string;
}

export interface IRehabPatientListExercise {
	id: string;
	userId: string;
	user?: IUser;
	physioterapistId: string;
	title: string;
	rehabExerciseToPatient?: IRehabExerciseToPatient[];
	createdAt: Date;
	updatedAt: Date;
}

export interface IRehabPatientResultExercise extends IProcessId {
	rehabExerciseToPatient?: IRehabExerciseToPatient;
	rehabExerciseToPatientId: string;
	video: string;
	RehabExerciseListSession?: IRehabExerciseListSession;
	rehabExerciseListSessionId: string;
	exerciseDifficultyLevel?: number;
}

export interface IRehabRequestEnrollmentResponsePaginatedByUser {
	data: Partial<IUser>[];
	pagination: IPaginationPluginDefaultResponse;
}

export interface IRehabSessionResponsePaginated {
	data: IUserWithSession[];
	pagination: IPaginationPluginDefaultResponse;
}

interface IAssetsAttributes {
	url: string;
}

interface IAssetsData {
	id: number;
	attributes: IAssetsAttributes;
}

interface IAssets {
	data: IAssetsData;
}

interface IStrapiExerciseAssets {
	id: number;
	gender: string;
	sideBody: string;
	video: IAssets;
	image: IAssets;
}

export interface IStrapiExercise extends IPrescribeStrpiExe {
	assets: IStrapiExerciseAssets[];
	video?: string;
	image?: string;
}

export interface IStrapiApiExercise {
	id: number;
	attributes: IStrapiExercise;
}

export interface IStrapiExercisePaginated {
	videos: IStrapiExercise[];
	pagination: IStrapiPagination;
}

interface UserToolStatus {
	id: string;
	user?: IUser;
	userId: string;
	status: UserToolStatusEnum;
	active: boolean;
	createdAt: Date;
	updatedAt: Date;
}

export interface IRomBodyPoint extends IRomPainFrequency {
	romStyle: IBodyPointsStyle;
	romPosition: string;
}

export interface IRomExercise extends IExerciseDetails {
	romExerciseGroupId?: string;
	result?: number;
	screenshot?: string;
}

type Styles = {
	[key: string]: string;
};

export interface IRomExerciseGroup extends IRomPainFrequency {
	side: string;
	romExercise: IRomExercise[];
	styles: Styles;
}

export interface IRomPainFrequency {
	id: string;
	name: string;
	createdAt: Date;
	updatedAt: Date;
}

export type IRomPainStart = IRomPainFrequency;

export enum ExerciseKey {
	lShoulder = 'lShoulder',
	lElbow = 'lElbow',
	rElbow = 'rElbow',
	rShoulder = 'rShoulder',
	rShoulderInternal = 'rShoulderInternal',
	rShoulderExternal = 'rShoulderExternal',
	lShoulderInternal = 'lShoulderInternal',
	lShoulderExternal = 'lShoulderExternal',
	legs = 'legs',
	lHip = 'lHip',
	rHip = 'rHip',
	rKnee = 'rKnee',
	lKnee = 'lKnee',
	lHipInternal = 'lHipInternal',
	rHipInternal = 'rHipInternal',
	lHipExternal = 'lHipExternal',
	rHipExternal = 'rHipExternal',
}

export interface IRomPatientResult {
	id: string;
	userId: string;
	romExerciseId: string;
	value: number;
	screenshot: string;
	outOfRange?: boolean;
	createdAt: Date;
	updatedAt: Date;
	romProgramExercise: ICustomRomBodyPoints;
	strapiOmniRomExercise: {
		name: string;
	};
}

export interface IRomSession {
	id: string;
	userId: string;
	bodyRegion: BodyRegion;
	romPatientResults?: IRomPatientResult[];
	completed: boolean;
	status: Status;
	strapiOmniRomExerciseGroupId: number;
	strapiOmniRomExerciseGroup: StrapiOmniRomExerciseGroup;
	createdAt: Date;
	updatedAt: Date;
}

export interface IRomSession {
	id: string;
	userId: string;
	bodyRegion: BodyRegion;
	romPatientResults?: IRomPatientResult[];
	completed: boolean;
	status: Status;
	strapiOmniRomExerciseGroupId: number;
	strapiOmniRomExerciseGroup: StrapiOmniRomExerciseGroup;
	createdAt: Date;
	updatedAt: Date;
}

export interface IRomSessionResponsePaginated {
	data: IRomSession[];
	pagination: IPaginationPluginDefaultResponse;
}

export interface IRomUserSessionResponsePaginated {
	data: IUserWithSession[];
	pagination: IPaginationPluginDefaultResponse;
}

export interface IUserFunctionalGoal {
	id: string;
	userId: string;
	functionalGoalIds?: number[];
	active: boolean;
	createdAt: Date;
	updatedAt: Date;
}

export enum UserRole {
	user = 'user',
	admin = 'admin',
}

interface UserProfile {
	email: string;
	firstName?: string;
	fullName?: string;
	lastName?: string;
	imageUrl?: string;
	preferredLanguages?: string[];
	birthDate?: string;
	role: UserRole;
	gender?: string;
	isPregnant?: boolean;
	height?: number;
	weight?: number;
	consentPolicyRead?: boolean;
	patientId?: string;
	avatarColor: string;
}
interface IDBUser {
	id: string;
	clientId: string;
	fusionAuthUserId: string;
	client: IClient;
	active: boolean;
	createdAt?: Date;
	updatedAt?: Date;
	profile?: UserProfile;

	functionalGoals?: IUserFunctionalGoal[];
	userToolStatus?: UserToolStatus[];
}

export interface IProfileData {
	active: boolean;
	birthDate: string;
	data: userData;
	email: string;
	firstName: string;
	fullName: string;
	id: string;
	lastName: string;
	fusionAuthUserId: string;
	createdAt: string;
	preferredLanguages?: string[];
	functionalGoals: FunctionalGoal[] | undefined;
	role: string;
}

interface userData {
	email: string;
	imageUrl: string;
	firstName: string;
	lastName: string;
	fullName: string;
	consentPolicyRead: boolean;
	gender: string;
	height: number;
	imperialHeight: number;
	imperialWeight: number;
	isPregnant: boolean;
	metricHeight: number;
	metricWeight: number;
	patientId: string;
	weight: number;
	avatarColor: string;
}

interface FunctionalGoal {
	id: string;
	userId: string;
	functionalGoalsIds: number[];
	active: boolean;
	createdAt: string;
	updatedAt: string;
}

export interface Physiotherapist {
	id: string;
	clientId: string;
	fusionAuthUserId: string;
	createdAt: string;
	updatedAt: string;
	active: boolean;
	profile: ProfileAdmin;
}

export interface PhysiotherapistPatientAssociation {
	id: string;
	physiotherapistId: string;
	patientId: string;
	showPopup: boolean;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	physiotherapist: Physiotherapist;
}

export interface IUser extends IDBUser {
	isPhysioterapist: boolean;
	active: boolean;
	id: string;
	fusionAuthUserId: string;
	physiotherapistPatientAssociationPatientIdRelation: PhysiotherapistPatientAssociation[];
}
interface ILibExercise {
	visible?: boolean;
	default?: boolean;
	physioterapistId: string;
	title: string;
	description?: string;
}
export interface IRehabCreateLibraryExercises
	extends ISetRepexercise,
		ILibExercise {
	get: (value: string) => string;
	video: string;
	userId?: string;
}
type Body = {
	libraryId: string;
	repetitions: number;
	setsPerSession: number;
	setsPerDay: number;
	frequencyPerWeek: number;
};
export interface ISavePhysioterapistVideoToPatient {
	userId: string;
	body: Body;
}

type Pagination = {
	currentPage: number;
	from: number;
	pageCount: number;
	perPage: number;
	to: number;
	total: number;
};
interface IProcessId {
	processing: boolean;
	createdAt: Date;
	updatedAt: Date;
	id: string;
}
export interface IPersonalExercise
	extends Partial<ISetRepexercise>,
		ILibExercise,
		IProcessId {
	videoUrl: string;
	jobId: string;
	active: true;
}

interface IPersonalLibrary {
	videos: IPersonalExercise[];
	pagination: Pagination;
}

export interface IStrapiExercises extends IPrescribeStrpiExe {
	assets: IStrapiExerciseAssets[];
}

export interface IStrapiPagination {
	page: number;
	pageSize: number;
	pageCount: number;
	total: number;
}

export interface IGeneralLibrary {
	videos?: IStrapiExercises[];
	exercises: IStrapiExercises[];
	pagination: IStrapiPagination;
}

export interface ISelectedExercise extends Partial<ISetRepexercise> {
	libraryId?: string;
	strapiExerciseId?: number;
	bodySide?: string;
	name?: string;
	description?: string;
	videoUrl?: string;
}

export interface IRehabInitialState {
	personal: IPersonalLibrary;
	general: IGeneralLibrary;
	noExercises: boolean;
	videoSettings: ISetRepexercise;
	selectedExercises: ISelectedExercise[];
	errorMessages: [];
	complete: boolean;
}
export interface IRehabCreatePatientResultExercise {
	userId: string;
	rehabPatientListExerciseId: string;
	rehabExerciseToPatientId: string;
	video: string;
	exerciseDifficultyLevel?: string;
}
export type IRehabCreatePatientEvaluation = IRehabCreatePatientResultExercise;
export interface IRehabExercisesLibrary
	extends IRehabCreateLibraryExercises,
		IProcessId {
	active: boolean;
	jobId: string;
	videoUrl: string;
}

export interface IRehabPatientResultsPaginated {
	data: IRehabPatientResultExercise[];
	pagination: IPaginationPluginDefaultResponse;
}

export interface INewExercisesResults {
	find(arg0: (itemData: INewExercisesResults) => boolean): INewExercisesResults;
	createdAt: Date;
	exerciseEffort: string;
	id: string;
	painLevel: string;
	patientResultsExercises: IRehabPatientResultExercise[];
	progress: string;
	rehabEvaluation: IRehabPatientEvaluation | null;
	rehabEvaluationId: string;
	rehabPatientListExercisesId: string;
	updatedAt: Date;
}
export interface IExercisesSelectedRows {
	length: number;
	map(arg0: (item: any, index: any) => JSX.Element): React.ReactNode;
	createdAt: Date;
	id: string;
	patientResultsExercises: IRehabCreatePatientResultExercise;
	rehabEvaluation: IRehabPatientEvaluation;
	rehabEvaluationId: string;
	rehabPatientListExercisesId: string;
	updatedAt: Date;
}
export interface ICreatePatientResult {
	userId: string;
	romExerciseId: string;
	value: number;
	screenshot: string;
}
type RangeDate = {
	start: string;
	end: string;
};

export interface IGetPatientResults {
	userId: string;
	exerciseId: string;
	rangeDate: RangeDate;
}
export interface IRomPainAssessment extends IRomCreatePainAssessment {
	find(arg0: (itemData: IRomPainAssessment) => boolean): IRomPainAssessment;
	createdAt: Date;
	updatedAt: Date;
	romBodyPoint?: IRomBodyPoint;
	romPainFrequency?: IRomPainFrequency;
	romPainStart?: IRomPainStart;
}
export interface IPainScale {
	whenPainStart: string;
	painCause: string;
	painFrequencies: string;
	painLevel: number;
	relievePainLevel: string;
}
export interface IRomPainAssessmentInitialState
	extends IPainAssessmentsHelpers {
	selectedBodyPoint: IRomBodyPoint;
	bodyPointsSaved: IRomBodyPoint[];
	bodyPointsFromLastSession: IRomBodyPoint[];
	preAssesmentsSaved: number;
	painScale: IPainScale;
}
export interface IRomCreatePainAssessment {
	id?: string;
	userId: string;
	romBodyPointId: string;
	romPainStartId: string;
	romPainFrequencyId: string;
	painCause: string;
	painLevel: number;
	relievePainLevel: string;
}
export interface IPainAssessmentsHelpers {
	bodyPoints: IRomBodyPoint[];
	painFrequencies: IRomPainFrequency[];
	painStart: IRomPainStart[];
}

interface IBodyPointsStyle {
	top?: string;
	left?: string;
	right?: string;
	bottom?: string;
}

export enum Side {
	LEFT = 'left',
	RIGHT = 'right',
}
export interface IMovrRomExercise extends IExerciseDetails {
	movrId: number;
	movrExerciseGroup?: IMovrExercise;
	movrExerciseGroupId?: string;
	result?: number;
	screenshot?: string;
}

export interface IMovrRomCreatePatientResult {
	userId: string;
	movrExerciseId: string;
	value: string;
	screenshot: string;
}
type Tutorial = {
	watched: string | null | boolean;
	isOpened: boolean;
};

type Splash = {
	isOpened: boolean;
};

type Image = {
	id: string;
	exercise: string;
	src: string;
	thumbnail: string;
	thumbnailWidth: number;
	thumbnailHeight: number;
	isSelected: boolean;
	date: string;
	result: number;
};

type Gallery = {
	isOpened: boolean;
	images: Image[];
	imagesToCompare: Image[];
	rangeDate: RangeDate;
};

type Progress = {
	total: number;
	completed: number;
	current: number;
};

type Assessment = {
	isOpened: boolean;
};

type Menu = {
	isOpened: boolean;
};

type FinishedExercises = {
	finished: boolean;
	isOpened: boolean;
};

type Pose = {
	angleResult: number;
	angleResults: number[];
	coordinates: NormalizedLandmark[];
	multiCoordinates: NormalizedLandmark[][];
};
interface IGeneralRomInitialState {
	user: IUser | null;
	goToExercise: null;
	pose: Pose;
	progress: Progress;
	gallery: Gallery;
	tutorial: Tutorial;
	splash: Splash;
	assessment: Assessment;
	menu: Menu;
	paused: boolean;
	finishedExercises: FinishedExercises;
	loading: boolean;
	isRepetingExercise: boolean;
	selfieMode: boolean;
	metricsData: CaptureData;
	enableToSendResult: boolean;
	waitSavedPhysicalAssessmentsMetrics: null;
	resultIndex: number | null;
	incorrectSide: boolean;
	facingMode: FacingMode;
	cameraId: string | null;
	bodyRegion: BodyRegion | null;
}
export interface IMovrRomInitialState extends IGeneralRomInitialState {
	exercises: IMovrRomExercise[];
	currentExercise: IMovrRomExercise | null;
	sequence: [];
	bodyPartsNotVisible: boolean;
	currentTransition: number;
	resultsExercises: IMovrRomExercise;
	resultExerciseValidation: IMovrRomExercise | null;
}
export interface IMovrRomPatientResult {
	id: string;
	userId: string;
	movrExerciseId: string;
	movrProgramId: string;
	value: number;
	screenshot: string;
	createdAt: Date;
	updatedAt: Date;
	movrExercise: IMovrRomExercise;
}
export interface IMovrPerformanceCreatePatientResult {
	video: File;
	userId: string;
	name: string;
	videoUrl: string;
	imageUrl: string;
	movrExerciseId: string;
}

interface IGeneralPerformanceInitialState {
	user: IUser | null;
	isMenuOpened: boolean;
	isHiddenMenu: boolean;
	shouldRedirect: boolean;
	isInvertVideo: boolean;
	progress: Progress;
}
export interface IMovrPerformanceInitialState
	extends IGeneralPerformanceInitialState {
	completed: boolean;
	physioterapist: null;
	isPhysioterapist: boolean;
	exercises: ILetsMovePerformanceExercises[];
	exercisesCompleted: ILetsMovePerformanceExercises[];
	painLevel: number;
	evaluationProgress: number;
	currentExercise: ILetsMovePerformanceExercises | null;
	isInvertCam: boolean;
	facingMode: string;
	continueWhereLeftOff: boolean;
	session: null;
}
export interface IPerformanceSaveUserProgress {
	userId: string;
	performanceExerciseId: string;
	time: number;
}

type AspectArea = {
	width: number;
	height: number;
};

type ExerciseProgress = {
	total: number;
	totalSets: number;
	current: number;
	sets: number;
};

export interface IPerformanceInitialState
	extends IGeneralPerformanceInitialState {
	exercises: IPerformanceExercises[];
	exercisesCompleted: [];
	currentExercise: IPerformanceExercises;
	exerciseProgress: ExerciseProgress;
	complete: boolean;
	aspectArea: AspectArea;
}
export interface ICoachPerformanceInitialState {
	coach: ICoachPerformance | null;
	exercises: ICoachPerformanceExercises | null;
	result: {};
}
export type ICoachPerformance = IPerformance;

export interface ICoachPerformanceExercises extends ISetRepexercise {
	id: string;
	label: string;
	videoUrl: string;
	createdAt: Date;
	updatedAt: Date;
}
export interface ICoachRehabInitialState {
	coach: ICoachRehabCoach | null;
	exercises: ICoachRehabExercises | null;
	result: [];
}
export interface ICoachRehabCoach {
	id: string;
	userId: string;
	rehabExerciseId: string;
	time: number;
	createdAt: Date;
	updatedAt: Date;
}
export interface ICoachRehabExercises extends ISetRepexercise {
	id: string;
	label: string;
	videoUrl: string;
	createdAt: Date;
	updatedAt: Date;
}
export interface ICoachRomInitialState {
	coach: ICoachRomCoach | null;
	exercises: ICoachRomExercises | null;
	result: [];
}
export type ICoachRomCoach = ICoachRehabCoach;
export type ICoachRomExercises = ICoachRehabExercises;
type PatientResultsExercises = {
	id: string;
	rehabExerciseToPatientId: string;
	video: string;
	processing: boolean;
	jobId: string;
	createdAt: Date;
	updatedAt: Date;
	rehabExerciseListSessionId: string;
	rehabExerciseToPatient: IContactsRehabExerciseToPatient;
};
export interface IContactsSessions {
	id: string;
	rehabPatientListExercisesId: string;
	rehabEvaluationId: null;
	createdAt: Date;
	updatedAt: Date;
	rehabEvaluation: null;
	patientResultsExercises: PatientResultsExercises;
}
export type IContactsExercises = IExerciseDetails;

export interface IPagination extends IDefaultPagination {
	totalDocs: number;
	offset: number;
	totalPages: number;
	pagingCounter: number;
	hasPrevPage: boolean;
	hasNextPage: boolean;
	prevPage: number | null;
	nextPage: number;
}
export interface IUsers {
	active: boolean;
	email: string;
	firstName: string;
	fullName: string;
	fusionAuthUserId: string;
	id: string;
}
export interface IRehabAssignedExercisesForPatient {
	id: string;
	userId: string;
	physioterapistId: string;
	title: string;
	createdAt: Date;
	updatedAt: Date;
	rehabExerciseToPatient: IContactsRehabExerciseToPatient;
}
type RehabExercisesLibrary = {
	id: string;
	physioterapistId: string;
	videoUrl: string;
	title: string;
	description: string;
	repetitions: number;
	setsPerSession: number;
	setsPerDay: number;
	frequencyPerWeek: number;
	visible: boolean;
	default: false;
	processing: false;
	jobId: string;
	createdAt: Date;
	updatedAt: Date;
	active: boolean;
};
export interface IContactsRehabExerciseToPatient extends ISetRepexercise {
	id: string;
	rehabExercisesLibraryId: string;
	active: boolean;
	createdAt: Date;
	updatedAt: Date;
	rehabPatientListExercisesId: string;
	rehabExercisesLibrary: RehabExercisesLibrary;
}
export type SelectedUser = {
	active: boolean;
	profile: {
		email: string;
		firstName: string;
		lastName: string;
		fullName: string;
	};
	id: string;
	fusionAuthUserId: string;
};

type Galery = {
	exerciseImages: [];
	images: [];
	imagesToCompare: [];
};

type Performance = {
	exercises: [];
	results: [];
};

type CreateAccount = {
	status: boolean;
	fieldErrors: [];
};
export interface IContactsInitialState {
	userDropDownList: IUsers[] | null;
	users: IUsers[] | null;
	selectedUsers: IUsers[] | null;
	rehabSelectedUsers: [];
	backList: boolean;
	rehabAction: null;
	selectedUser: SelectedUser | null;
	pagination: IPagination;
	contacts: [];
	isContactsListLoaded: boolean;
	wasActionExecuted: boolean | null;
	coach: IContactsRomPatientsResults | null;
	exercises: StrapiOmniRomExercises[] | null;
	// exercises: any
	preAssessment: [];
	weeklyAssessment: [];
	gallery: Galery;
	coachUserExercisesResults: [];
	performance: Performance;
	createAccount: CreateAccount;
	startUserSession: boolean;
	rehabExercisesFromUser: boolean;
	rehabPatientExercises: [];
	rehabPatientEvaluations: [];
	rehabAssignedExercisesForPatient: IRehabAssignedExercisesForPatient | null;
	sessions: IContactsSessions | null;
	rehabSessions: IContactsSessions | null;
	graphSessionData: IGraphSessionData | null;
	ptDashboard: {
		userId: string;
		activeTab: string;
	};
}
export interface IContactsRomPatientsResults {
	createdAt: Date;
	id: string;
	romExercise: IContactsExercises;
	romExerciseId: string;
	screenshot: string;
	updatedAt: Date;
	userId: string;
	value: number;
}

export interface TStrapiBodyPointAttributes extends TStrapiAttributes {
	position: string;
}

export interface TStrapiBodyPoint {
	id: number;
	attributes: TStrapiBodyPointAttributes;
}
export interface TStrapiAttributes {
	name: string;
	locale: string;
}
export interface TStrapiFactors {
	id: number;
	attributes: TStrapiAttributes;
}

export interface TPainAssessmentProps extends IIds {
	evaluationSessionId: string;
	createdAt: Date;
	id: string;
	userId: string;
	notes: string;
	painLevel: string;
	strapiAggravatingFactors: TStrapiFactors[];
	strapiBodyPoint: TStrapiBodyPoint;
	strapiPainCauses: TStrapiFactors[];
	strapiPainDuration: TStrapiFactors;
	strapiPainFrequency: TStrapiFactors;
	strapiPainStatus: TStrapiFactors;
	strapiRelievingFactors: TStrapiFactors[];
	updatedAt: Date;
}

export type TStrapiMedicalHistoryProps = TStrapiAttributes;

export interface TStrapiMedicalHistories {
	id: number;
	attributes: TStrapiMedicalHistoryProps;
}

export interface TMedicalHistoryProps {
	createdAt: Date;
	evaluationSessionId: string;
	id: string;
	notes: string;
	strapiMedicalHistories: TStrapiMedicalHistories[];
	strapiMedicalHistoriesIds: number[];
}

export type TStrapiHealthSignsAttributes = TStrapiAttributes;

export interface TStrapiHealthSigns {
	id: number;
	attributes: TStrapiHealthSignsAttributes;
}

export interface THealthSignsProps {
	createdAt: Date;
	evaluationSessionId: string;
	id: string;
	notes: string;
	strapiHealthSigns: TStrapiHealthSigns[];
	strapiHealthSignsIds: number[];
}

export interface TDataProps {
	active: boolean;
	createdAt: Date;
	healthSigns: THealthSignsProps;
	medicalHistories: TMedicalHistoryProps;
	painAssessments: TPainAssessmentProps[];
	updatedAt: Date;
	userId: string;
	id: string;
	isSelected?: boolean;
}

export interface TCoachSummary {
	data: TDataProps[];
	pagination: IPaginationDefaultResponse;
}

export interface ROMExercise extends IExerciseDetails {
	romExerciseGroupId: string;
}

export interface ROMPatientResult extends IRomPatientResult {
	romSessionId: string;
	strapiOmniRomExercise: StrapiOmniRomExercises;

	strapiOmniRomExerciseId?: number;
}

export interface UserExerciseResult extends IGeneralDetails {
	romPatientResults: GroupedData;
}
export interface GroupedData {
	leftShoulder: ROMPatientResult[];
	rightShoulder: ROMPatientResult[];
	leftHip: ROMPatientResult[];
	rightHip: ROMPatientResult[];
	leftElbow: ROMPatientResult[];
	rightElbow: ROMPatientResult[];
	leftKnee: ROMPatientResult[];
	rightKnee: ROMPatientResult[];
	squat: ROMPatientResult[];
}

export interface PositionList {
	name: string;
	key: string;
}

export interface RomeRequestParam extends IDefaultPagination {
	patientId: string;
}

export interface IOnchangeStatus {
	sessionId: string;
	status: Status;
}

export interface IstatusIcon {
	outOfParams: React.ReactNode;
	pendingReview: React.ReactNode;
	reviewed: React.ReactNode;
	escalationRequired: React.ReactNode;
	followUpRequired: React.ReactNode;
}
export interface IMovrStateResults {
	groups: IMovrExercise[] | null;
	resultsExercises: IMovrRomPatientResult[] | null;
	resetExercisesLoading: boolean;
}

export interface ResultExerciseValidation extends StrapiOmniRomExercises {
	result?: number;
	results?: number[];
	screenshot: string;
}

export enum ETransitions {
	INTRO = 'intro',
	CALIBRATION = 'calibration',
	READYSETGO = 'readySetGo',
	CLOSING = 'closing',
	RESULT = 'result',
	OPENNING = 'openning',
}
export type TTransitions = {
	next: TTransitions | null;
	value: ETransitions;
};
export interface IRomInitialState extends IGeneralRomInitialState {
	exercises: ICustomRomExercise[] | null;
	uploadProgress: number;
	currentExercise: ICustomRomExercise | null;
	transition: TTransitions | null;
	bodyPointsVisible: boolean;
	resultsExercises: ICustomRomExercise[];
	loading: boolean;
	isRepetingExercise: boolean;
	aspectArea: AspectArea;
	resultExerciseValidation: ResultExerciseValidation | null;
	session: ISession | null;
	omniRomExerciseGroups: StrapiOmniRomExerciseGroup[] | null;
	strapiOmniRomExerciseGroupId: string | number | null;
	fetchFullScanExercise: ICustomRomExercise[] | null;
	isCustom: boolean;
}

export interface rehabExercisesLibraryProp
	extends ISetRepexercise,
		ILibExercise,
		IProcessId {
	videoUrl: string;
	jobId: string;
	active: boolean;
}

export interface rehabExerciseToPatientProp extends ISetRepexercise {
	id: string;
	rehabExercisesLibraryId: string;
	strapiExerciseId: string;
	bodySide: string;
	active: boolean;
	createdAt: Date;
	updatedAt: Date;
	rehabPatientListExercisesId: string;
	rehabExercisesLibrary: rehabExercisesLibraryProp[] | [];
}

export interface exercisePropType {
	id?: string;
	userId?: string;
	physioterapistId?: string;
	title?: string;
	createdAt?: Date;
	updatedAt?: Date;
	rehabExerciseToPatient?: rehabExerciseToPatientProp[] | [];
}

export interface INewUserPaginated {
	data: INewUserData[];
	pagination: IUserPaginationDefaultResponse;
}
export interface INewUserData {
	active: boolean;
	email: string;
	firstName: string;
	id: string;
	lastName: string;
	preferredLanguages?: string[];
	fusionAuthUserId: string;
	userToolStatus: UserToolStatus[];
}
export interface ISaveVideoToRehab {
	strapiExerciseId: number;
	repetitions: number;
	setsPerSession: number;
	setsPerDay: number;
	frequencyPerWeek: number;
}
export interface ISavePhysioterapistVideoToPatient {
	userId: string;
	body: ISaveVideoToRehab[];
}

export interface IRehabPreAssesment {
	userId: string;
	painLevel: number;
	overallCondition: string;
	newInjury: boolean;
	rehabPatientListExercisesId: string;
}

export interface IProgramPreAssesment {
	programId: string;
	painLevel: number;
	overallCondition: string;
}

export interface ICustomModalProps {
	name?: string | null;
	description?: string | null;
	video?: string | null;
}

export interface IGraphSessionData {
	data: IRehabPatientResultExercise[];
	pagination: IPaginationPluginDefaultResponse | null;
}

export interface IGraphSessionRequestParam {
	patientId: string;
	rehabExerciseToPatientId: string;
}

export interface ActivityStreamPost {
	id: string;
	userId: string;
	activityStreamId: string;
	physioterapistId: string;
	message: string;
	createdAt: string;
	updatedAt: string;
	activityStreamPostMedia: IActivitySTreamPostMedia[];
}
export interface IActivitySTreamPostMedia {
	id: string;
	activityStreamPostId: string;
	image: string | null;
	video: string | null;
	jobId: string;
	processing: boolean;
	createdAt: string;
	updatedAt: string;
}

export interface ActivityStream {
	id: string;
	userId: string;
	romSessionId: string | null;
	programSessionId: string | null;
	programId: string | null;
	evaluationSessionId: string;
	rehabSessionId: string | null;
	surveyResultId: string | null;
	surveyId: string | null;
	createdAt: string;
	updatedAt: string;
}

export interface IActivityStreamData {
	id: string;
	userId: string;
	activityStreamId: string | null;
	activityStreamFeedbackId: string | null;
	activityStreamEvaluationId: string | null;
	activityStreamPostId: string | null;
	createdAt: string;
	activityStream: ActivityStream | null;
	activityStreamFeedback: string | null;
	activityStreamEvaluation: string | null;
	activityStreamPost: ActivityStreamPost | null;
	physioterapistId: string;
}

export interface EvaluationItem {
	title: string;
	subtitle: string;
	data: IActivityStreamData;
	value: any;
}

export enum AiAssistantKeys {
	home = 'home',
	activity = 'activity',
	virtualEvaluation = 'virtualEvaluation',
	startScan = 'startScan',
	exercises = 'exercises',
	startSession = 'startSession',
	myReport = 'myReport',
	createReport = 'createReport',
	listEvaluation = 'listEvaluation',
	romSummary = 'romSummary',
	captures = 'captures',
	listSessions = 'listSessions',
	programs = 'programs',
	startSurvey = 'startSurvey',
	newPatients = 'newPatients',
	pendingReview = 'pendingReview',
	outOfParams = 'outOfParams',
	reviewed = 'reviewed',
	escalationRequired = 'escalationRequired',
	followUpRequired = 'followUpRequired',
	noComponentFound = 'noComponentFound',
	notUnderstandQuestion = 'notUnderstandQuestion',
	surveySummary = 'surveySummary',
	startSurveyUser = 'startSurveyUser',
	generateProgram = 'generateProgram',
	createSurvey = 'createSurvey',
	assignSurvey = 'assignSurvey',
	program = 'program',
	postureScan = 'postureScan',
	startRomScan = 'startRomScan',
	postureCaptures = 'postureCaptures',
	postureSummary = 'postureSummary',
}

export interface StrapiOmniRomExercises {
	id: number;
	createdAt?: string;
	updatedAt?: string;
	publishedAt?: string;
	name?: string;
	title?: string;
	order?: number;
	function?: string;
	description?: any;
	transitionTime?: number;
	locale?: string;
	video?: StrapiOmniRomExercisesVideo;
	pointsToCalculateAngle?: StrapiOmniPointsToCalculateAngle;
	pointsToValidatePosition?: StrapiOmniPointsToCalculateAngle;
	omniRomExerciseGroups?: StrapiOmniRomExerciseGroup[];
	reference: StrapiOmniRomExerciseReference;
	bodyPoint?: ICustomRomBodyPoints[];
	image?: {
		url: string;
	};
}

export type Frontend = {
	styles?: unknown;
};
export interface StrapiOmniRomExerciseGroup {
	id: number;
	name: string;
	key: BodyRegion;
	createdAt: string;
	updatedAt: string;
	publishedAt: string;
	frontend?: Frontend;
	locale: string;
}
export interface StrapiOmniPointsToCalculateAngle {
	id: number;
	a: StrapiOmniPoints;
	b: StrapiOmniPoints;
	c: StrapiOmniPoints;
	d: StrapiOmniPoints;
}
export interface StrapiOmniPoints {
	id: number;
	value: number;
	name: string;
	createdAt: string;
	updatedAt: string;
	publishedAt: string;
	locale: string;
}
export interface StrapiOmniRomExercisesVideo {
	id: number;
	url: string;
}

export interface StrapiOmniRomExerciseReference {
	max: number;
	id: number;
	name: string;
	min: number;
	normal: number;
	wfl: number;
}

export interface ICreateRomSession {
	id?: string;
	customRomId?: string;
	strapiOmniRomExerciseGroupId?: string | number;
	userId: string;
}

export interface IProgramPayload {
	userId?: string;
	limit: number;
	page: number;
	searchValue?: string;
	status?: string;
}

export interface StrapiExercise {
	id: number;
	attributes: IAttributes;
}

export interface ExerciseLibrary {
	id: string;
	physioterapistId: string;
	videoUrl: string;
	video?: string;
	thumbnail: string;
	title: string;
	description: string;
	reps: number;
	sets: number;
	dailyReps: number;
	weeklyReps: number;
	visibility: boolean;
	processing: boolean;
	jobId: string;
	createdAt: string;
	updatedAt: string;
	active: boolean;
}

export interface ProgramExercise {
	id: string;
	programId: string;
	strapiExerciseId: number | null;
	exerciseLibraryId: string | null;
	weeklyReps: number;
	dailyReps: number;
	sets: number;
	reps: number;
	active: boolean;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	order: number;
	exerciseLibrary: ExerciseLibrary | null;
	strapiExercise: StrapiExercise | null;
}

export interface ProgramSessionResult {
	id: string;
	programSessionId: string;
	programExerciseId: string;
	video: string;
	thumbnail: string;
	processing: boolean;
	jobId: string;
	exerciseDifficultyLevel: string;
	createdAt: string;
	reportsId: string | null;
	programExercise: IStrapiExerciseProps | IExerciseLibary;
}

export interface Program {
	id: string;
	userId: string;
	name: string;
	active: boolean;
	duration: number;
	durationType: string;
	startAt: string;
	finishAt: string;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	status: string;
	originType: string;
	strapiProgramTemplateId: string | null;
	programTemplateId: string | null;
}

export interface IProgramSessionActivity {
	id: string;
	programId: string;
	createdAt: string;
	updatedAt: string;
	overallCondition: string;
	painLevel: number;
	status: string;
	completed: boolean;
	reportsId: string | null;
	programSessionResult: ProgramSessionResult[];
	program: Program;
}

interface IOnSubmitVideo {
	size: number;
	type: string;
}
export interface ReactVideorRecordRef {
	handleStartRecording: () => void;
	handleStopRecording: () => void;
	handleStopReplaying: () => void;
	handleRating: () => void;
	recordedBlobs: IOnSubmitVideo[];
}

export interface IUpdateData {
	data: IActivityStreamData[];
	pagination: {
		endCursor: string;
		hasNextPage: boolean;
		hasPreviousPage: boolean;
		startCursor: string;
	};
}

export interface ActivityType {
	isHeader?: boolean;
}

export interface AddProgramPopupProps {
	isVisible: boolean;
	onCancel: () => void;
	selectedExercises: IProgramExercise[];
	setSelectedExercises: (
		selectedExercises: IProgramExercise[],
	) => void | Dispatch<SetStateAction<never[]>>;
	program?: IProgramTemplate | null;
	refresh: boolean;
	setRefresh: (refresh: boolean) => void;
	onOk: () => void;
	isEdit?: boolean;
	deleteProgram?: (program: IProgramData) => void;
	isSaveTemplateVisible?: boolean;
	programId: string;
	strapiId: number;
	activeKey: string;
	thumbnailValue: string;
}

export interface DeleteExerciseIds {
	id: string;
}

export interface IAddProgramItemProps {
	selectedExercises: any;
	isSaveTemplateVisible: boolean | undefined;
	isSaveTemplate: boolean;
	setSelectedExercises: any;
	validateProgram: () => void;
	handleRemoveExercise: any;
	setSaveTemplate: (value: boolean) => void;
	SetLibraryModalVisible: (value: boolean) => void;
	programName: string;
	setProgramName: (item: string) => void;
	setProgramStartDate: (item: string) => void;
	programStartDate: string;
	duration: number;
	setDuration: (value: number) => void;
	setDurationType: (value: string) => void;
	durationType: string;
	programDescription: string;
	setProgramDescription: (item: string) => void;
	imgFile: string | null;
	openCoverImage: boolean;
	setOpenCoverImage: (value: boolean) => void;
	previewImage: string | null;
	setImgFile: (value: string) => void;
	previewUnSplashedImage: string | null;
	setPreviewImage: (value: string | null) => void;
	setPreviewUnSplashedImage: (value: string | null) => void;
}

export interface ReportIdsInterface {
	romSessionsIds?: string[];
	romResultsIds?: string[];
	programSessionsIds?: string[];
	evaluationSessionsIds?: string[];
	exercisesSelectedRows?: string[];
	surveyResultIds?: string[];
	postureSessionsIds?: string[];
}

export interface TDataSetProps {
	key: string;
	patterns: string[];
	extraResponse?: string[];
	component: React.ReactNode;
}

export interface IFilteredList {
	list: SearchItem[];
	name: string;
	id: string;
}
export interface SearchBarProps {
	dataset: TDataSetProps[];
	setRenderedComponent(component: React.ReactNode | null): void;
	newRecentSearch: string;
	setNewRecentSearch: (value: string) => void;
	inputText: string;
	setInputText: (value: string) => void;
	setOpenSurveyModal: (value: boolean) => void;
	openDropdown: boolean;
	setOpenDropdown: (value: boolean) => void;
}
export interface SearchItem {
	key: string;
	icon: React.ReactNode;
	name: string;
}

export interface IRomSummaryDataProps {
	isAssistant?: boolean;
	groupData?: [];
	isLoading: boolean;
	currentPage: number;
	onPageChange: (page: number) => void;
	totalPage: number;
}

export interface AiAssiantProps {
	isAssistant?: boolean;
}
export interface VideoModalProps {
	value?: string;
	onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
	isReport?: boolean;
	videoBlob: null | Blob;
	isVideoModalVisible: boolean;
	handleClose: () => void;
	onStartRecord: () => void;
	onStopRecord: () => void;
	onDiscardRecord: () => void;
	onEndedTimer: () => void;
	onRecordFinished: (videoBlob: Blob) => void;
	isStartingTimer: boolean;
	videoRef: React.RefObject<any>;
	messageDescription: string;
	setMessageDescription: React.Dispatch<React.SetStateAction<string>>;
	sendMessage: () => void;
	videoState: RehabVideoState;
	facingMode: string;
	isImageModal: boolean;
	imgFile: File[] | undefined;
	openImgInput: () => void;
	handleKeyPress: (e: any) => void;
}

export interface ActivityStreamDataProps {
	isHeader: boolean | undefined;
	evaluationData: EvaluationItem[];
	isLoading: boolean;
	handleLoad: () => void;
	functionalData: undefined | [];
	eventData: IActivityStreamData[];
	isReply: boolean;
	lastMessageRef: any;
	handleShare: (item: EvaluationItem) => void;
	updateOmniROMData: (newDate: Date) => void;
	isFilter: boolean;
	clearFilter: () => void;
	updateData: IUpdateData | undefined;
	handleScrollTo: (e: string | null) => void;
	scrollEnabled: boolean;
}

export interface IFooterProps {
	isReply: boolean;
	shareItem: EvaluationItem[];
	setIsReply: (value: boolean) => void;
	showVideoModal: () => void;
	showImageModal: () => void;
	microphoneRef: any;
	sendMessage: () => void;
	imgInputRef: any;
	handleImgChange: (e: any) => void;
	messages: string;
	setMessage: (value: string) => void;
	handleKeyPress: (e: any) => void;
	handleScrollTo: (e: string | null) => void;
	inputRef: any;
	setShareItem: (value: EvaluationItem[]) => void;
}
export interface IPayload {
	userId?: string;
	limit: number;
	page: number;
	searchValue?: string;
	search?: string;
	session?: boolean;
	approved?: boolean;
}
export interface AddSurveyPopupProps {
	imgFile: File[] | undefined;
	setImgFile: (value: File[] | undefined) => void;
	setQuestionList: (item: QuestionListPayload[]) => void;
	questionList: QuestionListPayload[];
	surveyTitle: string;
	setSurveyTitle: (val: string) => void;
	surveyInstructions: string;
	setSurveyInstructions: (val: string) => void;
	surveyResultFeedback: string;
	setSurveyResultFeedback: (val: string) => void;
	surveyDescription: string;
	setSurveyDescription: (val: string) => void;
	questionValue: string;
	setQuestionValue: (val: string) => void;
	setQuestionType: (val: string | undefined) => void;
	questionType: string | undefined;
	onCancel: () => void;
	selectedQuestion: ISurveyQuestion[];
	setSelectedQuestion: (selectedQuestion: ISurveyQuestion[]) => void;
	survey?: ISurvey | null;
	refresh: boolean;
	setRefresh: (refresh: boolean) => void;
	onOk: () => void;
	isEdit?: boolean;
	cancelTriggered?: boolean;
	setSelectedSurvey: (selectedSurvey: ISurvey | null) => void;
}
export interface QuestionListPayload {
	id: string;
	title: string;
	description?: string;
	questionType?: string;
	scored?: boolean;
	optionList?: {
		id?: string;
		option: string;
		score: number;
		order: number;
	}[];
	deletedOptionList?: { id: string }[];
	order?: number;
}

export interface ExerciseProps {
	setSelectedSurvey: (selectedSurvey: ISurvey | null) => void;
	searchValue: string;
	setSearchValue: (value: string) => void;
	setActiveKey: (value: string) => void;
}

export interface MySurveysModalDataProps {
	setSelectedQuestion: (selectedQuestion: ISurveyQuestion[]) => void;
	selectedQuestion: ISurveyQuestion[];
	isGrid: boolean;
	setGrid: (isGrid: boolean) => void;
	selectedSurvey: ISurvey;
	setSelectedSurvey: (value: ISurvey | null) => void;
	setPreviousQuestion: (selectedQuestion: ISurveyQuestion[]) => void;
	setModalVisible: (value: boolean) => void;
	fetchHomeData: (page: number) => void;
	searchValue: string;
	setSearchValue: (value: string) => void;
	refresh: boolean;
}

export interface ISurevyPopupFormProps {
	survey?: ISurvey | null;
	isEdit?: boolean;
	surveyTitle: string;
	setSurveyTitle: (val: string) => void;
	surveyInstructions: string;
	setSurveyInstructions: (val: string) => void;
	surveyResultFeedback: string;
	setSurveyResultFeedback: (val: string) => void;
	surveyDescription: string;
	setSurveyDescription: (val: string) => void;
	questionValue: string;
	setQuestionValue: (val: string) => void;
	setQuestionType: (val: string | undefined) => void;
	questionType: string | undefined;
	setQuestionList: (item: QuestionListPayload[]) => void;
	questionList: QuestionListPayload[];
	imgFile: File[] | undefined;
	setImgFile: (img: File[] | undefined) => void;
}

export interface Item {
	key: string;
	id: any;
	active: boolean;
	title: string;
	description: string;
	repetitions: number;
	setsPerSession: number;
	setsPerDay: number;
	frequencyPerWeek: number;
}

export interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	editing: boolean;
	dataIndex: string;
	title: any;
	inputType: Text;
	record: Item;
	index: number;
	children: React.ReactNode;
}

export interface ReportModalProps {
	isModalVisible: boolean;
	onOk: () => void;
	onCancel: () => void;
	copyToClipboard: () => void;
	downloadPdf2: () => void;
	reportModalLoading: boolean;
	setReportModalLoading: (value: boolean) => void;
}

export interface IActivityStreamPanel {
	item: EvaluationItem;
	index: number;
	evaluationData: EvaluationItem[];
	lastMessageRef: any;
	handleScrollTo: (e: string | null) => void;
	scrollEnabled: boolean;
}

export interface CreateProgramModalProps {
	isVisible: boolean;
	onCancel: () => void;
	fetchData: (pageNumber: number) => void;
	refresh: boolean;
	setRefresh: (refresh: boolean) => void;
	searchValue: string;
	setSearchValue: (searchValue: string) => void;
}

export interface CreateSurveyModalProps {
	surveyEdit: boolean;
	setSurveyEdit: (value: boolean) => void;
	isEdit: boolean;
	onOk: () => void;
	survey: ISurvey;
	isVisible: boolean;
	onCancel: () => void;
	refresh: boolean;
	setRefresh: (refresh: boolean) => void;
	fetchData: (pageNumber: number, value: string) => void;
	previousQuestion: ISurveyQuestion[];
	setPreviousQuestion: (val: ISurveyQuestion[]) => void;
	fetchHomeData: (pageNumber: number) => void;
	activeTab: string;
}

export interface ISurveyOptionList {
	saveSurvey: () => void;
	selectedOptionList: ISurveyResultQuestionList[];
	setSelectedSurvey: (saveSurvey: ISurvey | null) => void;
	setSelectedOptionList: (
		selectedOptionList: ISurveyResultQuestionList[],
	) => void;
	item: ISurveyQuestion;
	index: number;
	selectedSurveyData: ISurveyQuestion[];
	selectedSurvey: ISurvey;
	setSelectedSurveyData: (selectedSurveyData: ISurveyQuestion[]) => void;
	fetchSurveyData: (value: number) => void;
}

export interface SummaryContentProps {
	handlePanelChange: (key: string | string[]) => void;
	handleClick: (surveyId: string) => void;
	item: ISurveyResult;
	activePanelKey: string | string[] | undefined;
	index: number;
	selectedCollapse: string[] | string | undefined;
	setSelectedCollapse: (value: string[] | string | undefined) => void;
	isLoading: boolean;
	surveySessionData: ISurveyResult[];
	getPainLevel: (painLevel: number, question: ISurveyResultQuestionList) => any;
	totalCount: number;
	currentCount: number;
	pageHandle: (page: number) => void;
	fetchSurveyByIdData: (surveyId: string, page: number) => void;
	surveyIdData: string;
	setSurveyIdData: (value: string) => void;
}
export interface ProgramSummaryProps {
	antIcon: ReactNode;
	CustomModalInfo: (value: ICustomModalProps) => void;
}

export interface IAiProgramSummaryContent {
	item: IProgram;
	programSessionData: any;
	isLoading: boolean;
	antIcon: ReactNode;
	CustomModalInfo: (value: ICustomModalProps) => void;
	setSessionClicked: (value: boolean) => void;
	setRehabExerciseToPatientId: (value: string | undefined) => void;
	setProgramuTutorialVideo: (value: string) => void;
	setProgramDescription: (value: string) => void;
	setExerciseTitle: (value: string | undefined) => void;
	programIdData: string;
	currentCount: number;
	fetchProgramByIdList: (programId: string, page: number) => void;
}

export interface IRomCaptureResultProps {
	aiAssistant?: boolean;
	extraContentCaptureCollapse: (exerciseId: string, total: number) => ReactNode;
	config: any;
	onSelectImage: (index: number, id: string) => void;
}

export interface ProfileModalProps {
	isModalOpen: boolean;
	setIsModalOpen: (isModalOpen: boolean) => void;
	setPolicyModalOpen: (policyModalOpen: boolean) => void;
	policyModalOpen: boolean;
	closable: boolean;
	onEdit: boolean;
}
export interface IProfileFormProps {
	policyModalOpen: boolean;
	onEdit?: boolean;
	onFinish?: () => void;
	setIsModalOpen: (value: boolean) => void;
	userFormData?: TProfileData;
	setUserFormData?: (item: TProfileData) => void;
	isBirthDateError?: boolean;
	setPolicyModalOpen: (value: boolean) => void;
	handleCheck?: (value: boolean) => void;
}
export interface IRehabVideoHeaderProps {
	isFullscreen: boolean;
	flipCamera: boolean;
	videoState: RehabVideoState;
	isStartingTimer: boolean;
	onFullscreen: () => void;
	onToggleMenu: () => void;
	onToggleInfo: () => void;
	onStartRecord: () => void;
	autoStopRecord: () => void;
	onStopRecord: () => void;
	onDiscardRecord: () => void;
	onSubmitRecord: () => void;
	onEndedTimer: () => void;
	onStopRating: () => void;
	selectedRating: number;
	setSelectedRating: (value: number) => void;
	isGestureEnabled: boolean;
	savedVoice: string;
	switchCamera: () => void;
}

export interface IRehabVideoHeaderDataProps {
	isFullscreen: boolean;
	flipCamera?: boolean;
	videoState: RehabVideoState;
	isStartingTimer: boolean;
	onFullscreen: () => void;
	toogleFacingMode?: () => void;
	onToggleMenu?: () => void;
	onToggleInfo?: () => void;
	onStartRecord: () => void;
	onStopRecord: () => void;
	onDiscardRecord: () => void;
	onSubmitRecord: () => void;
	onEndedTimer: () => void;
	onStopRating: () => void;
	selectedRating: number;
	setSelectedRating: (value: number) => void;
	isGestureEnabled?: boolean;
	savedVoice: string;
}

export interface IRowDataProps {
	isDashboard: boolean;
	isSplashOpened: boolean;
	onToggleMenu: () => void;
	onToggleTutorial: () => void;
	onTogglesSwitchMode: () => void;
	onTogglesSplashPage: () => void;
	onExerciseValueAndCoordinates: (
		value: number,
		coordinates: NormalizedLandmark[],
	) => void;
	onBodyPointsVisible: (value: boolean) => void;
	switchCamera: () => void;
	onTogglePauseVideo: () => void;
	onFullscreen: () => void;
	isValidResult: boolean | null;
	onSavePhysicalAssessmentsMetrics: () => void;
	isVideoPause: boolean;
	isFullscreen: boolean;
	flipCamera: boolean;
	isSwitchMode: boolean;
	isMenuOpened: boolean;
	isTutorialOpened: boolean;
	onNextTransition: (transition: TTransitions) => void;
	fullscreenRef: any;
	isCompleted: boolean;
	setIsManual: (val: boolean) => void;
	setCompleted: (val: boolean) => void;
	isManual: boolean;
	onToggleInfo: () => void;
	isInfoOpened: boolean;
}

export interface IRomResultDataProps {
	transitionTime: number;
	isValidResult: boolean | null;
	onSavePhysicalAssessmentsMetrics: () => void;
	onFullscreen: () => void;
	onTogglesSwitchMode: () => void;
	flipCamera: boolean;
	switchCamera: () => void;
	isSwitchMode: boolean;
	isSplashOpened: boolean;
	isVideoPause: boolean;
	onTogglePauseVideo: () => void;
	onNextTransition: (transition: TTransitions) => void;
	onToggleTutorial: () => void;
	isFullscreen: boolean;
	isManual: boolean;
	isCompleted: boolean;
}

export interface ReportContentProps {
	reportModalLoading: boolean;
	isEditMode: boolean;
}

export interface IReportOjectiveProps {
	romSummaryData: IRomSession[];
	romCapturesData: IRomPatientResult[];
	rehabCapturesData: IRehabExerciseListSession[];
	surveyData: ISurveyResult[] | undefined;
	romSesssionNotes: string;
	romResultsNotes: string;
	programSessionsNotes: string;
	surveyResultNotes: string;
	setEvaluationNotes: (notes: string) => void;
	setRomSessionNotes: (notes: string) => void;
	setRomResultsNotes: (notes: string) => void;
	setProgramSessionsNotes: (notse: string) => void;
	setSurveyResultNotes: (notes: string) => void;
	isEditMode: boolean;
	report: any;
	handleSubmit: (notes: string) => void;
	setActiveComponent: (value: IAddButtonItemsProps) => void;
	setIsVisible: (val: boolean) => void;
}

export interface TPainAssessmentDataProps {
	onItemSelect: (value: TDataProps[]) => void;
	onOk: () => void;
	onCancel: () => void;
	selectedItems: TDataProps[];
	isPainAssessmentModalVisible: boolean;
	apiData: TCoachSummary;
	fetchData: (limit: number, page: number) => void;
	perPage?: number;
	currentPage: number;
	perpage: number;
}

export interface ISubMenuAdmin {
	label: string;
	key: string;
	icon: ReactNode;
	status?: Status | 'newPatients';
}

export interface ISubMenuAdminPatients {
	label: string;
	key: string;
	icon: ReactNode;
	status?: StatusPatients | 'unAssignedPatients';
}

export interface AdminMenuProps {
	onClick: (key: string) => void;
}

export interface IAdminSideBarProps {
	onChangeHome: (value: string) => void;
	activeMenu: string;
	setActiveMenu: (value: string) => void;
	setSearchQuery: (value: string) => void;
	setSelectedItem: (value: SelectedUser | null) => void;
	onClick: (value: string) => void;
}

export interface IAdminMenuContentProps {
	inboxLoading: boolean;
	activeMenu: string;
	setActiveMenu: (value: string) => void;
	setSearchQuery: (value: string) => void;
	selectedItem: SelectedUser | null;
	handleSelectChange: (Selected: SelectedUser) => void;
	isModalOpen: boolean;
	setIsModalOpen: (value: boolean) => void;
	profileData: {};
	functionalData: [] | undefined;
	handleInboxChange: (selected: SelectedUser) => void;
	searchQuery: string;
	activeSubMenu: string;
	setActiveSubMenu: (value: string) => void;
	inboxData: any;
	onClick: (value: string) => void;
	activeSubPatients: string;
	setActiveSubPatients: (value: string) => void;
}

export interface InboxDataMessage {
	id: string;
	profile: {
		firstName: string;
		lastName: string;
		email: string;
		fullName: string;
	};
	unread: number;
	lastMessage: string;
}
interface UserData {
	avatarColor: string;
}

export interface PatientListDataProps {
	active: boolean;
	data: UserData;
	email: string;
	firstName: string;
	id: string;
	fusionAuthUserId: string;
	createdAt: string;
}
interface Client {
	inviteCode: string;
}

export interface APIKeyData {
	id: string;
	clientId: string;
	openaiApiKey: string;
	openaiApiKeyActive: boolean;
	createdAt: string;
	updatedAt: string;
	client?: Client;
}

export interface IMenuAdminProps {
	label: string;
	key: string;
	icon: ReactNode;
}

interface Reference {
	id: number;
	createdAt: string;
	updatedAt: string;
	publishedAt: string;
	name: string;
	locale: string;
	min: number;
	normal: number;
	wfl: number;
}
interface StrapiOmniRomExercise {
	id: number;
	name: string;
	description: string | null;
	order: number;
	reference: Reference;
	video?: StrapiExercisesAssets;
	image?: StrapiExercisesAssets;
}
interface RomSession {
	id: string;
	userId: string;
	bodyRegion: string;
	strapiOmniRomExerciseGroupId: number;
	completed: boolean;
	createdAt: string;
	updatedAt: string;
	status: string;
	reportsId: string | null;
	strapiOmniRomExerciseGroup: {};
}
export interface ProgramData {
	id: string;
	userId: string;
	romExerciseId: string | null;
	strapiOmniRomExerciseId: number;
	value: number;
	screenshot: string;
	outOfRange: boolean;
	createdAt: string;
	updatedAt: string;
	romSessionId: string;
	reportsId: string | null;
	romSession: RomSession;
	activityStreamEvaluation: any[];
	activityStreamFeedback: any[];
	strapiOmniRomExercise: StrapiOmniRomExercise;
}

export interface IProgramData {
	id: string;
	userId: string;
	name: string;
	active: boolean;
	duration: number;
	durationType: string;
	startAt: Date;
	finishAt: Date;
	createdAt: Date;
	updatedAt: Date;
	deletedAt: Date;
	status: string;
	originType: string;
	programTemplateId: string;
	rehabPatientListExercisesId: string | null;
}
export interface ProgramCompletionData {
	id: string;
	programId: string;
	createdAt: string;
	updatedAt: string;
	overallCondition: string;
	painLevel: number;
	status: string;
	completed: boolean;
	reportsId: string | null;
	rehabExerciseListSessionId: string | null;
	program: IProgramData;
}

export interface IAddButtonItemsProps {
	key: string;
	label: string;
	icon: React.ReactNode;
}

export interface IPostureData {
	data: IPosture[];
	pagination: IUserPaginationDefaultResponse | null;
	uploadProgress: number;
}

export interface IPosture {
	id: string;
	userId: string;
	createdAt: Date;
	updatedAt: Date;
	deletedAt: Date;
	postureAnalytics: PostureAnalyticsItem[];
}

interface Coordinates {
	x: number;
	y: number;
	z: number;
	visibility: number;
}

export interface PostureAnalyticsItem {
	id: string;
	userId: string;
	view: 'front' | 'back' | 'left' | 'right';
	head: number;
	ear: number;
	shoulder: number;
	elbow: number;
	hip: number;
	knee: number;
	ankle: number;
	screenshot: string;
	createdAt: Date;
	updatedAt: Date;
	deletedAt: Date | null;
	postureAnalyticsSessionId: string;
	coordinates: Coordinates[];
}

export interface IPostNewInviteUserData {
	createdById: string;
	firstName: string;
	lastName: string;
	email: string;
	inviteUrl: string;
	invitedRole: string;
	inviteCode: string;
	deletedAt: string;
	features: [string];
}

export interface BodyPoint {
	id: string;
	customRomTemplateExerciseId: string;
	name: string;
	kinematics: string;
	normal: number;
	wfl: number;
	min: number;
	max: number;
	createdAt: string;
	updatedAt: string;
	strapiOmniRomExerciseId: number;
	function: string;
	pointsToValidatePosition: SimplifiedPoints;
	pointsToCalculateAngle: SimplifiedPoints;
}

export interface IRomTemplateExercise {
	id: string;
	customRomTemplateId: string;
	name: string;
	description: string;
	transitionTime: number;
	video: string;
	title: string;
	order: number;
	createdAt: string;
	updatedAt: string;
	bodyPoint: BodyPoint[];
	image: string;
	strapiOmniRomExercise: StrapiOmniRomExercises;
	exerciseLibrary: ExerciseLibrary | null;
	OmniRomExerciseId: {
		image: {
			url: string;
		};
		video: {
			url: string;
		};
		name: string;
		description: string;
	};
}

export interface ICustomRomTemplate {
	id: string;
	clientId: string | null;
	title: string;
	description: string;
	active: boolean;
	finishAt: string | null;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	exercises: IRomTemplateExercise[];
}

export interface ICustomRomBodyPoints {
	id: string;
	name: string;
	kinematics: string;
	normal: number;
	wfl: number;
	min: number;
	max: number;
	createdAt: Date;
	updatedAt: Date;
	value: number;
	function: String;
	strapiOmniRomExerciseId: number;
	romProgramExercise: ICustomRomBodyPoints;
	strapiOmniRomExercise: StrapiExercises;
	exerciseLibrary: ExerciseLibrary | null;
	pointsToCalculateAngle: StrapiOmniPointsToCalculateAngle;
	pointsToValidatePosition: StrapiOmniPointsToCalculateAngle;
}

export interface ICustomRomExercise extends StrapiOmniRomExercises {
	id: string;
	customRomId: string;
	title: string;
	exercises: IRomProgramExercise[];
	description: string;
	transitionTime: number;
	video: string;
	thumbnail: string;
	image: string;
	screenshot: string;
	order: number;
	bodyPoints: ICustomRomBodyPoints[];
	romProgramExercise: ICustomRomBodyPoints;
	romProgramexercise: ICustomRomBodyPoints;
	createdAt: Date;
	strapiOmniRomExercise: StrapiOmniRomExercises;
	exerciseLibrary: ExerciseLibrary | null;
	value: number;
	updatedAt: Date;
	customRomExerciseLibraryId: string;
}

export interface ICustomRom {
	id: string;
	userId: string;
	openSessionId: string;
	title: string;
	description: string;
	active: boolean;
	originType: string;
	finishAt: Date | null;
	createdAt: Date;
	updatedAt: Date;
	deletedAt: Date | null;
	exercises: ICustomRomExercise[];
	customRomSession: ICustomRomSession[];
}

export interface ICustomRomSession {
	id: string;
	userId: string;
	finishAt: Date;
	completed: boolean;
	createdAt: Date;
	updatedAt: Date;
	customRomId: string;
	customRomExerciseId: string;
	romPatientResults: ICustomRomSessionExercise[];
	customRomSessionExercise: ICustomRomSessionExercise[];
}

export interface ICustomRomSessionExercise {
	id: string;
	customRomSessionId: string;
	customRomExercise: ICustomRomExercise;
	customRomExerciseId: string;
	createdAt: Date;
	updatedAt: Date;
	coordinates: Coordinates[];
	image: string;
	value: number;
	bodyPoints: ICustomRomBodyPoints[];
	description: string;
	title: string;
	transitionTime: number;
}

export interface ICustonRomPaginated {
	data: ICustomRom[];
	pagination: IPaginationPluginDefaultResponse | null;
}

export interface ICustonRomSessionPaginated {
	data: ICustomRomSession[];
	pagination: IPaginationPluginDefaultResponse | null;
}

interface CaptureData {
	userId: string;
	strapiOmniRomExerciseId: string;
	romSessionId: string;
	value: any | null;
	coordinates: string;
	screenshot: string;
}

export interface ICustomRomState {
	customRom: ICustonRomPaginated;
	customRomSession: ICustomRomSession | null;
	data: ICustomRom[];
	pagination: IPaginationPluginDefaultResponse | null;
	romUploadDetails: any | null;
	customRomSessionData: ICustonRomPaginated;
}

interface Point {
	id: number;
	value: number;
	name: string;
	createdAt: string;
	updatedAt: string;
	publishedAt: string;
	locale: string;
}

export interface ExercisePoints {
	a: Point;
	b: Point;
	c: Point;
}

interface SimplifiedPoint {
	value: number;
	name: string;
}

export interface SimplifiedPoints {
	a: SimplifiedPoint;
	b: SimplifiedPoint;
	c: SimplifiedPoint;
}

export interface IRomExerciseLibrary {
	id: string;
	physioterapistId: string;
	title: string;
	description: string;
	video: string;
	thumbnail: string;
	processing: boolean;
	jobId: string;
	createdAt: Date;
	updatedAt: Date;
	deletedAt: Date;
	transitionTime?: number;
	exercises?: IStrapiExercises[];
}

export interface IRomProgramExercise {
	id: string;
	programId: string;
	transitionTime: number;
	strapiOmniRomExerciseId: number;
	exerciseLibraryId: string;
	normal: number;
	wfl: number;
	min: number;
	max: number;
	active: boolean;
	order: number;
	createdAt: Date;
	updatedAt: Date;
	deletedAt: null;
	exerciseLibrary: IRomExerciseLibrary;
	strapiOmniRomExercise: StrapiOmniRomExercises;
	strapiLibraryId: string;
	OmniRomExerciseId: {
		id: number | string;
		reference: StrapiOmniRomExerciseReference;
	};
}

interface TemplateExercise {
	id: number;
	order: number;
	createdAt: string;
	updatedAt: string;
	publishedAt: string;
	OmniRomExerciseId: StrapiOmniRomExercises;
	video: string;
	name: string;
	description: string;
	transitionTime: number;
}

export interface TemplateExerciseProgram {
	id: number;
	title: string;
	createdAt: string;
	updatedAt: string;
	publishedAt: string;
	exercises: TemplateExercise[];
}
export interface ProfileAdmin {
	email: string;
	firstName: string | null;
	lastName: string | null;
	fullName: string | null;
	imageUrl: string | null;
	preferredLanguages: string[] | null;
	birthDate: string | null;
	gender: string | null;
	isPregnant: boolean | null;
	imperialHeight: number | null;
	metricHeight: number | null;
	height: number | null;
	weight: number | null;
	imperialWeight: number | null;
	metricWeight: number | null;
	consentPolicyRead: boolean;
	patientId: string | null;
	role: string;
	avatarColor: string;
}

export interface AdminDashboardPatient {
	profile: ProfileAdmin;
	id: string;
	clientId: string;
	fusionAuthUserId: string;
	createdAt: string;
	updatedAt: string;
	active: boolean;
}

export interface ResponseDataNewDashoard {
	data: AdminDashboardPatient[];
	pagination: IUserPaginationDefaultResponse;
}
export interface Feature {
	id: string;
	name: string;
	description: string;
	path: string;
	active: boolean;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	userInviteId: string;
}
export interface IInvitePatientModalProps {
	isInvitePatientModalOpen: boolean;
	handleUserUpdate: (data: RegisteredPatientsProps) => void;
	setIsInvitePatientModalOpen: (val: boolean) => void;
	closable: boolean;
	fullRowDetails?: any;
	rowData?: any;
	rowDataFeature?: UserFeatureProps[];
	isRegistered?: boolean;
	currentPage: number;
	searchValue: string;
	filterButton: string;
	isBulkInvite?: boolean;
}
export interface AllFeatureFlagsInterface {
	id: string;
	clientId: string;
	featureId?: string;
	default: boolean;
	active: boolean;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	feature: Feature;
}

interface CreatedBy {
	id: string;
	clientId: string;
	fusionAuthUserId: string;
	createdAt: string;
	updatedAt: string;
	active: boolean;
}

export interface IInviteUserProps {
	id: string;
	createdById: string;
	firstName: string;
	lastName: string;
	email: string;
	inviteUrl: string;
	invitedRole: string;
	inviteCode: string;
	createdAt: string;
	deletedAt: string | null;
	features: Feature[];
	createdBy: CreatedBy;
}

export interface UserFeatureProps {
	id: string;
	userId: string;
	featureId: string;
	active: boolean;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	feature: Feature;
}

export interface RegisteredPatientsProps {
	id: string;
	clientId: string;
	fusionAuthUserId: string;
	createdAt: string;
	updatedAt: string;
	active: boolean;
	userFeature: UserFeatureProps[];
	profile: ProfileAdmin;
	physiotherapistPatientAssociationPatientIdRelation: PhysiotherapistPatientAssociation[];
}

export interface IShowPopStatus {
	showPopup: boolean;
}

export interface IProgramFilterOptions {
	bodyRegionIds: number[];
	exerciseCategoriesIds: number[];
	jointsIds: number[];
	functionalGoalsIds: number[];
	sideIds: number[];
}

export interface IProgramExerciseLibrary extends StrapiExercises {
	physioterapistId: string;
	videoUrl: string;
	thumbnail: string;
	title: string;
	description: string;
	reps: number;
	sets: number;
	dailyReps: number;
	weeklyReps: number;
	visibility: boolean;
	processing: boolean;
	jobId: string;
	createdAt: string;
	updatedAt: string;
	active: boolean;
}

export interface ExerciseProps {
	setSelectedExercises: (selectedExercises: IExerciseDetails[]) => void;
	selectedExercises: IExerciseDetails[];
	isGrid: boolean;
	setGrid: (isGrid: boolean) => void;
	open: boolean;
	setOpen: (value: boolean) => void;
}

export interface IAddCoverImageProps {
	openCoverImage: boolean;
	setOpenCoverImage: (value: boolean) => void;
	previewImage: string | null;
	setImgFile: (value: string) => void;
	previewUnSplashedImage: string | null;
	setPreviewImage: (value: string | null) => void;
	setPreviewUnSplashedImage: (value: string | null) => void;
}

export interface IExerciseDetailsModalData {
	strapiExerciseId: string;
	exerciseLibraryId: string;
	id: string;
	physioterapistId: string;
	videoUrl: string;
	thumbnail: string | null;
	title: string;
	description: string;
	reps: number;
	sets: number;
	dailyReps: number;
	weeklyReps: number;
	visibility: boolean;
	processing: boolean;
	jobId: string;
	createdAt: string;
	updatedAt: string;
	active: boolean;
	bodyRegionIds: string[];
	exerciseCategoriesIds: string[];
	jointsIds: string[];
	functionalGoalsIds: string[];
}

export interface TOnBoardSymptomsProps {
	setActiveStep: (value: number) => void;
	setProgressPercent: (value: number) => void;
}

export interface TOnBoardPostureProps {
	setProgressPercent: (value: number) => void;
	onComplete: () => void;
}

export interface TOnBoardPostureProps {
	setActiveStepLocal?: (value: number) => void;
	setActiveStep?: (value: number) => void;
	setProgressPercent: (value: number) => void;
	onComplete: () => void;
}

export interface IWholeDayActivity {
	[key: string]: number;
}

export interface TSideFilters {
	id: string;
	attributes: {
		name: string;
	};
}

export interface InvitationData {
	firstName?: string;
	lastName?: string;
	email?: string;
	invitedRole: string | undefined;
	features: string[];
	message: string;
}

export interface UploadedFile {
	uid: string;
	lastModified: number;
	lastModifiedDate: Date;
	name: string;
	size: number;
	type: string;
	webkitRelativePath: string;
}

export interface IBulkInviteUser {
	message: string;
	invitedRole: string;
	features: string[];
	users: {
		firstName: string;
		lastName: string;
		email: string;
		mobilePhone: string;
		adminIds: string[];
	}[];
	password: string;
}
