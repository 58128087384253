import { getDiagnosisCode } from '@stores/diagnosisCode';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { DiagnosisCode } from '@stores/interfaces';
import { updateReport } from '@stores/report/reports';
import { Button, message, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './ReportDiagnosisCodes.css'

interface ReportCodesProps {
  isDownload?: boolean
}

const ReportDiagnosisCodes = (props: ReportCodesProps) => {

  const { isDownload } = props
  const buttonStyle = {
    color: "#533F85",
    border: "inherit",
    marginTop: "-5px",
  };
  const report = useTypedSelector(state => state.reports.report)
  const [diagnosisData, setDiagnosisData] = useState<DiagnosisCode[]>([])
  const [diagnosisCode, setDiagnosisCode] = useState<number[]>(report?.diagnosisCode || []);
  const { Option } = Select;
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()

  const handleChange = (value: number[]) => {
    setDiagnosisCode(value);
  };

  const fetchData = async () => {
    const data = await dispatch(getDiagnosisCode());
    setDiagnosisData(data.payload.data)
  }

  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    if (report?.diagnosisCode) {
      setDiagnosisCode(report.diagnosisCode);
    }
  }, [report]);

  const handleSave = async () => {
    const reportId = report?.id || '';
    const payload = {
      diagnosisCode: diagnosisCode
    }
    await dispatch(updateReport({ payload, reportId }))
    message.success(t('Admin.data.addToReports.saveText'))
  }

  return (
    <>
      <p className="code-label">{t('Patient.data.diagnosisCode.label')}</p>
      {!isDownload &&
        <div className='input-container'>
          <Select
            placeholder={t('Patient.data.diagnosisCode.placeHolder')}
            mode="multiple"
            onChange={handleChange}
            allowClear
            style={{ width: "90%", marginBottom: "5px" }}
            value={diagnosisCode}
            showSearch
            filterOption={(input, option) => {
              const optionText = option?.children?.toString().toLowerCase() || '';
              const optionValue = option?.value?.toString().toLowerCase() || '';
              return optionText.includes(input.toLowerCase()) || optionValue.includes(input.toLowerCase());
            }}
          >
            {
              diagnosisData?.map((option) => (
                <Option key={option?.id} value={option?.id}>{option.code} - {option.description}</Option>
              ))
            }
          </Select>&nbsp;
          <Button style={buttonStyle} className='start-session-css !bg-[#BDFF00]' onClick={handleSave}>
            + {t('Patient.data.diagnosisCode.addCodes')}
          </Button>
        </div>
      }
      {
        report?.diagnosisCode?.length! > 0 &&
        <div className='custom-padding'>
          <div className='code-container custom-margin-icd'>
            <span className='input-label-container'>{t('Patient.data.diagnosisCode.icdCode')}</span>
            <span className='input-right-label-container'>{t('Patient.data.diagnosisCode.icdDescription')}</span>
          </div>
          {report?.diagnosisCode?.map((code) => {
            const diagnosis = diagnosisData?.find((data) => data.id === code);
            return (
              diagnosis && (
                <div key={code} className='code-container'>
                  <span className="input-label input-label-container">{diagnosis.code}</span>
                  <span className="input-label input-right-label-container">{diagnosis.description}</span>
                </div>
              )
            );
          })}
        </div>
      }
    </>
  )
}

export default ReportDiagnosisCodes;
