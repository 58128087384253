import { Line } from '@ant-design/charts';
import { Tabs } from 'antd';
import { useState } from 'react';
import moment from 'moment';
import PostureCaptureScreenshots from './PostureCaptureScreenshots';
import { IPosture } from '@stores/interfaces';
import { useTypedSelector } from '@stores/index';

const bodyParts = [
	{ key: 'ear', value: 'Ears', color: '#5B8FF9' },
	{ key: 'shoulder', value: 'Shoulders', color: '#32D583' },
	{ key: 'elbow', value: 'Elbows', color: '#FEC84B' },
	{ key: 'hip', value: 'Hips', color: '#DC6803' },
	{ key: 'knee', value: 'Knees', color: '#7F56D9' },
	{ key: 'ankle', value: 'Ankles', color: '#101323' },
];

const createChartConfig = (postureData: IPosture[], side: string) => ({
	data: postureData
		?.filter(item => item.postureAnalytics.some(data => data.view == side))
		?.reverse()
		?.flatMap(item => {
			const dateUtc = moment(item.createdAt).local().format('LLL');
			const index = item.postureAnalytics.findIndex(data => data.view == side);
			return bodyParts.map(part => ({
				date: dateUtc,
				value:
					item.postureAnalytics[index]?.[part.key] <= -40
						? -40
						: item.postureAnalytics[index]?.[part.key] >= 40
							? 40
							: item.postureAnalytics[index]?.[part.key],
				bodyPart: part.value,
				image: item.postureAnalytics[index]?.screenshot || '',
				angle: side,
				index: index,
			}));
		}),
	padding: 'auto',
	xField: 'date',
	yField: 'value',
	seriesField: 'bodyPart',
	tooltip: {
		customContent: (title: string, data) => {
			if (data.length === 0) return null;

			const matchingItem = postureData.find(
				item => moment(item.createdAt).local().format('LLL') === title,
			);
			if (!matchingItem) return null;


			const bodyPartValues = bodyParts
				.map(part => {
					const value =
						matchingItem.postureAnalytics[data[0].data.index]?.[part.key];
					return `<div class="value">${part.value}: ${value}°</div>`;
				})
				.join('');

			const imageUrl =
				matchingItem.postureAnalytics[data[0].data.index]?.screenshot;

			return `
        <div class="chart-tool-tip">
          <div class="image">
            ${imageUrl && `<img src="${imageUrl}" alt="Captured Image" style="width: 100px; height: auto;" />`}
          </div>
          <div class="date">${title}</div>
          ${bodyPartValues}
        </div>
      `;
		},
	},
	point: {
		size: 5,
		shape: 'circle',
		style: datum => ({
			fill: bodyParts.find(part => part.value === datum.bodyPart)?.color,
			stroke: '#ffffff',
			lineWidth: 1,
		}),
	},
	meta: {
		value: {
			min: -40,
			max: 40,
			tickInterval: 5,
		},
	},
	slider: {
		start: 0.0,
		end: 1,
	},
	color: bodyParts.map(part => part.color),
	legend: {
		itemName: {
			style: {
				fontSize: 12,
				fill: '#000',
			},
		},
		layout: 'horizontal',
		position: 'top',
		offsetX: 0,
		offsetY: 0,
		itemMargin: 10,
		items: bodyParts.map(part => ({
			name: part.value,
			color: part.color,
			marker: {
				symbol: 'square',
				style: {
					stroke: part.color,
					fill: part.color,
					lineWidth: 7,
				},
			},
		})),
	},
});

export const PostureCaptureData = ({ side }: { side: string }) => {
	const [activeKey, setActiveKey] = useState('1');
	const postureData = useTypedSelector(state => state.postures.postures.data);

	const chartConfig = createChartConfig(postureData, side);

	return (
		<div className="posture-capture-data">
			<Tabs
				type="card"
				defaultActiveKey={activeKey}
				activeKey={activeKey}
				onChange={value => setActiveKey(value)}
				className="tab"
				items={[
					{
						label: 'Chart',
						key: '1',
						children: (
							<div className="chart">
								<Line {...chartConfig} />
							</div>
						),
					},
					{
						label: 'Screenshots',
						key: '4',
						children: <PostureCaptureScreenshots angle={side} />,
					},
				]}
			/>
		</div>
	);
};

export default PostureCaptureData;
