import ExportReports from './ExportReports';
import { Modal, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { APIKeyData } from '@stores/interfaces';
import OpenAiSettings from './OpenAi';
import InviteCodeSettings from './InviteCode';
import { useEffect, useState } from 'react';
import './style.css';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import Features from './Features';
import { USER_ROLES } from '@stores/constants';
import Templates from './Templates';
import { getEmailTemplate, getInviteTemplate } from '@stores/settings/setings';

interface SettingProps {
	isModalOpen: boolean;
	setIsModalSettingsOpened: (value: boolean) => void;
	settingData: APIKeyData | undefined;
	apiKey: string;
	setApiKey: (value: string) => void;
	inviteCode: string;
}

const Settings = (props: SettingProps) => {
	const {
		isModalOpen,
		setIsModalSettingsOpened,
		settingData,
		apiKey,
		setApiKey,
		inviteCode,
	} = props;

	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState('OpenAi');
	const allFeatureFlags = useTypedSelector(
		state => state.adminDashboardPatient.allFeatureFlags,
	);
	const [features, setFeatures] = useState<string[]>([]);
	const user = useTypedSelector(state => state.user);
	const isSuperAdmin = user?.profile?.role === USER_ROLES.SUPER_ADMIN;
	const dispatch = useTypedDispatch();

	const items = [
		{
			key: 'OpenAi',
			label: t('Admin.data.menu.setting.openAi.label'),
		},
		{
			key: 'InviteCode',
			label: t('Admin.data.menu.setting.inviteCode.label'),
		},
		...(isSuperAdmin
			? [
					{
						key: 'features',
						label: 'Features',
					},
					{
						key: 'templates',
						label: 'Templates',
					},
					{
						key: 'exportReports',
						label: 'Export Reports',
					},
				]
			: []),
	];

	useEffect(() => {
		if (isModalOpen) {
			const allFeatureIds = allFeatureFlags
				.map(feature => feature.featureId)
				.filter((id): id is string => id !== undefined);
			setFeatures(allFeatureIds);
			fetchData();
		}
	}, [isModalOpen, allFeatureFlags]);

	const fetchData = async () => {
		await dispatch(getEmailTemplate());
		await dispatch(getInviteTemplate());
	};

	return (
		<Modal
			title={
				<div style={{ textAlign: 'center', marginTop: '8px' }}>
					<span className="setting-title">
						{t('Admin.data.menu.setting.title')}
					</span>
				</div>
			}
			width="800px"
			destroyOnClose={true}
			open={isModalOpen}
			closable={true}
			onCancel={() => setIsModalSettingsOpened(false)}
			maskClosable={true}
			footer={null}
			style={{ top: '25%', userSelect: 'none' }}>
			<Tabs
				activeKey={activeTab}
				onChange={setActiveTab}
				items={items.map(item => ({
					key: item.key,
					label: item.label,
					children:
						item.key === 'OpenAi' ? (
							<OpenAiSettings
								{...{
									isModalOpen,
									setIsModalSettingsOpened,
									settingData,
									apiKey,
									setApiKey,
								}}
							/>
						) : item.key === 'InviteCode' ? (
							<InviteCodeSettings {...{ inviteCode }} />
						) : item.key === 'exportReports' ? (
							<ExportReports />
						) : item.key === 'templates' ? (
							<Templates setIsModalSettingsOpened={setIsModalSettingsOpened} />
						) : (
							<Features
								features={features}
								setFeatures={setFeatures}
								setIsModalSettingsOpened={setIsModalSettingsOpened}
							/>
						),
				}))}
			/>
		</Modal>
	);
};

export default Settings;
