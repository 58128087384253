import { AI_MENU_DATA } from './AI_MENU_DATA';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import './style.less';
import {
	setActiveTab,
	setProgramModal,
	setProgramStateId,
	setStateId,
	setSurveyModal,
	setSurveyStateId,
} from '@stores/aiAssistant/aiAssistant';
import { useNavigate } from 'react-router-dom';
import { router } from '@routers/routers';
import { ACTIVETAB, USER_ROLES } from '@stores/constants';
import { ChevronRightDouble } from '@carespace-icons/arrows/chevronRightDouble';

export default function AiTab() {
	const activeTab = useTypedSelector(
		state => state.aiAssistant.aiAssistant.activeTab,
	);
	const navigate = useNavigate();
	const dispatch = useTypedDispatch();
	const programApproved = useTypedSelector(state=> state.aiAssistant.program.programApproved)	

	const getMenuItems = (activeTab: string) => {
		let id: number;
		if (activeTab === ACTIVETAB.HOME) {
			id = 0
		}
		else if (activeTab === 'virtualEvaluation' || activeTab === 'listEvaluation') {
			id = 1;
		} else if (
			activeTab === 'startScan' ||
			activeTab === 'romSummary' ||
			activeTab === 'captures' ||
			activeTab === 'postureScan' ||
			activeTab === 'startRomScan' ||
			activeTab === 'postureSummary' ||
			activeTab === 'postureCaptures' || activeTab === 'customSummary' ||
			activeTab === 'customCaptures'
		) {
			id = 2;
		} else if (
			activeTab === 'generateProgram' ||
			activeTab === 'programs' ||
			activeTab === 'listSessions'
		) {
			id = 3;
		} else if (activeTab === 'createReport' || activeTab === 'myReport') {
			id = 4;
		} else if (
			activeTab === 'assignSurvey' ||
			activeTab === 'createSurvey' ||
			activeTab === 'surveySummary' ||
			activeTab === 'startSurveyUser'
		) {
			id = 5;
		}

		const category = AI_MENU_DATA?.data?.find(item => item?.id === id);
		if (!category) return { title: '', items: [] };

		const newList = category.list.filter(item => {
			if (!item) return false;
			if (item.key === 'programs' && programApproved?.data?.length === 0)
				return false;
			return true;
		});

		return {
			title: category.title,
			items: newList,
		};
	};

	const { title, items } = getMenuItems(activeTab);
	const { user, selectedUser } = useTypedSelector(
		(state) => ({
			user: state.user,
			selectedUser: state.contacts.main.selectedUser
		})
	)
	const isSuperAdmin = user?.profile?.role === USER_ROLES.SUPER_ADMIN;

	const onChangeHome = () => {
		dispatch(setActiveTab(ACTIVETAB.HOME));
		navigate(router.ROOT, {
      state: { activeMenu: 'home'}, 
    });
	};

	return (
		<div className="aitab-menu-container">
			{activeTab != ACTIVETAB.HOME && <div style={{ cursor: 'pointer', color: 'white', fontSize: '14px', fontWeight: 'bold', gap: "5px", display: "flex" }} onClick={onChangeHome}><p>Home</p><ChevronRightDouble width={20} height={20} color='stroke-white' /></div>}
			{title && <div className="aitab-menu-title">{title}</div>}
			{items.map((item, index) =>
				(item.key === 'generateProgram' ||
					item.key === 'createSurvey' ||
					item.key === 'assignSurvey') &&
					!user.isPhysioterapist && !isSuperAdmin ? null : (
					<div
						key={index}
						className={`aitab-menu-item ${activeTab === item.key ? 'selected-ai-tab' : 'unselected-ai-tab'}`}
						onClick={() => {
							dispatch(setActiveTab(item.key));
							dispatch(setStateId(''));
							dispatch(setProgramStateId(''));
							dispatch(setSurveyStateId(''));
							item.key === 'assignSurvey' && dispatch(setSurveyModal(true));
							item.key === 'generateProgram' && dispatch(setProgramModal(true));
							item.key === 'startScan' && navigate(`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.AIASSISTANT_START_SCAN}`)
						}}>
						{item.icon}
						<span>{item.name}</span>
					</div>
				),
			)}
		</div>
	);
}
