import CheckBox from '@atoms/CheckBox';
import { useEffect, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { Avatar, Empty, Popover, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowUp } from '@carespace-icons/arrows/arrowUp';
import { ArrowDown } from '@carespace-icons/arrows/arrowDown';
import AddPatientToAdmin from '@pages/AddPatientToAdmin';
import { USER_ROLES } from '@stores/constants';
import { useTypedSelector } from '@stores/index';

interface ColumnType {
	key: string;
	name: string;
	sortable?: boolean;
}
interface ActionType {
	icon: React.ReactNode;
	action?: (data: any) => void;
	label: string
}
interface TableDataType {
	id: string;
	[key: string]: any;
}
interface TableListProps {
	columns: ColumnType[];
	tableData: TableDataType[];
	selectable?: boolean;
	actions?: ActionType[];
	onSort?: (key: string, order: 'asc' | 'desc') => void;
	onSelect?: (selectedItems: TableDataType[]) => void;
	activeRowId: string | null,
	setActiveRowId: (val: string | null) => void
	currentPage: number;
	searchValue: string;
	filterButton: string
}

const Table = ({
	columns,
	tableData,
	selectable,
	actions,
	onSort,
	onSelect, activeRowId, setActiveRowId, currentPage, searchValue, filterButton
}: TableListProps) => {
	const { t } = useTranslation();
	const [tableListData, setTableListData] = useState<TableDataType[]>([]);
	const [sortKey, setSortKey] = useState<string | null>(null);
	const [sortBy, setSortBy] = useState<'asc' | 'desc'>('asc');
	const [selectedAll, setSelectedAll] = useState(false);
	const [hoverIndex, setHoverIndex] = useState(-1);
		const { loading } = useTypedSelector(state => state.adminDashboardPatient)

	const getRoleStyles = (role: string) => {
		switch (role) {
			case USER_ROLES.USER:
				return 'bg-[#EFF8FF] text-[#175CD3]';
			case USER_ROLES.ADMIN:
				return 'bg-[#F4F3FF] text-[#5925DC]';
			case USER_ROLES.SUPER_ADMIN:
				return 'bg-[#FFFAEB] text-[#B54708]';
			default:
				return null;
		}
	};

	const formatRole = (role: string) => {
		switch (role) {
			case USER_ROLES.SUPER_ADMIN:
				return "Super Admin";
			case USER_ROLES.ADMIN:
				return "Admin";
			case USER_ROLES.USER:
				return "User";
			default:
				return role?.charAt(0)?.toUpperCase() + role?.slice(1).toLowerCase();
		}
	};

	useEffect(() => {
		setTableListData(tableData || []);
	}, [tableData]);

	const handleSort = (key: string) => {
		const newSortOrder = sortBy === 'asc' ? 'desc' : 'asc';
		setSortKey(key);
		setSortBy(newSortOrder);
		if (onSort) {
			onSort(key, newSortOrder);
		}
	};

	const toggleSelectAll = (checked: boolean) => {
		const updatedData = tableListData.map((item) => ({
			...item,
			isSelected: checked,
		}));
		setTableListData(updatedData);
		setSelectedAll(checked);
		if (onSelect) {
			onSelect(updatedData.filter((item) => item.isSelected));
		}
	};

	const toggleSelectRow = (id: string, checked: boolean) => {
		const updatedData = tableListData.map((item) =>
			item.id === id ? { ...item, isSelected: checked } : item
		);
		setTableListData(updatedData);
		if (onSelect) {
			onSelect(updatedData.filter((item) => item.isSelected));
		}
	};

	const handleActionClick = (action, rowData) => {
		if (action.icon?.type?.displayName === 'UserPlus01') {
			setActiveRowId(activeRowId === rowData.id ? null : rowData.id);
		} else {
			action.action?.(rowData);
		}
	};

	return loading
		? <Spin className="spin-css" tip="Loading" size="large" />
		: (
		<div className="w-full rounded-md shadow-lg border-gray-200 border-2">
			<table className="w-full">
				<thead>
					<tr className="bg-gray-50 border-b-gray-200 border-b-2 h-11 font-semi-bold text-gray-600 text-xs">
						{selectable && (
							<th className="text-left pl-2 pr-2">
								<CheckBox
									id="selectAll"
									checked={selectedAll}
									onClick={() => toggleSelectAll(!selectedAll)}
								/>
							</th>
						)}
						{columns.map((col, index) => (
							<th
								key={index}
								className={twJoin(
									'text-left pl-2 pr-2',
									col.sortable && 'cursor-pointer'
								)}
								onClick={() => col.sortable && handleSort(col.key)}
							>
								{col.name}
								{col.sortable && sortKey === col.key && (
									<span className="ml-4 inline-block">
										{sortBy === 'asc' ? (
											<ArrowUp color="stroke-gray-600" width={10} height={10} />
										) : (
											<ArrowDown color="stroke-gray-600" width={10} height={10} />
										)}
									</span>
								)}
							</th>
						))}
						{actions && actions.length > 0 && <th className="text-center pl-2 pr-2">{t('Admin.data.menu.userRoles.actions')}</th>}
					</tr>
				</thead>
				<tbody>
					{tableListData.length > 0 ? (
						tableListData.map((rowData, rowIndex) => (
							<tr
								key={rowData.id}
								className="border-b-gray-200 border-b-2 text-sm text-gray-600 cursor-pointer"
								onMouseOver={() => setHoverIndex(rowIndex)}
								onMouseLeave={() => setHoverIndex(-1)}
							>
								{selectable && (
									<td className="pl-2 pr-2 align-middle p-2 text-sm">
										<CheckBox
											id={rowData.id}
											checked={rowData.isSelected}
											onClick={(e) =>
												toggleSelectRow(rowData?.id, e.currentTarget.checked)
											}
										/>
									</td>
								)}
								{columns.map((col) => (
									<td key={col.key} className="pl-2 pr-2">
										{col.key === 'name' ? (
											<div className="flex py-2 items-center">
												{rowData?.imageUrl ? (
													<Avatar
														src={rowData?.imageUrl}
														alt={`${rowData?.firstName} ${rowData?.lastName}`}
													/>
												) : (
													<Avatar
														style={{ backgroundColor: rowData?.avatarColor || '#1890FF' }}
														alt={`${rowData?.firstName} ${rowData?.lastName}`}
													>
														{rowData?.firstName ? rowData?.firstName.charAt(0).toUpperCase() : 'U'}
													</Avatar>
												)}
												<span className="ml-2">
													<span className="block text-gray-900">
														<strong>
															{rowData?.firstName ? <>
																{rowData?.firstName}{' '} {rowData?.lastName} </> : <>{rowData?.fullName}</>}
														</strong>
													</span>
													<span className="block text-gray-600">
														{rowData?.email}
													</span>
												</span>
											</div>
										) : col.key === 'role' ? (
											<span className={`text-xs font-medium rounded-full py-1 px-2 mr-2 -ml-2 ${getRoleStyles(rowData?.invitedRole)}`}>
												{formatRole(rowData?.invitedRole)}
											</span>
										) : (
											rowData[col.key]
										)}
									</td>
								))}
								{actions && (
									<td className={`p-2 text-right pr-4 w-[140px] min-w-[140px] max-w-[140px] whitespace-nowrap ${hoverIndex == rowIndex ? 'opacity-100' : 'opacity-0'}`}>
										{activeRowId === rowData.id && (
											<Popover
												placement="leftTop"
												open={activeRowId === rowData.id}
												onOpenChange={() => {
													setActiveRowId(activeRowId === rowData.id ? null : rowData.id);
												}}
												content={<AddPatientToAdmin activeRowId={activeRowId} setActiveRowId={setActiveRowId} currentPage={currentPage} isRegistered={false} filterButton={filterButton} searchName={searchValue} />}
												trigger="click"
												overlayInnerStyle={{ width: '300px' }}
											/>
										)}
										{actions.map((action, index) => (
											<Tooltip title={action.label} color="purple">
												<span
													key={index}
													className="inline-block mr-3 cursor-pointer"
													onClick={() => handleActionClick(action, rowData)}>
													{action.icon}
												</span>
											</Tooltip>
										))}
									</td>
								)}
							</tr>
						))
					) : (
						<tr>
							<td colSpan={columns.length + (actions ? 1 : 0)} className="text-center p-3">
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={t('No Data')}
								/>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>

	);
};

export default Table;

