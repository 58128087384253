import {
	FilesetResolver,
	HandLandmarker,
	NormalizedLandmark,
	PoseLandmarker,
	PoseLandmarkerResult,
} from '@mediapipe/tasks-vision';
import { memo, useCallback, useEffect, useRef } from 'react';

const defaultVideoSize = { width: 1280, height: 720 };
const ipadVideoSize = { width: 640, height: 360 };

const drawOptions = { color: 'white', lineWidth: 5, visibilityMin: 0.65 };
const landmarksToRemove = [
	0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 17, 18, 19, 20, 21, 22,
];

// A simple throttle helper
function throttle<T extends (...args: any[]) => void>(
	func: T,
	delay: number,
): (...args: Parameters<T>) => void {
	let lastCall = 0;
	return (...args: Parameters<T>): void => {
		const now = performance.now();
		if (now - lastCall < delay) return;
		lastCall = now;
		func(...args);
	};
}

function Mediapipe(props: {
	cameraId: string | null;
	isFullscreen: boolean;
	isSwitchMode: boolean;
	isRightHand?: boolean;
	wristExercise?: boolean;
	stopLandmarks?: boolean;
}) {
	const { cameraId, isFullscreen, isSwitchMode, wristExercise, stopLandmarks } =
		props;
	const videoRef = useRef<HTMLVideoElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const poseLandmarkerRef = useRef<PoseLandmarker | null>(null);
	const handLandmarkerRef = useRef<HandLandmarker | null>(null);

	// Determine if the device is an iPad
	const isIpad =
		/iPad|Macintosh/.test(navigator.userAgent) && navigator.maxTouchPoints > 1;
	// Use different video sizes and frame rates for iPad versus other devices.
	const videoSize = isIpad ? ipadVideoSize : defaultVideoSize;
	const frameRate = isIpad ? { ideal: 10, max: 15 } : { ideal: 15, max: 20 };

	let animationFrameId: number;

	// Trigger custom events for drawing results
	const triggerResultsEvent = (eventName: string, data: unknown) => {
		const event = new CustomEvent(eventName, { detail: data });
		window.dispatchEvent(event);
	};
	const throttledTriggerResultsEvent = throttle(triggerResultsEvent, 100);
	const throttledTriggerHandResults = throttle(triggerResultsEvent, 100);

	// Draw pose landmarks on the canvas
	const drawCallback = useCallback(
		(results: PoseLandmarkerResult) => {
			if (!results.landmarks[0] || !canvasRef.current) return;
			const canvasCtx = canvasRef.current.getContext(
				'2d',
			) as CanvasRenderingContext2D;
			const { width, height } = videoSize;
			canvasCtx.save();
			canvasCtx.clearRect(0, 0, width, height);
			canvasCtx.fillStyle = 'white';

			PoseLandmarker.POSE_CONNECTIONS.forEach(connection => {
				const { start, end } = connection;
				if (
					!landmarksToRemove.includes(start) &&
					!landmarksToRemove.includes(end)
				) {
					const startLandmark = results.landmarks[0][start];
					const endLandmark = results.landmarks[0][end];
					if (
						startLandmark.visibility < drawOptions.visibilityMin ||
						endLandmark.visibility < drawOptions.visibilityMin
					) {
						canvasCtx.restore();
						return;
					}
					const startX = startLandmark.x * width;
					const startY = startLandmark.y * height;
					const endX = endLandmark.x * width;
					const endY = endLandmark.y * height;

					// Draw connection line
					canvasCtx.globalCompositeOperation = 'destination-over';
					canvasCtx.lineWidth = drawOptions.lineWidth;
					canvasCtx.strokeStyle = drawOptions.color;
					canvasCtx.beginPath();
					canvasCtx.moveTo(startX, startY);
					canvasCtx.lineTo(endX, endY);
					canvasCtx.stroke();

					// Draw endpoints
					canvasCtx.globalCompositeOperation = 'source-over';
					canvasCtx.fillStyle =
						start % 2 === 0 ? 'rgb(0,217,231)' : 'rgb(255,138,0)';
					canvasCtx.beginPath();
					canvasCtx.arc(
						startX,
						startY,
						drawOptions.lineWidth + 2,
						0,
						2 * Math.PI,
					);
					canvasCtx.arc(endX, endY, drawOptions.lineWidth + 2, 0, 2 * Math.PI);
					canvasCtx.fill();
				}
			});

			throttledTriggerResultsEvent('results', {
				results: results.landmarks[0],
			});
			canvasCtx.restore();
		},
		[throttledTriggerResultsEvent, videoSize],
	);

	// Draw hand landmarks on the canvas (restored your original drawHandCallback)
	const drawHandCallback = async () => {
		if (videoRef.current && handLandmarkerRef.current && canvasRef.current) {
			const handResults = await handLandmarkerRef.current.detectForVideo(
				videoRef.current,
				performance.now(),
			);

			if (
				handResults &&
				handResults.landmarks &&
				handResults.landmarks.length > 0
			) {
				const canvasCtx = canvasRef.current.getContext(
					'2d',
				) as CanvasRenderingContext2D;
				const { width, height } = videoSize;
				let leftHand: NormalizedLandmark[] | null = null;
				let rightHand: NormalizedLandmark[] | null = null;

				handResults.landmarks.forEach((handLandmarks, index) => {
					if (handLandmarks.length === 21) {
						const handPos =
							handResults.handednesses[index] &&
							handResults.handednesses[index][0] &&
							handResults.handednesses[index][0].categoryName;
						if (handLandmarks.length === 21) {
							if (handPos === 'Left') {
								leftHand = handLandmarks;
							} else if (handPos === 'Right') {
								rightHand = handLandmarks;
							}
						}
					}
					throttledTriggerHandResults('handmarks', {
						handmarkLeft: leftHand,
						handmarkRight: rightHand,
					});
					const handedness =
						handResults.handednesses && handResults.handednesses[index]
							? handResults.handednesses[index][0]?.categoryName
							: 'Unknown';
					const color =
						handedness === 'Left'
							? 'rgb(255,138,0)'
							: handedness === 'Right'
								? 'rgb(0,217,231)'
								: 'rgb(200,200,200)';
					handLandmarks.forEach((landmark: NormalizedLandmark) => {
						const x = landmark.x * width;
						const y = landmark.y * height;
						canvasCtx.beginPath();
						canvasCtx.arc(x, y, 5, 0, 2 * Math.PI);
						canvasCtx.fillStyle = color;
						canvasCtx.fill();
					});
					HandLandmarker.HAND_CONNECTIONS.forEach(connection => {
						const start = handLandmarks[connection.start];
						const end = handLandmarks[connection.end];
						canvasCtx.beginPath();
						canvasCtx.moveTo(start.x * width, start.y * height);
						canvasCtx.lineTo(end.x * width, end.y * height);
						canvasCtx.strokeStyle = 'white';
						canvasCtx.lineWidth = 5;
						canvasCtx.stroke();
					});
				});
			}
		}
	};

	// Main prediction loop
	const predictWebcam = useCallback(async () => {
		if (videoRef.current && poseLandmarkerRef.current) {
			const startTimeMs = performance.now();
			if (videoRef.current.readyState < 2) {
				console.error('Video not ready for processing.');
				return;
			}
			try {
				await poseLandmarkerRef.current.detectForVideo(
					videoRef.current,
					startTimeMs,
					drawCallback,
				);
			} catch (error) {
				console.error('PoseLandmarker Error:', error);
				return;
			}
			// Run hand landmark drawing if enabled
			if (wristExercise && handLandmarkerRef.current) {
				try {
					await drawHandCallback();
				} catch (error) {
					console.error('HandLandmarker Error:', error);
				}
			}
			animationFrameId = requestAnimationFrame(predictWebcam);
		}
	}, [drawCallback, wristExercise]);

	// Stop video stream and cancel animation frames
	const stopStreamedVideo = useCallback(() => {
		if (videoRef.current) {
			cancelAnimationFrame(animationFrameId);
			const stream = videoRef.current.srcObject as MediaStream;
			if (stream) {
				stream.getTracks().forEach(track => track.stop());
			}
			videoRef.current.srcObject = null;
			videoRef.current.removeEventListener('loadeddata', predictWebcam);
		}
	}, [predictWebcam]);

	// Set up the camera stream with updated constraints for iPad vs non-iPad
	const setupCamera = useCallback(() => {
		if (!navigator.mediaDevices?.getUserMedia) {
			console.warn('getUserMedia() is not supported by your browser');
			return;
		}
		if (!poseLandmarkerRef.current) {
			console.warn('Wait! PoseLandmarker not loaded yet.');
			setTimeout(setupCamera, 1000);
			return;
		}
		const constraints = {
			video: {
				deviceId: { exact: cameraId as string },
				width: { ideal: videoSize.width, max: videoSize.width },
				height: { ideal: videoSize.height, max: videoSize.height },
				frameRate: frameRate,
			},
			audio: false,
		};
		navigator.mediaDevices.getUserMedia(constraints).then(stream => {
			if (videoRef.current) {
				videoRef.current.srcObject = stream;
				videoRef.current.addEventListener('loadeddata', predictWebcam);
			}
		});
	}, [cameraId, predictWebcam, videoSize, frameRate]);

	const setupCameraOnly = useCallback(() => {
		if (!navigator.mediaDevices?.getUserMedia) {
			console.warn('getUserMedia() is not supported by your browser');
			return;
		}

		const constraints = {
			video: {
				deviceId: { exact: cameraId as string },
				width: { ideal: videoSize.width, max: videoSize.width },
				height: { ideal: videoSize.height, max: videoSize.height },
				frameRate: frameRate,
			},
			audio: false,
		};
		navigator.mediaDevices.getUserMedia(constraints).then(stream => {
			if (videoRef.current) {
				videoRef.current.srcObject = stream;
			}
		});
	}, [cameraId, videoSize, frameRate]);

	// Create the PoseLandmarker instance with delegate based on device
	const createPoseLandmarker = useCallback(async () => {
		const vision = await FilesetResolver.forVisionTasks(
			'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm',
		);
		const newPoseLandmarker = await PoseLandmarker.createFromOptions(vision, {
			baseOptions: {
				modelAssetPath:
					'https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_full/float16/latest/pose_landmarker_full.task',
				delegate: isIpad ? 'GPU' : 'CPU',
			},
			runningMode: 'VIDEO',
			numPoses: 1,
			minPoseDetectionConfidence: 0.6,
			minTrackingConfidence: 0.6,
		});
		poseLandmarkerRef.current = newPoseLandmarker;
	}, [isIpad]);

	// Create the HandLandmarker instance if needed
	const createHandLandmarker = useCallback(async () => {
		const vision = await FilesetResolver.forVisionTasks(
			'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm',
		);
		const newHandLandmarker = await HandLandmarker.createFromOptions(vision, {
			baseOptions: {
				modelAssetPath:
					'https://storage.googleapis.com/mediapipe-models/hand_landmarker/hand_landmarker/float16/1/hand_landmarker.task',
				delegate: 'CPU',
			},
			runningMode: 'VIDEO',
			numHands: 2,
		});
		handLandmarkerRef.current = newHandLandmarker;
	}, []);

	// On mount: create models and set up cleanup on unmount.
	useEffect(() => {
		if (stopLandmarks) {
			return;
		}
		createPoseLandmarker();
		if (wristExercise) {
			createHandLandmarker();
		}
		return () => {
			// Stop video and cancel animation frame
			stopStreamedVideo();
			// Release mediapipe instance resources
			if (poseLandmarkerRef.current) {
				console.log('Releasing PoseLandmarker instance...');
				poseLandmarkerRef.current.close(); // Releases both CPU and GPU resources as applicable
				poseLandmarkerRef.current = null;
			}
			if (handLandmarkerRef.current) {
				console.log('Releasing HandLandmarker instance...');
				handLandmarkerRef.current.close();
				handLandmarkerRef.current = null;
			}
		};
	}, [
		createPoseLandmarker,
		createHandLandmarker,
		stopStreamedVideo,
		wristExercise,
		stopLandmarks,
	]);

	useEffect(() => {
		if (stopLandmarks) {
			if (poseLandmarkerRef.current) {
				poseLandmarkerRef.current.close();
				poseLandmarkerRef.current = null;
			}
			if (handLandmarkerRef.current) {
				handLandmarkerRef.current.close();
				handLandmarkerRef.current = null;
			}
			return;
		}
	}, [stopLandmarks]);

	// Set up camera whenever cameraId changes
	useEffect(() => {
		if (cameraId) {
			stopStreamedVideo();
			if (stopLandmarks) {
				setupCameraOnly();
			} else {
				setupCamera();
			}
		}
	}, [cameraId, setupCamera, stopStreamedVideo, stopLandmarks]);

	return (
		<>
			<video
				id="video"
				autoPlay
				playsInline
				ref={videoRef}
				onLoadedMetadata={() => {
					const video = videoRef.current;
					const canvas = canvasRef.current;
					if (video && canvas) {
						canvas.width = video.videoWidth;
						canvas.height = video.videoHeight;
					}
				}}
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					zIndex: 1,
					objectFit: 'cover',
					aspectRatio: '16/9',
					width: '100%',
					height: isFullscreen && !isSwitchMode ? '100%' : 'auto',
				}}
			/>
			<canvas
				id="canvas"
				ref={canvasRef}
				width={videoSize.width}
				height={videoSize.height}
				style={{
					width: '100%',
					height: '100%',
					zIndex: 2,
					position: 'absolute',
					pointerEvents: 'none',
					top: 0,
					left: 0,
				}}
			/>
		</>
	);
}

export default memo(Mediapipe);
