import { KeyboardEvent, ReactNode, useEffect, useState } from 'react'
import { Collapse, Empty, Input, Pagination, Spin } from 'antd';
import { SearchLg } from '@carespace-icons/general/searchLg';
import { useTranslation } from 'react-i18next';
import { showCustomModal } from '@atoms/ACustomModalInfo';
import { PlayCircle } from '@carespace-icons/media/playCircle';
import { fetchSystemTemplateExercises } from '@stores/rom/romTemplates';
import { useTypedDispatch } from '@stores/index';
import { IStrapiPagination, TemplateExerciseProgram } from '@stores/interfaces';

interface IRomMyTemplate {
  extra: (data: TemplateExerciseProgram) => ReactNode
}

interface dataItem {
  data: TemplateExerciseProgram[],
  meta: {
    pagination: IStrapiPagination
  }
}

const SystemTemplate = (props: IRomMyTemplate) => {

  const { extra } = props
  const { Panel } = Collapse;
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchValue, setSearchValue] = useState<string>('')
  const [isLoading, setLoading] = useState(false)
  const [suggestedRomData, setSuggestedRomData] = useState<dataItem>()
  const dispatch = useTypedDispatch()

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    fetchTemplateExercises(pageNumber, searchValue);
  }

  const fetchTemplateExercises = async (page: number, search: string) => {
    setLoading(true)
    const data = await dispatch(fetchSystemTemplateExercises({ page: page, search: search }))
    setSuggestedRomData(data?.payload)
    setLoading(false)
  }


  useEffect(() => {
    fetchTemplateExercises(currentPage, searchValue)
  }, [])

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      fetchTemplateExercises(1, searchValue);
    }
  };

  return (
    <>
      <Input
        className='w-full my-3 h-10'
        placeholder={t('Admin.data.menu.aiAssistant.aiAssistantPrograms.search')}
        value={searchValue}
        onKeyDown={(e) => handleKeyDown(e)}
        onChange={(e) => setSearchValue(e.target.value)}
        suffix={<SearchLg height={15} width={15} color='stroke-black' />}
      />
      {isLoading ? <Spin className="spinner" tip="Loading" size="large" /> : <>
        {suggestedRomData?.data?.length === 0 || undefined ? (
          <Empty
            className="bg-gray-50 p-10 m-0"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <span className="text-gray-300">
                {t("Admin.data.addToReports.noData")}
              </span>
            }
          />
        ) : (
          <div style={{ maxHeight: "42vh", overflowX: "hidden", overflowY: "auto" }}>
            <Collapse className="select-none p-4 rounded-none bg-gray-50 hover:!bg-gray-50 flex flex-col gap-2" bordered={false}>
              {
                suggestedRomData?.data?.map((data: TemplateExerciseProgram) => {
                  const sortedExercises = [...(data?.exercises || [])].sort((a, b) => a.order - b.order);
                  return <Panel
                    className='header-panel !w-full bg-white !border !border-gray-200 !rounded-xl'
                    key={""}
                    header={<p className="text-sm font-semibold text-gray-600">{data?.title}</p>}
                    extra={extra(data)}
                  >
                    {
                      sortedExercises?.map((item) => {
                        return (
                          <div className="px-4 py-2">
                            <div className='flex items-center justify-center cursor-pointer gap-4 p-4 rounded-lg border-2 border-inherit w-full' key={item?.name}>
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  showCustomModal({
                                    video: item?.OmniRomExerciseId?.video ? item?.OmniRomExerciseId?.video?.url : item?.video,
                                    name: item?.OmniRomExerciseId?.name ? item?.OmniRomExerciseId?.name : item?.name,
                                    description: item?.OmniRomExerciseId?.description ? item?.OmniRomExerciseId?.description : item?.description,
                                  })
                                }}
                                className={`custom-image-container`}>
                                <div className="w-40 flex-shrink-0 image-wrapper">
                                  {item?.OmniRomExerciseId?.image ?
                                    <img src={item?.OmniRomExerciseId?.image?.url} alt="" className="rounded-lg w-full h-full" /> :
                                    <video src={item?.OmniRomExerciseId?.video?.url} className="rounded-lg w-full h-full" />
                                  }
                                  <div className="play-button">
                                    <PlayCircle width={50} height={50} />
                                  </div>
                                </div>
                              </div>
                              <div className="flex-1 rounded-lg p-2">
                                <p className="text-gray-500 font-semibold text-lg">{item?.OmniRomExerciseId?.name}</p>
                                <p className="text-gray-400 font-semibold text-xs">{t('Patient.data.omnirom.transitionTime')} : {item?.transitionTime || 3}</p>
                                <p className="text-gray-500 mt-2">{item?.OmniRomExerciseId?.description ? item?.OmniRomExerciseId?.description : item?.description}</p>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </Panel>
                })
              }
            </Collapse></div>)}
        <div>
          {!isLoading && <Pagination
            className='createProgramModalPagination flex justify-center'
            current={suggestedRomData?.meta?.pagination?.page}
            showSizeChanger={false}
            onChange={() => { onPageChange }}
            total={suggestedRomData?.meta?.pagination?.total}
          />}
        </div>
      </>}
    </>
  )
}

export default SystemTemplate