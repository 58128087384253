import { Layout, Spin } from 'antd';
import { Outlet } from 'react-router-dom';
import { useTypedSelector } from '@stores/index';
import MMainMenu from '@molecules/MMainMenu';
import Sider from 'antd/es/layout/Sider';
import PatientOnboard from '@pages/PatientOnboard';
import { useEffect, useState } from 'react';
import { USER_ROLES } from '@stores/constants';
import NewPatientOnboardDashboard from '@pages/NewPatientOnboardDashboard';
import { UseAuth } from '@contexts/AuthContext';
import { IWholeDayActivity } from '@stores/interfaces';

const { Content } = Layout;

const MLayoult = () => {
	const { user } = useTypedSelector((state) => ({
		user: state.user,
	}));
	const { credentials } = UseAuth();
	const role = credentials?.roles ? credentials.roles[0] : undefined;
	const [isLoading, setLoading] = useState(true)
	const isSuperAdmin = user?.profile?.role === USER_ROLES.SUPER_ADMIN;
	const [isUserDataPresent, setIsUserDataPresent] = useState(false);
	const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);
	const onBoardCompletion = useTypedSelector((state) => state.onBoard.onBoard.onBoardCompletion)
	useEffect(() => {
		if (user?.profile?.role == USER_ROLES.USER || role == USER_ROLES.USER) {
			const isWeight =
				(user?.profile?.imperialWeight || user?.profile?.metricWeight) ||
				(user?.profile?.weight?.[0]?.imperialWeight || user?.profile?.weight?.[0]?.metricWeight);

			const userDataCheck = (
				user?.profile?.firstName &&
				user?.profile?.lastName &&
				user?.profile?.email &&
				user?.profile?.birthDate &&
				(user?.profile?.imperialHeight || user?.profile?.metricHeight) &&
				isWeight &&
				user?.profile?.gender &&
				(user?.functionalGoals?.length > 0) &&
				user?.profile?.consentPolicyRead && getTotalHours(user?.dailyActivityDistribution)
			)
			setIsUserDataPresent(userDataCheck);
		} else {
			setIsUserDataPresent(true);
		}
		user?.id && setLoading(false)
	}, [user]);

	const getTotalHours = (data: IWholeDayActivity): number => {
		if (!data) return 0;
		const activityKeys = ["sitting", "standing", "bending", "lifting", "walking", "sleeping"];
		const total = activityKeys
			.map(key => data[key as keyof IWholeDayActivity] || 0)
			.reduce((sum, value) => sum + (typeof value === "number" ? value : 0), 0);
		return total;
	};

	const handleOnboardingComplete = () => {
		setIsOnboardingComplete(true);
	};

	if (isLoading) {
		return <Spin className="flex items-center justify-center w-[100%] h-[100%]" tip="Loading" size="large" />;
	}

	return (
		<Layout>
			{(!isUserDataPresent && !isOnboardingComplete) && !onBoardCompletion ? (
				<PatientOnboard onComplete={handleOnboardingComplete} />
			) : (
				<>
					<Sider
						trigger={null}
						collapsible
						collapsed={false}
						width='364px'
					>
						<MMainMenu user={user} />
					</Sider>
					<Layout className='ml-8'>
						<Content>
							{!isUserDataPresent ? <NewPatientOnboardDashboard /> : <Outlet />}
						</Content>
					</Layout>
				</>
			)}
		</Layout>
	);
};

export default MLayoult;
