import { BarChartSquare01 } from '@carespace-icons/charts/barChartSquare01';
import { InfoCircle } from '@carespace-icons/general/infoCircle';
import {
	IAiProgramSummaryContent,
	IOnchangeStatus,
	IstatusIcon,
	ProgramSession,
	ProgramSessionResult,
	Status,
} from '@stores/interfaces';
import {
	Checkbox,
	Col,
	Collapse,
	Dropdown,
	Empty,
	Menu,
	Result,
	Row,
	Space,
	Tag,
	message,
} from 'antd';
import Badge from '@atoms/Badge';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaceHappy } from '@carespace-icons/users/faceHappy';
import { FaceWink } from '@carespace-icons/users/faceWink';
import { FaceSmile } from '@carespace-icons/users/faceSmile';
import { FaceNeutral } from '@carespace-icons/users/faceNeutral';
import { FaceSad } from '@carespace-icons/users/faceSad';
import { FaceFrown } from '@carespace-icons/users/faceFrown';
import { StatusNormalized } from '@stores/constants';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { updateRehabSessionStatus as updateOmniRomSessionStatusOutOfParams } from '@stores/outOfParams';
import { updateRehabSessionStatus as updateOmniRomSessionStatusPendingReview } from '@stores/pendingReview';
import { updateRehabSessionStatus as updateOmniRomSessionStatusReviewed } from '@stores/reviewed';
import { updateRehabSessionStatus as updateOmniRomSessionStatusEscalationRequired } from '@stores/escalationRequired';
import { updateRehabSessionStatus as updateOmniRomSessionStatusFollowUpRequired } from '@stores/followUpRequired';
import { addReportId, deleteReportId } from '@stores/report/reports';

const { Panel } = Collapse;

const painLevelData = {
	0: {
		color: 'text-cyan-500',
		icon: <FaceHappy color={'stroke-cyan-500'} width={20} height={20} />,
	},
	1: {
		color: 'text-green-700',
		icon: <FaceWink color={'stroke-green-700'} width={20} height={20} />,
	},
	2: {
		color: 'text-green-500',
		icon: <FaceSmile color={'stroke-green-500'} width={20} height={20} />,
	},
	3: {
		color: 'text-green-300',
		icon: <FaceSmile color={'stroke-green-300'} width={20} height={20} />,
	},
	4: {
		color: 'text-yellow-300',
		icon: <FaceNeutral color={'stroke-yellow-300'} width={20} height={20} />,
	},
	5: {
		color: 'text-yellow-400',
		icon: <FaceNeutral color={'stroke-yellow-400'} width={20} height={20} />,
	},
	6: {
		color: 'text-orange-300',
		icon: <FaceSad color={'stroke-orange-300'} width={20} height={20} />,
	},
	7: {
		color: 'text-orange-400',
		icon: <FaceSad color={'stroke-orange-400'} width={20} height={20} />,
	},
	8: {
		color: 'text-rose-500',
		icon: <FaceFrown color={'stroke-rose-500'} width={20} height={20} />,
	},
	9: {
		color: 'text-rose-600',
		icon: <FaceFrown color={'stroke-rose-600'} width={20} height={20} />,
	},
	10: {
		color: 'text-rose-700',
		icon: <FaceFrown color={'stroke-rose-700'} width={20} height={20} />,
	},
};

export default function AiProgramSummaryContent(
	props: IAiProgramSummaryContent,
) {
	const {
		programIdData,
		currentCount,
		fetchProgramByIdList,
		item,
		programSessionData,
		isLoading,
		antIcon,
		CustomModalInfo,
		setSessionClicked,
		setRehabExerciseToPatientId,
		setProgramuTutorialVideo,
		setProgramDescription,
		setExerciseTitle,
		fetchData3,
	} = props;
	const { t } = useTranslation();
	const [selectedCollapse, setSelectedCollapse] = useState<
		string[] | string | undefined
	>();
	const user = useTypedSelector(state => state.user);
	const reportIds = useTypedSelector(state => state.reports.reportIds);
	const stateId = useTypedSelector(
		state => state.aiAssistant.aiAssistant.stateId,
	);

	const handleExtraHeader = (dataItems: ProgramSession) => {
		const getStatusComponent = () => {
			switch (dataItems?.overallCondition) {
				case 'improving':
					return (
						<span className="bg-green-500 text-white px-3  rounded-full">
							{t(
								'Admin.data.menu.aiAssistant.aiAssistantListSessions.improving',
							)}
						</span>
					);
				case 'worsening':
					return (
						<span className="bg-red-400 text-white px-3  rounded-full">
							{t(
								'Admin.data.menu.aiAssistant.aiAssistantListSessions.worsening',
							)}
						</span>
					);
				case 'noChange':
					return (
						<span className="bg-yellow-400 text-white px-3  rounded-full">
							{t(
								'Admin.data.menu.aiAssistant.aiAssistantListSessions.noChange',
							)}
						</span>
					);
				default:
					return null;
			}
		};

		const getPainLevel = () => {
			const painLevelDataItem = painLevelData[dataItems?.painLevel];
			if (painLevelDataItem) {
				return (
					<div className={`!flex gap-2 ${painLevelDataItem.color}`}>
						<span className="text-sm font-semibold">
							{dataItems?.painLevel}
						</span>
						{painLevelDataItem.icon}
					</div>
				);
			}
			return null;
		};

		const statusIcons: IstatusIcon = {
			outOfParams: <Badge color="black" />,
			pendingReview: <Badge color="yellow" />,
			reviewed: <Badge color="green" />,
			escalationRequired: <Badge color="rose" />,
			followUpRequired: <Badge color="orange" />,
		};

		type IUpdateRomSessionStatus = Omit<Record<Status, any>, 'newPatients'>;
		const dispatch = useTypedDispatch();
		const updateRehabSessionStatus: IUpdateRomSessionStatus = {
			outOfParams: updateOmniRomSessionStatusOutOfParams,
			pendingReview: updateOmniRomSessionStatusPendingReview,
			reviewed: updateOmniRomSessionStatusReviewed,
			escalationRequired: updateOmniRomSessionStatusEscalationRequired,
			followUpRequired: updateOmniRomSessionStatusFollowUpRequired,
		};

		const onChangeStatus = async (params: IOnchangeStatus, item: any) => {
			const { sessionId, status } = params;
			message.loading({
				content: t('Admin.data.menu.aiAssistant.aiAssistantRomSummary.saving'),
				key: sessionId,
			});
			await dispatch(
				updateRehabSessionStatus[item.status as keyof IUpdateRomSessionStatus]({
					sessionId,
					body: { status },
				}),
			);
			message.success({
				content: t(
					'Admin.data.menu.aiAssistant.aiAssistantRomSummary.statusSuccess',
				),
				key: sessionId,
				duration: 3,
			});
			fetchProgramByIdList(programIdData, currentCount);
		};
		const healthStatus = (dataItems: any) => {
			const menu = (
				<Menu>
					{Object.entries(StatusNormalized)
						.filter(([key]) => key !== dataItems.status)
						.map(([status, label]) => {
							return (
								<Menu.Item
									key={status}
									onClick={() =>
										onChangeStatus(
											{ sessionId: dataItems.id, status: status as Status },
											dataItems,
										)
									}>
									<Space>
										<span className="dropdown-badge">
											{statusIcons[status as keyof IstatusIcon]}
										</span>
										<span>{label}</span>
									</Space>
								</Menu.Item>
							);
						})}
				</Menu>
			);
			return (
				<div className="flex justify-between items-center">
					<div className="align-middle mt-[0.20rem]">
						{user.isPhysioterapist ? (
							<Dropdown overlay={menu}>
								<a onClick={e => e.stopPropagation()}>
									{statusIcons[dataItems.status as keyof IstatusIcon]}
								</a>
							</Dropdown>
						) : (
							''
						)}
					</div>
				</div>
			);
		};

		const statusComponent = getStatusComponent();
		const painLevelComponent = getPainLevel();
		const sessionStatusComponent = healthStatus(dataItems);
		return (
			<div
				className="ml-auto !flex items-center justify-center"
				onClick={e => e.stopPropagation()}>
				<section className="!flex">
					<span className="pr-3 flex justify-between items-center">
						{statusComponent}
					</span>
					<span className="pr-3 flex justify-between items-center">
						{painLevelComponent}
					</span>
					<span className="">{sessionStatusComponent}</span>
				</section>
				<div
					onClick={event => {
						event.stopPropagation();
					}}>
					{
						<Checkbox
							className="mb-1"
							style={{
								visibility:
									dataItems.programSessionResult.length > 0
										? 'visible'
										: 'hidden',
								marginTop: "3px"
							}}
							checked={reportIds?.programSessionsIds?.includes(dataItems.id)}
							onChange={e => {
								if (e.target.checked) {
									dispatch(
										addReportId({
											type: 'programSessionsIds',
											id: dataItems.id,
										}),
									);
								} else {
									dispatch(
										deleteReportId({
											type: 'programSessionsIds',
											id: dataItems.id,
										}),
									);
								}
							}}
						/>
					}
				</div>
			</div>
		);
	};

	return (
		<Collapse
			bordered={false}
			defaultActiveKey={selectedCollapse ? selectedCollapse : stateId}
			onChange={key => setSelectedCollapse(key)}
			className={`select-none summary-panel ${'bg-white'}`}>
			{programSessionData?.map((session: ProgramSession) => {
				return (
					<Panel
						className="header-panel bg-gray-50 !border !border-gray-200 !rounded-lg mt-3"
						header={
							<div className="flex gap-1 items-center cursor-pointer">
								<p className="text-sm font-semibold text-gray-600">
									{
										moment(session?.createdAt).local().format('LLL')}
								</p>
								{session?.programSessionResult.length === 0 && (
									<Tag color="purple">
										No Record Found
									</Tag>
								)}
							</div>
						}
						key={session?.id}
						showArrow={session?.programSessionResult.length > 0}
						collapsible={session?.programSessionResult?.length === 0 ? "disabled" : undefined}
						extra={handleExtraHeader(session)}>
						<Row>
							{!isLoading &&
								session?.programSessionResult &&
								session?.programSessionResult.length > 0 ? (
								session?.programSessionResult.map(
									(value: ProgramSessionResult) => {
										if (value.processing)
											setTimeout(
												() => fetchProgramByIdList(programIdData, 1),
												2000,
											);
										const checkStrapi = value?.programExercise?.strapiExercise;
										const strapiExerciseValue =
											value?.programExercise?.strapiExercise?.attributes;
										const exerciseLibraryValue =
											value?.programExercise?.exerciseLibrary;
										return (
											<Col key={value.id}>
												<div className="video-item">
													{value?.processing ? (
														<>
															{value?.processing}
															<Result
																className="result"
																icon={antIcon}
																title="processing"
																style={{ color: '#FFF' }}
															/>
														</>
													) : (
														<div className="relative rounded-md pl-3 pb-3">
															<video
																controls
																className="video rounded-md"
																preload="metadata"
																src={value?.video}
																type="video/mp4"
																width="100%"
																height="100%"
															/>
															<div>
																<span
																	className=" bg-white rounded-md p-1 cursor-pointer absolute z-10 top-2 right-10"
																	onClick={() =>
																		CustomModalInfo({
																			video: checkStrapi
																				? strapiExerciseValue?.assets[0]?.video
																					?.data?.attributes?.url
																				: exerciseLibraryValue?.videoUrl,
																			name: checkStrapi
																				? strapiExerciseValue?.name
																				: exerciseLibraryValue?.title,
																			description: checkStrapi
																				? strapiExerciseValue?.description
																				: exerciseLibraryValue?.description,
																		})
																	}>
																	<InfoCircle
																		width={17}
																		height={17}
																		color="stroke-gray-700"
																	/>
																</span>

																<span
																	className="bg-white rounded-md p-1 cursor-pointer absolute z-10 top-2 right-2"
																	onClick={() => {
																		setSessionClicked(true);
																		setRehabExerciseToPatientId(
																			value?.programExerciseId,
																		);
																		setProgramuTutorialVideo(
																			checkStrapi
																				? strapiExerciseValue?.assets[0]?.video
																					?.data?.attributes?.url
																				: exerciseLibraryValue?.videoUrl,
																		);
																		setProgramDescription(
																			checkStrapi
																				? strapiExerciseValue?.description
																				: exerciseLibraryValue?.description,
																		);
																		setExerciseTitle(
																			checkStrapi
																				? strapiExerciseValue?.name
																				: exerciseLibraryValue?.title,
																		);
																	}}>
																	<BarChartSquare01
																		width={17}
																		height={17}
																		color="stroke-gray-700"
																	/>
																</span>
															</div>

															<div className="bg-gray-700 text-white p-2 -mt-1 relative z-10 rounded-b-md flex justify-between">
																<span>
																	{checkStrapi
																		? strapiExerciseValue?.name
																		: exerciseLibraryValue?.title}
																</span>
																<span></span>
															</div>
														</div>
													)}
												</div>
											</Col>
										);
									},
								)
							) : (
								<Col span={24}>
									<Empty
										description={t(
											'Admin.data.managePatient.rehab.captures.noCapturesFound',
										)}
										image={Empty.PRESENTED_IMAGE_SIMPLE}
									/>
								</Col>
							)}
						</Row>
					</Panel>
				);
			})}
		</Collapse>
	);
}
